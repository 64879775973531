.toast-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 16px;
  color: #0067FF;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
}
.toast-center .Toastify__close-button {
  color: #0067FF; 
}

.toast-center .Toastify__progress-bar {
  color:#0067FF !important;
}
.toast-center .Toastify__toast-icon {
  color:#0067FF; 
}
.error-message {
  color: red;
  font-size: 14px; 
}


.userprofile-card {
  width: 100%;
  height:fit-content;
  padding:40px;
  border-radius: 12px;
  background: #FFFFFF;
}

.profile-header {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.profile-image-wrapper {
  position: relative;
}

.profile-image {
  border-radius: 20px 5px;
  object-fit: cover;
  width: 56px;
  height: 56px;
}

.edit-icon1 {
  position: absolute;
  top: 36px;
  left: 38px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  z-index: 0;
  cursor: pointer;
  background: #FFFFFF;
  box-shadow: 0px 1px 4px 0px #1A0F011F;
}

.edit-icon2 {
  position: absolute;
  top: 4px;
  left: 4px;
  cursor: pointer;
  z-index: 1;
  color: #4B5563;
  font-weight: 600;
}

.profile-info {
  padding-top: 10px;
}

.profile-info h5 {
  font-size: 12px;
  font-weight: 400;
  color: #1F2937;
  margin-bottom: 2px;
}

.profile-info p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #6B7280;
}

.profile-group {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  border-top: 1px solid #E5E7EB;
}

.profile-label {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  flex-grow: 1;
  color: #1F2937;
  min-width: 100px;
  margin:0;
}

.profile-input {
  background:transparent;
  font-weight: 400;
  font-size: 16px;
  line-height: 60px;
  flex-grow: 1;
  margin-right: 20px;
  text-align: right;
  border: 0;
}
.profile-group select{
  line-height: 60px;
  padding: 15px 0px !important;

}
.react-PH-input{
  border: none;
  outline: none;
  padding: 20px 0px 20px 50px;
}
.react-tel-input{
  width: 24% !important;
  background-color: transparent !important;
  display: flex;
  flex-direction: row;
  justify-content: start;
  
}
.react-tel-input .flag-dropdown{
  background: transparent;
  border: none;
  margin-left:-10px;
}

.profile-group .profile-input::placeholder {
  color: #4B5563;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.profile-input:focus {
  outline: 0px none;
  box-shadow: none;
}

.edit-icon {
  color: #4B5563;
  cursor: pointer;
}

.profile-field-wrapper {
  position: relative;
  padding-bottom: 15px;
}

.savebutton {
  position: relative;  /* Make the button a positioning context */
  border-radius: 5px;
  background: #2489FF;
  color: white;
  padding: 8px 30px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.savebutton:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.savebutton-text {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}

/* Spinner overlay */
.spinner-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.5);  /* Optional: Semi-transparent background */
  width: 100%;
  height: 100%;
  border-radius: 5px;  /* Match button's border radius */
}

/* Small spinner style */
.small-spinner {
  width: 24px;
  height: 24px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


/* Mobile view adjustments */

@media (max-width: 380px) {
  .userprofile-card {
    padding: 20px 15px;
    max-width: 500px;
  }
  
  .profile-header {
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 20px;
    gap: 20px;
  }
  
  .profile-label,
  .profile-input {
    font-size: 14px;
    line-height: 20px;
  }
  
  .profile-input {
    line-height: 40px;
    margin-right: 10px;
    text-align: end;
  }
  
  .profile-field-wrapper {
    padding-bottom: 10px;
  }
  
  .savebutton {
    padding: 6px 20px;
  }
  
  .savebutton-text {
    font-size: 12px;
  }

}

/* Mobile view adjustments for viewports under 320px */
@media (max-width: 340px) {
  .userprofile-card {
    padding: 20px 10px;
  }
  
  .profile-header {
    flex-direction: row;
    align-items: flex-start;
  }
  
  .profile-label,
  .profile-input {
    font-size: 12px;
    line-height: 20px;
  }
  
  .profile-input {
    line-height: 40px;
    margin-right: 10px;
    text-align: end;
  }
  
  .profile-field-wrapper {
    padding-bottom: 10px;
  }
  
  .savebutton {
    padding: 6px 20px;
  }
  
  .savebutton-text {
    font-size: 12px;
  }
}