.sidebar-filter {
    max-width: 280px;
    height: fit-content;
    background: #FFFFFF;
    border: 1px solid #0000001A;
    padding:20px;
    border-radius: 15px;
    color: #272848;
    font-size: 14px;
    margin: 118px 25px 0 10px;
   
}
.select-container-filter .scrollable-container {
    max-height: 200px; /* Adjust the height to fit 8 conditions */
    overflow-y: auto;
    padding-right: 10px; /* To avoid cutting off the scroll bar */
}

.checkbox-group {
    display: flex;
    flex-direction: column;
}

/* Scrollbar design for WebKit browsers (Chrome, Safari, etc.) */
.scrollable-container::-webkit-scrollbar {
    width: 6px; /* Thin scrollbar width */
}

.scrollable-container::-webkit-scrollbar-track {
    background: #f1f1f1; /* Track color */
}

.scrollable-container::-webkit-scrollbar-thumb {
    background-color: #FF7F50; /* Scrollbar color */
    border-radius: 10px; /* Rounded edges for smoothness */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
    background-color: #FF7F50; /* Darker shade when hovered */
}

/* For Firefox */
.scrollable-container {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #FF7F50 #f1f1f1; /* Scrollbar color and track color */
}


.filter-heading-reset {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 10px;
}
  
.sidebar-filter .filter-heading-reset h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
  
.sidebar-filter .filter-heading-reset button {
    background: none;
    border: none;
    color: #272848;
    cursor: pointer;
    margin-bottom: 10px;
}
  
.sidebar-filter .select-container-filter {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 25px;
    
}
  
.sidebar-filter .form-group select,
.sidebar-filter .form-group input {
    border: 1px solid #E2E2E2;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 12px 8px 8px;
    border-radius: 4px;
    -webkit-appearance: none; 
    -moz-appearance: none; 
    appearance: none; 
    outline: none;
    width: 100%;
    box-sizing: border-box;
    color: #272848;
}
  
.filter-date-style {
    padding-right: 40px;
    position: relative;
    z-index: 1;
}
  
.filter-date-style ::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: auto;
    height: auto;
    color: black;
    background: none;
    z-index: 2;
    
}

/*defult icons removeing in date icons*/
.filter-date-style ::-webkit-clear-button,
.filter-date-style ::-webkit-inner-spin-button,
.filter-date-style ::-webkit-calendar-picker-indicator,
.filter-date-style ::-moz-clear-button,
.filter-date-style ::-moz-inner-spin-button,
.filter-date-style ::-moz-calendar-picker-indicator,
.filter-date-style ::-ms-clear,
.filter-date-style ::-ms-expand {
  display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
  }
  
.sidebar-filter .select-container-filter .form-group label {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #272848;
}
.options{
    padding: 40px !important;
}
  
.sidebar-filter .select-container-filter .form-group .arrow-icon-filter {
    position: absolute;
    top: 60%;
    right: 25px;
    transform: translateY(-50%);
    font-size: 20px;
    color: #FF7F50;
    pointer-events: none;
}
  
.sidebar-filter .select-container-filter .form-group .date-input-container {
    position: relative;
}
  
.sidebar-filter .select-container-filter .form-group .date-input-container input {
    padding-right: 40px;
}
  
.sidebar-filter .select-container-filter .form-group .custom-calendar-icon {
    position: absolute;
    top: 56%;
    right:25px;
    transform: translateY(-50%);
    font-size: 18px;
    color: #FF7F50;
    pointer-events: none;
    z-index:10; 
}


.checkbox-group {
    display: flex;
    flex-direction: column;
}

.checkbox-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    position: relative;
}

.checkbox-item input[type="checkbox"] {
    opacity: 0;
    position: absolute;
    width: 20px;
    height: 20px;
    cursor: pointer;
    z-index: 2; /* Ensure checkbox is on top */
}

.checkbox-item .checkbox-label {
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 14px;
    color: #333;
    z-index: 1;
}

.checkbox-item .checkbox-label::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    border: 2px solid #007bff;
    background-color: #fff;
    border-radius: 4px;
    transition: background-color 0.2s ease, border-color 0.2s ease;
    z-index: -1; /* Ensure the pseudo-element is below the label text */
}

.checkbox-item input[type="checkbox"]:checked + .checkbox-label::before {
    background-color: #007bff;
    border-color: #007bff;
}

.checkbox-item input[type="checkbox"]:focus + .checkbox-label::before {
    box-shadow: 0 0 3px #007bff;
}

.filter-search-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px 10px;
    margin-bottom: 10px; /* Adjust for spacing */
    background-color: #fff;
}

.filter-search-bar {
    border: none !important;
    outline: none !important; /* Remove the default outline */
    width: 100%;
    padding: 5px;
    font-size: 14px;
    color: #272848;
}

.filter-search-bar::placeholder {
    color: #aaa; /* Lighter color for placeholder text */
}

.filter-search-icon {
    font-size: 18px;
    color: #FF7F50;
    margin-left: 10px;
}

.filter-search-container:hover {
    border-color: #007bff; /* Change border color on hover */
}

.filter-search-container:focus-within {
    border-color: #007bff; /* Change border color when input is focused */
}

.checkbox-group {
    max-height: 200px;
    overflow-y: auto;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
}

@media (max-width : 998px) {
    .sidebar-filter{
        margin: 20px;
    }
}