/*=======================Blog head================start===================*/
.Adminviewblog-dashboard-blogs-container  {
  padding: 0px 20px;
}
.Adminviewblog-dashboard-blogs-container .Admin-view-blogs-title{
  font-size: 25px;
  font-weight: 700;
  line-height: 28.8px;
  color: #272848;
  display: inline-block;
  padding: 20px 10px 0 5px;
  margin-bottom: 20px;
}
/* Using in Review scrolling render in Nice Dont worry about that
.dashboard-page-item-review-head .review-scroll{}*/

.Adminviewblog-blogs-cnt {
  width: 80%;
  /* display: flex; */
  justify-content:center;
  /* align-items:flex-start; */
  gap:20px;
}

.Adminviewblog-thoughts-cnt {
  width: 100%;
  height: 6rem;
  display: flex;
  padding: 0 20px;
  align-items: center;
  justify-content:space-evenly;
  gap:20px;
  background-color: #fff;
  box-shadow: 0 0 10px 0 #0000001A;
  border-radius: 0.5rem;
}

.Adminviewblog-thoughts-input {
  width: 100%;
  height: 50px;
  outline: none;
  border: none;
  border-radius:5px;
  padding: 1rem;
  background: #EEEEEE;
}

.Adminviewblog-add-btn {
  width: 50px;
  height: 50px;
  padding: 9px 9px 9px 8px;
  background: #272848;
  border-radius:5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Adminviewblog-add-icon {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

/*=======================Blog head================End===================*/


/* Scroll setup */
.Adminviewblog-blog-list-cnt {
  overflow-y: auto;
  height: calc(100vh - 175px); 
}


.Adminviewblog-blog-list-cnt::-webkit-scrollbar {
  width: 0px;
}
/*=======================Blog page================Start===================*/
.Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
  display: flex;
  flex-direction: column;
  height: auto;
  margin:20px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0 0 10px 0 #0000001A;
  padding: 1rem;
}

.Adminviewblog-blog-card-img {
  width: 100%;
  height:250px;
  object-fit: cover;
  border-radius:5px;
}

.Adminviewblog-blog-card-profileInfo-cnt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
}

.Adminviewblog-profileInfo-cnt {
  display: flex;
  gap:20px;
  justify-content:center;
  align-items: center;
}

.Adminviewblog-blog-card-profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.Adminviewblog-profileInfo h4 {
  color: #333448;
  font-size: 15px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
}

.Adminviewblog-profileInfo p {
  font-size:10px;
  font-weight: 500;
  color: #33354880;
  line-height: 16.4px;
  margin: 0;
}

.Adminviewblog-date-info-cnt {
  display: flex;
  align-items:center;
  justify-content: center;
  gap:10px;
  margin:0 15px;
}

.Adminviewblog-date-info-cnt .Adminviewblog-blue-text {
  color: #0167FF;
  font-size: 12px;
  font-weight:500;
  margin:0;
}

.Adminviewblog-blog-content-preview-cnt {
  margin-top:30px;
}

.Adminviewblog-blog-content-preview-cnt h4 {
  color: #272848;
  font-size: 18px;
  font-weight: 600;
  line-height: 19.2px;
}

.Adminviewblog-blog-content-preview-cnt p {
  font-size: 14px;
  font-weight: 400;
  color: #33354880;
  text-align: justify;
  line-height: 25px;
  width:80%;
}

.Adminviewblog-readMore-cnt {
  display: flex;
  align-items:center;
  gap:10px;
  color: #0167FF;
  cursor: pointer;
}
.Adminviewblog-readMore-cnt h4 {
  font-size:14px;
  font-weight: 500;
  line-height: 21px;
}
.Adminviewblog-readMore-cnt-icon{
  margin-bottom: 5px;
}
/*=======================Blog page================End===================*/

/*=======================blogdetailstransition====start===================*/
.Adminviewblog-blogdetailstransition{
  display: flex;
  flex-direction: column;
  gap:20px;
  transition: all 0.25s cubic-bezier(0.5, 0, 0.1, 1) 0s;
}

/*=======================blogdetailstransition====End===================*/



/*======================Categories compontent===============Start=====================*/


.Adminviewblog-blog-cnt {
  width: 100%;
  height:auto;
  display: flex;
  flex-direction: column;
  gap:20px;
}

.Adminviewblog-blog-post-cnt {
  margin-top: 10px;
  padding:10px 1rem;
  border-radius:8px;
  box-shadow: 0 0 10px 0 #0000001A;
}

.Adminviewblog-blog-post-cnt h4 {
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  color: #272848;
}

.Adminviewblog-blog-status-info {
  width: 100%;
  display: flex;
  gap: 0.3rem;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}
.Adminviewblog-blog-status-info .read-more-text{
  color: #33344880;
  font-size: 12px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
}

.Adminviewblog-date-info-cnt {
  gap: 10px;
  display: flex;
}

.Adminviewblog-date-info-cnt-icon{
  color: #33344880;
}

.Adminviewblog-blog-status {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:5px;
}

.Adminviewblog-blog-image {
  width:100%;
  max-height: 576px;
  object-fit: cover;
}

.Adminviewblog-blog-description {
  margin-top: 1rem;
  color: #33344880;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  text-align: justify;
}

.Adminviewblog-social-reach-cnt {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top:px;
}

.Adminviewblog-social-reach-cnt .facebook-icon {
  width:30px;
  height:30px;
  padding:5px;
  border-radius:5px;
  background: #c0c0c080;
}

.Adminviewblog-social-reach-cnt span {
  color: #33344880;
  font-size:12px;
  font-weight: 700;
}

.Adminviewblog-blog-tags {
  flex-wrap: wrap;
  display: flex;
  gap:15px;
  margin:30px 0;
}

.Adminviewblog-blog-tags-content{
  background: #3334481A;
  border-radius:5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:6px 15px;
}

.Adminviewblog-blog-tags-item{
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: #272848;
}

/*blogger-details Satrt*/
.Adminviewblog-blogger-details-cnt {
  border-radius:5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap:10px;
  padding:1rem;
  border-radius:8px;
  box-shadow: 0 0 10px 0 #0000001A;
}

.Adminviewblog-profile-img {
  width: 34px;
  height:34px;
  object-fit: cover;
  border-radius: 50%;
}

.Adminviewblog-profile-bio {
  text-align: center;
}

.Adminviewblog-profile-socials-cnt {
  display: flex;
  gap: 1.5rem;
  margin: 10px 0;
}
/*blogger-details End*/

/*comments-Read  Start*/
.Adminviewblog-comments-cnt {
  border-radius:5px;
  display: flex;
  flex-direction: column;
  gap:10px;
  padding:1rem;
  border-radius:8px;
  box-shadow: 0 0 10px 0 #0000001A;
}

.Adminviewblog-comments-title {
  color: #272848;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}

.Adminviewblog-style-comment-cnt {
  display: flex;
  align-items: end;
  justify-content: space-between;
  gap: 3rem;
  padding: 1rem 0;
  border-bottom: 1px solid #e9e9e9;
}

.Adminviewblog-comment-header-text{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap:2rem;
  padding:0 1rem;
}

.Adminviewblog-comment .Adminviewblog-comment-title{
  color: #272848;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.8px;
  margin: 0;
}
.Adminviewblog-comment .Adminviewblog-comment-time-txt {
  font-size: 10px;
  line-height: 21px;
  color: #33344880;
  font-weight: 500;
  margin: 0;
}

.Adminviewblog-comment .Adminviewblog-comment-txt {
  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  text-align: justify;
  color: #33344880;
  margin: 0;
}

.Adminviewblog-style-comment-cnt .Adminviewblog-replay-btn {
  display: flex;
  gap: 0.2rem;
  align-items:center;
  cursor: pointer;
  color: #0167FF;
  padding:30px 10px 0 0;
}

.Adminviewblog-style-comment-cnt .Adminviewblog-replay-btn span {
  font-size: 12px;
  font-weight: 500;
}
/*comments-cnt  End*/

/*post-comment  Start*/

.Adminviewblog-leave-comment-cnt {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center;
  gap:10px;
  padding:1rem;
  border-radius:8px;
  box-shadow: 0 0 10px 0 #0000001A;
  display: inline;
}

.Adminviewblog-textarea-comment-post {
  width: 100%;
  height: 10rem;
  position: relative;
  margin-top: 1rem;
  padding: 5px 0;
  border-radius: 0.5rem;
  border: 1px solid #e9e9e9;
}

.Adminviewblog-input-textarea-commit-post {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  overflow-y: auto;
  outline: none;
  border: none;
  resize: none;
  color:#272848;
  font-size: 14px;
  font-weight: 500;
}

/* .input-textarea-commit-post::-webkit-scrollbar {
  width: 0px;
} */

.Adminviewblog-input-placeholder-commit-post {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0 0.5rem;
  transition: all 200ms ease-in-out;
  font-weight: 500;
  pointer-events: none;
}

.Adminviewblog-input-placeholder-commit-post.focused {
  top: -0.8rem;
  left: 1rem;
  font-size: 0.85rem;
  color: #999;
  background: #ffffff;
}

.Adminviewblog-comment-term-conformation-cnt {
  display: flex;
  align-items:center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.Adminviewblog-comment-checkbox {
  width: 15px;
  height: 15px;
}

.Adminviewblog-checkbox-comment-txt {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.5px;
  color: #333448;

}

.Adminviewblog-submit-button {
  margin-top: 1rem;
  padding: 0.2rem 0.8rem;
  border: none;
  background: none;
  border-radius:5px;
  color: #272848;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  border: 1.5px solid #0167FF;
}

.Adminviewblog-submit-button:hover {
  background-color: #0167FF;
  color: #FFFFFF;
}


.Adminviewblog-comment-top-input-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  gap:1rem;
} 

.Adminviewblog-create-blog-cnt {
  width: 100%;
  height: 35.5rem;
  padding: 2rem;
}

.Adminviewblog-blog-details-cnt {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  justify-content: space-between;
}

.Adminviewblog-blog-short-input-cnt {
  position: relative;
  width: 30rem;
  height: 2.5rem;
}

.Adminviewblog-blog-short-input {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 0.5rem;
  border: 1px solid #e9e9e9;
  outline: none;
}

.Adminviewblog-input-placeholder {
  position: absolute;
  top: 0.5rem;
  left: 0.8rem;
  background: #ffffff;
  padding: 0 0.5rem;
  transition: all 200ms ease-in-out;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

.Adminviewblog-text-editor-cnt {
  margin-top: 2rem;
}

.Adminviewblog-text-editor {
  height: 10rem;
}

.Adminviewblog-blog-long-input-cnt {
  width: 100%;
  height: 2.5rem;
  position: relative;
  margin-top:1rem;
  border-radius: 0.5rem;
  border: 1px solid #e9e9e9;
}

.Adminviewblog-file-input {
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
}

.Adminviewblog-choose-file-label {
  position: absolute;
  right: 0;
  width: 8rem;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: rgb(216, 216, 246);
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  border-radius: 0.5rem;
}

.Adminviewblog-absolute-file-name {
  position: absolute;
  left: 1rem;
  top: 0;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
} 

.Adminviewblog-btns-cnt {
  width: 100%;
  height: 2.5rem;
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 2rem;
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
}

.Adminviewblog-action-btn {
  width: 8rem;
  height: 100%;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #2e37a4;
  color: white;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  cursor: pointer;
}

.Adminviewblog-status-input-cnt {
  position: relative;
  width: 30rem;
}

.Adminviewblog-checkbox-cnt {
  width: fit-content;
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.Adminviewblog-checkbox {
  border-radius: 50%;
  background: #000;
}

.Adminviewblog-select-option-cnt {
  max-width: fit-content;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: space-between;
  margin-left: 1rem;
}

.Adminviewblog-select-option-cnt p {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  color: #33344880;
}


/*post-comment  End*/

/*======================Categories compontent===============End=====================*/
/* Media Queries for Responsive Design */

/* @media (max-width:1440px){

   
} */

/* Media Queries for Responsive Design */

@media (max-width: 1279px) {
  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: auto;
  }
  .Adminviewblog-thoughts-cnt {
    width: auto;
  }
  .Adminviewblog-blogs-cnt {
    gap: 0;
  }
}

@media (max-width: 1200px) {
  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 430px;
  }
  .Adminviewblog-thoughts-cnt {
    width: auto;
  }
}

@media (max-width: 1100px) {
  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 400px;
  }
}

@media (max-width: 1024px) {
  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 380px;
  }
}

@media (max-width: 998px) {
  .Adminviewblog-blogs-cnt {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .Adminviewblog-widget-cnt .Adminviewblog-related-post-cnt,
  .Adminviewblog-widget-cnt .Adminviewblog-tags-widget-cnt {
    width: 100%;
    padding: 20px;
  }

  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .Adminviewblog-blogs-cnt {
    flex-direction: column;
    align-items: center;
  }

  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 450px;
  }
}

@media (max-width: 768px) {
  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 470px;
  }
}

@media (max-width: 576px) {
  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 350px;
  }
}

@media (max-width: 480px) {
  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 270px;
  }
  .Adminviewblog-thoughts-cnt {
    width: 270px;
  }
  .Adminviewblog-thoughts-input::placeholder {
    font-size: 12px;
  }
}

@media (max-width: 380px) {
  .Adminviewblog-blog-list-cnt .Adminviewblog-blog-card-cnt {
    width: 200px;
  }
  .Adminviewblog-thoughts-cnt {
    width: 200px;
  }
  .Adminviewblog-thoughts-input::placeholder {
    font-size: 8px;
  }
}



/*enhhhhxxxxxxxxxxxxxxxxxxcccccccccccccccttttttttttthhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh*/






