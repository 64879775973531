.layout-profile {
 background: linear-gradient(180deg, #EDF3FF 50%, #F4F7FC 100%);
 padding:5rem;  
}

.layout-profile h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: left;    
    color: #212121;
    display: inline-block;
}

.container-profile-head {
    display: flex;
    justify-content:center;
    align-items:flex-start;
    gap:5px;
}

.profile-card {
    width: 300px;
    /*height:493px;*/
    height: auto;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 4px 8px 32px 0px #0000003D;
    padding: 20px 15px;
}

.profile-card .profile-content {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    gap: 20px;
}

.profile-card .profile-content .profileimg {
    width: 56px;
    height: 56px;
    border-radius: 20px 5px;
    margin-bottom: 10px;
    object-fit: cover;
}

.profile-card .profile-content p {
    margin: 0;
}

.profile-card .profile-content .hold-content {
    padding-top: 10px;
}

.profile-card .profile-content .hold-content .name {
    font-size: 12px;
    font-weight: 400;
    color: #1F2937;
}

.profile-card .profile-content .hold-content .email {
    font-size: 12px;
    font-weight: 400;
    color: #6B7280;
}

.profile-card nav ul {
    list-style-type: none;
    padding: 0;
    border-top: 1px solid #E5E7EB;
}

.profile-card nav ul li {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
}

.profile-card nav ul li a {
    text-decoration: none;
    color: #1F2937;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px;
}

.profile-card nav ul li a span {
    margin-left:15px;
    flex-grow: 1;
}

.profile-card nav ul .active{
    background: #F9FAFB;
}

.profile-card nav ul li:first-child {
    border-top: none;
    margin-top: 20px;
}

.outlet-content {
    flex: 1;
}


@media (max-width: 998px) {
    .layout-profile h1 {
        display: block;
        text-align: center;
        font-size: 28px;
    }
    .container-profile-head {
        flex-direction: column;
        align-items: center;
    }
    .profile-card {
        width: 300px;
        height: auto;
        margin-bottom: 10px;
    }
}