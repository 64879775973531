
.blogPage-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.blogPage-loading-spinner {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid #ffffff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}









/*Blog container overAll*/
.blogPageOuterBox {
  padding: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:50px;
  overflow: hidden;
  flex-direction: column;
  background-color: #f7faff;
}

.blogPageBox {
  display: flex;
  gap: 30px;
  justify-content:center;
}

.blogPageLHS {
  display: flex;
  gap: 25px;
  flex-direction: column;
  justify-content:center;
  max-width: 700px;
}

/*=================Featured Section==========Start=================== */
.blogPageFeaturedHeading,
.blogPageDefaultCardHeading{
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  color:#272848; 
  padding-left:6px; 
  border-left: 3.5px solid #0167ff;
}

.blogPageFeaturedBox {
  display: flex;
  justify-content:center;
  max-width: 700px;
  gap:20px;
}

.blogPageFeaturedLHS {
  display: flex;
  gap: 15px;
  width:364px;
  flex-direction: column;
 
}

.blogPageFeaturedLHS > a > img {
  width: 364px;
  height:384px;
  object-fit: cover;
}

.Featuredblog-title{
  color: rgba(0, 0, 0, 1);
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  margin-bottom: 15px;
}

.Featuredblog-title:hover{
  text-decoration: underline;
  text-decoration-color:rgba(0, 0, 0, 1) ;
}

.Featuredblog-description{
  color: #444548;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
/*============BlogMiniCard===============Start===========*/
.blogPageFeaturedRHS {
  width:321px;
  display: flex;
  gap: 7.5px;
  flex-direction: column;
}

.blogPageFeaturedRHSCard {
  display: flex;
  gap: 10px;
  width: 321px;
  height: 92px;
  border-bottom: 0.5px solid #cccccc;
}

.blogPageFeaturedRHSCard:first-child {
  padding-top: 0px;
}

.blogPageFeaturedRHSCard > img {
  width: 150px;
  height: 85px;  
  object-fit: cover;
}

.blogPageFeaturedRHSCard > div > div:first-child {
  color: black;
  line-height: 1.3;
  font-weight: 500;
  font-size: 0.8em;
  margin-bottom:10px;
}

.blogPageFeaturedRHSCard > div > div:nth-child(2) {
  color: #666666;
  font-size: 0.6em;
  font-weight: 300;
}

.blogPageDefaultCardOuter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap:50px;
}

.blogPageDefaultCardHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 576px;
}

.blogPageDefaultCardHeader > a {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-decoration: underline;
  transition: all 0.15s ease;
  text-decoration-color: white;
}

.blogPageDefaultCardHeader > a:hover {
  color: #004cff;
  transform: scale(1.05);
}

.blogPageDefaultCardBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blogPageDefaultCard {
  display: flex;
  justify-content: space-between;
  align-items:start;
  gap:20px;
  padding: 12px 0px 20px 0px;
  border-bottom: 0.5px solid #cccccc;
}

.blogPageDefaultCardLHS {
  display: flex;
  flex-direction: column;
  gap:2.5px;
}

.blogPageDefaultCardLHS > a > img {
  width: 115px;
  height: 101px;
  object-fit: cover;
}

.blogPageDefaultCardAuthor {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; 
  margin: 0;
}

.blogPageDefaultCardDate {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  margin: 0; 
}

.blogPageDefaultCardRHS {
  display: flex;
  flex-direction: column;
  justify-content:center;
  gap:15px;
}

.blogPageDefaultCardRHS > a {
  font-weight: 500;
  line-height: 1.1;
  font-size: 1em;
  text-decoration: none; 
  color: inherit;
  display: block;
}

.blogPageDefaultCardRHS > a:first-child {
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  display: block;
}

.blogPageDefaultCardRHS > div:nth-child(2) {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(68, 69, 72, 1);
  margin: 0;
}
/*============BlogMiniCard===============End===========*/
/*===================Featured Section========End===================*/

/*===============Side Card read mode ... Tag Selection=========Start=========== */

.blogPageRHS .blogPageRHSContent {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 545px;
  height:fit-content;
  padding: 19.7px 20px 20px 20px;
  border-radius: 10px;
  background-color: white;
}

.blogPageRHS-defaultCardBox {
  cursor: default;
  display: flex;
  text-align: start;
  flex-direction: column;
}

.blogPageRHS-defaultCardBoxHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 2px solid rgba(46, 55, 164, 0.05)
}

.blogPageRHS-defaultCardBoxHeader > a{
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: rgba(1, 103, 255, 1);
  transition: all 0.15s ease;
}


.blogPageRHS-defaultCardBoxHeader > a:hover{
  color: #004cff;
  text-decoration: underline;
  transform: scale(1.05);
}

.blogPageRHS-defaultCardBoxTitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  color: rgba(51, 53, 72, 1);
}

.blogPageRHS-defaultCard-grid {
  width: 100%;
  display: grid;
  column-gap: 10%;
  justify-content: space-between;
  grid-template-columns: repeat(1, 1fr);
}

.blogPageRHS-defaultCard {
  display: flex;
  align-items: start;
  gap:20px;
  padding: 20px 0;
  border-bottom: 1px solid #eeeeee;
}

.blogPageRHS-defaultCard-left {
  display: flex;
  flex-direction: column;
}

.blogPageRHS-defaultCard-image {
  width: 80px;
  height: 58.55px;
  border-radius:10px;
  object-fit: cover;
}

.blogPageRHS-defaultCard-right{
  display: flex;
  flex-direction: column;
  gap:6.5px;
}
.blogPageRHS-defaultCard-flex {
  display: flex;
  gap: 10px;
  font-size: 12px;
  align-items: center;
}

.blogPageRHS-defaultCard-chips {
  padding: 4px 8px;
  color: #333548;
  font-weight: 600;
  font-size:10px;
  border-radius: 8px;
  background: rgba(51, 52, 72, 0.1);
  text-transform: capitalize;
}

.blogPageRHS-defaultCard-date {
  font-weight: 500;
  font-size: 1em;
  color: #33344880;
}

.blogPageRHS-defaultCard-title {
  font-size: 0.9em;
  color: #333448;
  font-weight: 600;
  line-height: 1em;
}

.blogPageRHS-defaultCard-readmore{
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(1, 103, 255, 1);
  transition: all 0.15s ease;
}

.blogPageRHS-defaultCard-readmore:hover{
  color: rgba(1, 103, 255, 1);
  text-decoration: underline;
  transform: translateX(2px);
}

.blogPagetags-chips {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  margin-top: 1.25vw;
}

.blogPagetags-chip {
  display: flex;
  gap: 7px;
  cursor: pointer;
  color: #333448;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; 
  padding: 5px 12px;
  border-radius:5px;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  background: rgba(51, 52, 72, 0.1);

}

.blogPagetags-chip:hover {
  background-color: #bdbdbd;
}
/*===============Side Card read mode ... Tag Selection=========End=========== */

/*=============Blog Four to support Four View Card ===============Start===================*/
.Blog-six-card-hold-container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap:30px;
  max-width: 1279px;

}

.Blog-six-card-container{
  display: flex;
  justify-content:space-between;
  align-items: center;
}

.Blog-six-card-container h2{
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  color: rgba(39, 40, 72, 1);
  padding-left:6px; 
  border-left: 3.5px solid #0167ff;
}

.Blog-six-card-style-flex {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two equal columns */
  row-gap: 30px;
  column-gap: 130px;
}

.blog-left:nth-child(1) {
  grid-column: 1; /* First column */
  grid-row: 1;    /* First row */
}

.blog-left:nth-child(2) {
  grid-column: 1; /* First column */
  grid-row: 2;    /* Second row */
}

.blog-left:nth-child(3) {
  grid-column: 1; /* First column */
  grid-row: 3;    /* Third row */
}

.blog-right:nth-child(4) {
  grid-column: 2; /* Second column */
  grid-row: 1;    /* First row */
}

.blog-right:nth-child(5) {
  grid-column: 2; /* Second column */
  grid-row: 2;    /* Second row */
}

.blog-right:nth-child(6) {
  grid-column: 2; /* Second column */
  grid-row: 3;    /* Third row */
}

.BlogBiggerCard-container{
  max-width: 576px;
}
.BlogBiggerCard-insider-Box{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.BlogBiggerCard-image-content{
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
  padding: 12px 0px 20px 0px;
  /* border-bottom: 0.5px solid #cccccc; */
}


.BlogBiggerCard-image {
  display: flex;
  flex-direction: column;
  gap:2.5px;
}

.BlogBiggerCard-image > a > img {
  width: 115px;
  height: 101px;
  object-fit: cover;
}

.BlogBiggerCardAuthor {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px; 
  margin: 0;
}

.BlogBiggerCardDate {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: rgba(102, 102, 102, 1);
  margin: 0;
  
}

.BlogBiggerCard-title-desc-container{
  display: flex;
  flex-direction: column;
  justify-content:center;
  gap:15px;
}

.BlogBiggerCard-title-desc-container > a {
  font-weight: 500;
  line-height: 1.1;
  font-size: 1em;
  text-decoration: none; 
  color: inherit;
  display: block;
}

.BlogBiggerCard-title-desc-container > a:first-child {
  font-size: 22px;
  font-weight: 500;
  line-height: 26.4px;
  text-align: left;
  color: rgba(0, 0, 0, 1);
  display: block;
}

.BlogBiggerCard-title-desc-container > div:nth-child(2) {
  height:fit-content;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: rgba(68, 69, 72, 1);
  margin: 0;
}

.BlogBiggerCard-title-desc-container > a.BlogBiggerCard-read-more {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  transition: all 0.15s ease;
  text-decoration-color: white;
  color: #004cff;
}

.BlogBiggerCard-title-desc-container > a.BlogBiggerCard-read-more:hover {
  color: #004cff;
  text-decoration: underline; 
  transform: translateX(2px);
}
/*=============Blog Four to support Four View Card ===============End===================*/

/*=============Blog Doctors Card ===============Start===================*/
.blogPage-Doctors-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 10px;
  max-width: 1282px; /* Ensure it fits the screen size */
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.blogPage-Doctors-title-head h2{
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  color: rgba(39, 40, 72, 1);
  padding-left:6px; 
  border-left: 3.5px solid #0167ff;
}
.blogPageDoctorCardBox {
  display: flex;
  align-items: flex-start;
  gap: 20px;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden;
  padding-bottom: 15px;
  scroll-behavior: smooth;
  white-space: nowrap; /* Prevent cards from wrapping */
}

.blogPageDoctorCardBox::-webkit-scrollbar {
  height:4.5px;
}

.blogPageDoctorCardBox::-webkit-scrollbar-thumb {
  background-color: #0167ff; 
  border-radius: 10px; 
}

.blogPageDoctorCard {
  flex: 0 0 298px; /* Fixed card width */
  height: 269px;
  display: inline-flex; /* Aligns items horizontally */
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  cursor: pointer;
  margin-top: 60px;
  border-radius: 12px;
  transition: all 0.2s ease;
  background: rgba(255, 255, 255, 1);
  border: 0.4px solid rgba(1, 103, 255, 0.4);
  box-shadow: 0px 2px 4px rgba(1, 103, 255, 0.08);
}

.blogPageDoctorCard:hover {
  border: 0.4px solid #0167ff;
}

.blogPageDoctorCardImgBox {
  position: relative;
  top: -33%;
  bottom: 0;
  margin: 0 auto;
  
}

.blogPageDoctorCardImgBox > a > img {
  width: 94px;
  height: 94px;
  padding: 2px;
  object-fit: cover;
  border-radius: 50%;
  background-color: #ff7f50;
}

.blogPageVerifiedTick {
  position: absolute;
  right: 0.06vw;
  bottom: 0.3vw;

}

.blogPageDoctorCard-content{
  position: relative;
  top:-28%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.blogPageDoctorCardHeader {
  display: flex;
  justify-content: space-between;
}

.blogPageDoctorCardName {
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  color: rgba(0, 0, 0, 1);
}

.blogPageDoctorCardRating {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: white;
  font-size: 10px;
  font-weight: 400;
  width: 41px;
  height: 18px;
  border-radius: 5px;
  align-items: center;
  background-color: #ff7f50;
}

.blogPageDoctorCardSpecialist,
.blogPageDoctorCardExp {
  display: flex;
  align-items: center;
  gap: 10px;
}

.blogPageDoctorCardSpecialist p,
.blogPageDoctorCardExp p {
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  color: rgba(68, 68, 68, 1);
  margin: 0;

}

.blogPageDoctorCardDesc {
  width: 258px;
  height: 42px;
  font-size: 10px;
  font-weight: 400;
  line-height: 13.5px;
  color: rgba(0, 0, 0, 1);
  
}

.blogPageDoctorCard-content .blogPageDoctorCardBtn {
  width: 145px;
  height: 37px;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  background-color: #0167ff;
  text-align: center;
  line-height: 37px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.blogPageDoctorCard-content .blogPageDoctorCardBtn:hover {
  background-color: #edf3ff;
  border: 0.4px solid #0167ff;
  color: #0167ff;
  transform: scale(1.05);
}
/*=============Blog Doctors Card  ===============End===================*/

/*=============Larger Blog Card ===============Start===================*/
.BlogLargerCard-Container{
  display: flex;
  justify-content:center;
  flex-direction: column;
  gap:30px;
}

.BlogLargerCard-Container h2{
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  color: rgba(39, 40, 72, 1);
  padding-left:6px; 
  border-left: 3.5px solid #0167ff;
}
.BlogLargerCard-content-center{
  display: flex;
  align-items: center;
  flex-direction: column;
  gap:30px;
  
}

.BlogLargerCard-inside{
  display: flex; 
  justify-content:space-between;
  align-items: start;
  max-width: 1279px;
  gap:100px;
  border-bottom:0.5px solid #cccccc;
}

.BlogLargerCard-img img{
  width: 578px;
  height: 270px;
  object-fit:cover; 
  margin-bottom: 20px;
}

.BlogLargerCard-title-body{
  display: flex;
  align-items: start;
  flex-direction: column;
  margin-bottom: 20px;
  max-width: 603px;
  height:fit-content;
  gap:15px;
  
}

.BlogLargerCard-main-title{
  font-size: 30px;
  font-weight: 500;
  line-height: 36px;
  color: rgba(0, 0, 0, 1);
}

.BlogLargerCard-descripton{
  color: rgba(68, 69, 72, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.BlogLargerCard-author-name{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: rgba(0, 0, 0, 1);
}

.BlogLargerCard-Date-year{
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: rgba(102, 102, 102, 1);
}

.BlogLargerCard-read-more{
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: rgba(1, 103, 255, 1);
  transition: all 0.15s ease;
}

.BlogLargerCard-read-more:hover{
  color: rgba(1, 103, 255, 1);
  text-decoration: underline;
  transform: translateX(2px);
}

.BlogLargerCard-btn {
  width: 244px;
  height: 54px;
  padding: 14px 65px 13px 67px;
  border: none;
  outline:none;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: white;
  margin-top: 70px;
  border-radius: 5px;
  background: #0167ff;
  transition: background 0.3s ease, transform 0.3s ease;
}

.BlogLargerCard-btn:hover {
  background-color: #edf3ff;
  border: 0.4px solid #0167ff;
  color: #0167ff;
  transform: scale(1.05);
}

/*=============Larger Blog Card ===============End===================*/

@media (max-width: 1440px) and (min-width: 1200px) {
 
  .blogPageOuterBox {
    padding: 50px;
    gap: 40px;
  }
  .blogPageBox {
    gap: 25px;
  }

  /*=============Blog Doctors Card ===============*/
  .blogPage-Doctors-container {
    max-width: 100%; /* Prevent shrinking */
    padding: 0 20px; /* Add padding for smaller screens */
  }

  .blogPageDoctorCard {
    flex-basis: calc(40.5% - 100px);/* Four cards visible */
  }

  /*=============Blog Four View in Card ===============*/
   .Blog-six-card-style-flex {
    column-gap: 90px;
  }

  /*=============Larger Blog Card ===============*/
  .BlogLargerCard-inside {
    gap: 70px;
  }

  .BlogLargerCard-img img {
    width: 460px;
    height: 270px;
  }

  .BlogLargerCard-title-body {
    max-width: 100%;
  }

  .BlogLargerCard-Container h2 {
    font-size: 24px;
  }

  .BlogLargerCard-main-title {
    font-size: 26px;
  }

  .BlogLargerCard-btn {
    width: 200px;
    height: 45px;
    font-size: 16px;
    margin-top: 35px;
    padding: 10px 50px;
  }
}

@media (max-width: 1199px) and (min-width: 999px) {
  .blogPageOuterBox {
    padding: 40px;
  }
  .blogPageBox {
    flex-direction: row;
    gap: 20px;
  }

  .blogPageFeaturedBox {
    flex-direction: column;
  }
  .blogPageFeaturedLHS > a > img {
    width: 45vw; 
  }

  /*=============Blog Doctors Card ===============*/
  .blogPageDoctorCard {
    flex: 0 0 calc(29.33% - 20px); /* Three cards visible */ 
  }

  /*=============Larger Blog Card ===============*/
  .BlogLargerCard-inside {
    gap: 50px;
  }

  .BlogLargerCard-img img {
    width: 360px;
    height: 270px;
  }

  .BlogLargerCard-title-body {
    max-width: 100%;
  }

  .BlogLargerCard-Container h2 {
    font-size: 22px;
  }

  .BlogLargerCard-main-title {
    font-size: 24px;
  }

  .BlogLargerCard-btn {
    width: 200px;
    height: 45px;
    font-size: 16px;
    margin-top: 25px;
    padding: 10px 50px;
  }
}


@media (max-width: 998px) {
  /*Blog container overAll*/
  .blogPageOuterBox {
    padding: 30px;
    gap: 30px;
  }

  .blogPageOuterBox {
    padding: 70px;
    display: flex;
    justify-content: start;
    align-items: start;
    gap: 50px;
    overflow: hidden;
    flex-direction: column;
    background-color: #f7faff;
  }
 
  .blogPageBox {
    flex-direction: column;
    gap: 15px;
  }
  .blogPageLHS > a > img {
    height: 300px;
  }

  .blogPageRHS .blogPageRHSContent {
    max-width: 100%;
  }
  


  /*=============Blog Doctors Card ===============*/
  .blogPageDoctorCard {
    flex: 0 0 calc(35.3% - 20px); /* two cards visible */
   
  }
  /*=============Blog Four View in Card ===============*/
  .Blog-six-card-hold-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    width:100%;
  }
  .Blog-six-card-style-flex {
    display:flex;
    flex-direction: column;
    row-gap: 30px;
    column-gap: 130px;
  }

  /*=============Larger Blog Card ===============*/
  .BlogLargerCard-content-center {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 25px;
  }

  .BlogLargerCard-inside {
    flex-direction: column;
    gap: 15px;
  }

  .BlogLargerCard-img img {
    max-width: 100%;
  }


  .BlogLargerCard-btn {
    margin-top: 30px;
  }
}


@media (max-width: 768px) {
  /*Blog container overAll*/
  .blogPageOuterBox {
    padding: 30px;
  }

  .blogPageFeaturedBox {
    flex-direction: column;
    align-items: center;
  }
  .blogPageFeaturedLHS {
    width: 100%;
  }
  .blogPageFeaturedLHS > a > img {
    width: 100%;
    height:fit-content;
  }
  .blogPageFeaturedRHS {
    width: 100%;
    gap: 20px;
  }
  .blogPageDefaultCardHeader {
    max-width: 100%;
  }
  .blogPageRHS {
    display: flex;
    justify-content: center;
  }
  .blogPageFeaturedRHSCard {
    display: flex;
    gap: 20px;
    width: 100%;
    height: 100px;
  }
  .blogPageFeaturedRHSCard > div > div:nth-child(2) {
    margin-bottom: 10px;
  }


  /*=============Blog Doctors Card ===============*/
  .blogPage-Doctors-container {
    max-width: 100%; /* Ensure full width on mobile */
    padding: 0 10px;
  }
  
  .blogPageDoctorCard {
    flex: 0 0 calc(33.5% - 10px);/* One card visible */
  }

  /*=============Larger Blog Card ===============*/
  .BlogLargerCard-Container h2,
  .BlogLargerCard-main-title {
    font-size: 22px;
  }

  .BlogLargerCard-img img {
    width: 850px;
    height: 270px;
  }

  .BlogLargerCard-title-body {
    max-width: 100%;
  }

  .BlogLargerCard-btn {
    margin-top:25px;
  }
}

@media (max-width: 576px) and (min-width: 320px) {
  /*Blog container overAll*/
  .blogPageOuterBox {
    padding: 20px;
  }

  .blogPageOuterBox {
    padding: 20px;
  }

  .blogPageBox {
    gap: 10px;
  }
  .blogPageLHS > a > img {
    height: 200px;
  }
  .blogPageFeaturedHeading {
    font-size: 24px;
  }
  .Featuredblog-title {
    font-size: 18px;
  }
  .blogPageFeaturedRHSCard {
    flex-direction: column;
    height: auto;
  }
  .blogPageFeaturedRHSCard > img {
    width: 100%;
    height: 150px;
  }







  /*=============Blog Doctors Card ===============*/

  .blogPageDoctorCard {
    flex: 0 0 calc(30.3% - 100px);/* One card visible */
  }

  /*=============Larger Blog Card ===============*/
  .BlogLargerCard-Container {
    gap: 15px;
  }

  .BlogLargerCard-inside {
    gap: 20px;
  }

  .BlogLargerCard-Container h2,
  .BlogLargerCard-main-title {
    font-size: 18px;
  }

  .BlogLargerCard-btn {
    margin-top: 20px;
    width: 200px;
    height: 54px;
    padding:10px 20px;
  }
}












































/* CONDITION LIBRARY NAVBAR */

/* Banner styles */
.condition-banner-container {
  background-image: url('../Assets/condition_lib_bg.png'); /* Correct image path */
  background-size: cover;
  background-position: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  position: relative; 
}

.condition-banner-container h1 {
  font-size: 48px;
  font-weight: bold;
  margin: 0;
  line-height: 1.2;
  color: #0167ff;
}


/* Navbar styles inside banner */
.condition-nav-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 15px 0;
  background-color: rgba(0, 0, 0, 0.4); /* Optional semi-transparent background */
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: auto; /* Enable horizontal scroll */
  white-space: nowrap; /* Prevent wrapping of links */
}

.condition-nav-tabs::-webkit-scrollbar {
  height: 0px; /* Small scrollbar for aesthetic purposes */
}

.condition-nav-tabs::-webkit-scrollbar-thumb {
  background-color: #0167ff;
  border-radius: 10px;
}

.condition-nav-tabs a {
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none !important;
  padding-bottom: 5px;
  position: relative;
  white-space: nowrap; /* Ensure links don’t wrap */
}

.condition-nav-tabs a:hover {
  color: #fff;
}

.condition-nav-tabs a.active {
  border-bottom: 3px solid #fff;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .condition-banner-container {
    padding: 60px 10px; /* Reduce padding on smaller screens */
  }

  .condition-banner-container h1 {
    font-size: 32px; /* Smaller title for mobile */
  }

  .condition-nav-tabs {
    padding:20px;
    justify-content: flex-start; /* Align tabs to the left for easier scrolling */
  }
}

@media (max-width: 576px) {
  .condition-banner-container {
    padding: 40px 5px;
  }

  .condition-banner-container h1 {
    font-size: 24px; /* Further reduce font size for small screens */
  }
}





