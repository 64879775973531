.chat-window-container {
  width: 90%;
  display: flex;
  flex-direction: column;
  height: 75vh;
  font-family: 'Matter', sans-serif;
  /* background-color: rgb(21, 21, 170); */
}
.placeholder-message{
  z-index: 1000;
  width: 100%;
  height: 100vh;
  margin: -70px 0px;
display: flex;
align-items: center;
justify-content: center;
background: rgba( 255, 255, 255, 0 );
/* box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 ); */
backdrop-filter: blur( 1.5px );
-webkit-backdrop-filter: blur( 1.5px );
border-radius: 10px;
border: 1px solid rgba( 255, 255, 255, 0.18 );
}
.placeholder-message .btn-primary{
  background: rgba( 20, 65, 230, 0.65 );
  box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
  backdrop-filter: blur( 12.5px );
  -webkit-backdrop-filter: blur( 12.5px );
  /* border-radius: 10px; */
  border: 1px solid rgba( 255, 255, 255, 0.18 );
  padding: 20px;
  border-radius: 30px 5px;
}

@media (max-width: 768px) {
  .chat-window-container {
    width: 100%;
  }
}
