 
 
 .blog-preview {
    border: 1px solid #ccc;
    padding: 20px;
    margin-top: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    width: 859px;
    height: auto;
  }
  
  .blog-preview h3 {
    margin-bottom: 10px;
    color: #333;
  }
  
  .blog-preview h2 {
    margin-bottom: 10px;
    color: #555;
  }
  
  .blog-preview p {
    margin: 5px 0;
    color: #666;
  }
  
  .blog-preview .preview-image {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    border-radius: 5px;
  }
  
  .toggle-preview-button {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
   margin-left: 50px;
  }
  
  .toggle-preview-button:hover {
    background-color: #0056b3;
  }
  

.publish-blog-container .blog-title{
    font-size: 25px;
    font-weight: 700;
    line-height: 28.8px;
    color: #272848;
    display: inline-block;
    padding:20px 10px 0 25px;
    margin-bottom: 20px;
}

.publish-blog-gap{
    display: flex;
    flex-wrap: wrap;
    gap:30px;
    justify-content:space-around;
    padding:10px 18px;
}

.publish-blog-header {
    position: relative;
    width:525px;
    height: 45px;
}

.publish-blog-input {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding:0 18px;
    border: 2px solid #2E37A41A;
    outline: none;
}

.publish-blog-placeholder {
    position: absolute;
    top: -0.8rem;
    left: 1rem;
    padding: 0 0.5rem;
    transition: all 200ms ease-in-out;
    font-weight: 500;
    pointer-events: none;
    background: #ffffff;
}
  
/* .publish-blog-placeholder.focused {
    font-size: 0.85rem;
    color: #999;
    background: #ffffff;
} */


.publish-blog-placeholder-status{
    position: absolute;
    top: -0.6rem;
    left: 5px;
    font-weight: 500;
}

.publish-blog-check-aina {
    display: flex;
    align-items:end;
    justify-content:flex-start;
    gap:20px;
    margin-top: 16px;
    margin-left: 5px;
}
.publish-blog-check-aina .radio-input-label {
    display: flex;
    gap:5px;
}

.publish-blog-check-aina .radio-input-label .radio-label{
    color: #33344880;
    font-size: 16px;
    font-weight: 500;
}

.editor-and-file-container{
    display: flex;
    flex-direction: column;
    gap:4rem;
    padding: 10px 3rem;
}

.editor-box{
    width:100%;
}
/*Editor*/
.editor-text-box{
    height: 8rem;
}

.publish-blog-header-file {
    position: relative;
    border-radius: 10px;
    padding:0 18px;
    border: 2px solid #2E37A41A;
    outline: none;
}

.publish-file-input {
    width: 100%;
    height: 2.5rem;
    opacity: 0;
    pointer-events: none;
}

.publish-file-name {
    position: absolute;
    left: 1rem;
    top: 0;
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
} 

.choose-file-publish{
    position: absolute;
    right: 0;
    width: 8rem;
    top: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #F7FAFF;
    border-radius:5px;
}
.choose-file-publish span{
    font-size: 14px;
    font-weight: 500;
    line-height: 21px; 
    color:#0167FF;    
}

.publish-button {
    width: 100%;
    height: 2.5rem;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    padding: 0px 48px;
    margin-top: 10px;
}
  
.publish-button-inside {
    position: relative;  /* Make the button a positioning context */
    display: flex;
      align-items: center;
      justify-content: center;
    border-radius: 5px;
    background: #2E37A4;
    color: white;
    padding: 8px 30px;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 130px;
    height:43px;
}

.publish-button-inside:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

.publish-savebutton-text{
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-wrap: nowrap;
}
  /* Spinner overlay */
.spinner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);  /* Optional: Semi-transparent background */
    width: 100%;
    height: 100%;
    border-radius: 5px;  /* Match button's border radius */
  }
  
  /* Small spinner style */
  .small-spinner {
    width: 24px;
    height: 24px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  
  
.ql-toolbar.ql-snow {
    border: 2px solid #2E37A41A;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.ql-container.ql-snow {
    border: 2px solid #2E37A41A; 
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

@media (max-width: 1280px) {
    .publish-blog-gap{
        display: flex;
        flex-wrap: wrap;
        gap:35px;
        justify-content:space-around;
        padding:10px 0;
    }
    .publish-blog-header {
        position: relative;
        width: 25rem;
        height: 45px;
    }

    .editor-and-file-container{
        display: flex;
        flex-direction: column;
        gap:4rem;
        padding: 10px 2.3rem;
    }

    .publish-button {
       margin-top: 10px;
       padding: 0px 35px;
    }
 
}

  