.toast-center .Toastify__close-button {
  color: #0067FF; 
}

.toast-center .Toastify__progress-bar {
  color:#0067FF !important;
}
.toast-center .Toastify__toast-icon {
  color:#0067FF; 
}



@keyframes upDown {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
@keyframes disappear {
  0%, 10%, 20%, 30%, 40%, 50%, 60%, 70%, 80%, 90%, 100% {
    opacity: 0.7;
  }
  5%, 15%, 25%, 35%, 45%, 55%, 65%, 75%, 85%, 95% {
    opacity: 0;
  }
}
.login-new-total-container{
  background: rgba(244, 247, 252, 1);
  margin:0 auto;
  height:fit-content;
  width: 100%;
}

.login-new--close-custom-container{
  display: flex;
  justify-content: end;
  padding: 30px 40px;
}

.login-new--close-custom {
  color: rgb(0, 0, 0);
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
}

.login-new--container{
  display: flex;
  justify-content: center;
  align-items: center;
}



.login-smile-emoji{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}

.login-brand-image-logo{
  width: 150px;
  height: 35px;
  position: absolute;
  top: 8%; /* 70px / 700px */
  left: 5%; /* 120px / 800px */
}  


.login-schedule-emoji {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 22%; /* 180px / 700px */
  left: 22.5%; /* 380px / 800px */
  animation: upDown 2s infinite, disappear 22s infinite;
}

.login-emoji-ring {
  width: 36px;
  height: 18px;
  position: absolute;
  top: 38.4%; /* 290px / 700px */
  left: 5%; /* 138px / 800px */
  font-size: 36px;
  font-weight: 900;
  line-height: 18px;
  text-align: right;
  animation: upDown 2s infinite, disappear 25s infinite;
}


.login-speech-bubble-container {
  background-image: url('../../assests/img/curvedesign.svg'); 
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center; 
  height: 120px;
  position: absolute;
  top: 45%; /* 350px / 700px */
  left: 10.25%; /* 138px / 800px */
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.login-speech-bubble {
  position: relative;
  padding: 5px 8px;
  color: #272848; 
  font-size: 16px; 
  font-weight: 500; 
}

.login-typing-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap; 
  animation: typing 4s steps(30, end) infinite;
}

.login-speech-bubble-container-std {
  background-image: url('../../assests/img/curvedesign.svg'); 
  background-repeat: no-repeat;
  background-size: cover; 
  background-position: center; 
  height: 120px;
  position: absolute;
  top: 14%; /* 150px / 700px */
  left: 76.75%; /* 1150px / 800px */
  display: flex; 
  justify-content: center; 
  align-items: center; 
}

.login-speech-bubble-std {
  position: relative;
  padding: 5px 8px;
  color: #272848; 
  font-size: 16px; 
  font-weight: 500; 
}

.login-typing-animation-std {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap; 
  animation: typing 4s steps(30, end) infinite;
}

.login-stethoscope-emoji {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 65%; /* 668px / 700px */
  left: 28.25%; /* 130px / 800px */
  animation: upDown 2s infinite, disappear 28s infinite;
}


.login-meds-emoji {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 75%; /* 668px / 700px */
  left: 4%; /* 130px / 800px */
  animation: upDown 2s infinite, disappear 28s infinite;
}

.login-firstaidkit-emoji {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 90%; /* 542px / 700px */
  left: 15.75%; /* -70px / 800px */
  animation: upDown 2s infinite, disappear 40s infinite; 
}

.login-handsucking{
  width: 40px;
  height: 40px;
  position: absolute;
  top: 38%; /* 350px / 700px */
  left: 67%; /* 1000px / 800px */
  font-size: 36px;
  font-weight: 900;
  line-height: 18px;
  animation: upDown 2s infinite, disappear 35s infinite; 
}

.doctorconsultation-emoji {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 48.3%; /* 450px / 700px */
  left: 87.5%; /* 1300px / 800px */
  animation: upDown 2s infinite, disappear 33s infinite;
}

.welocome-emoji{
  position: absolute;
  width: 98px;
  height: 150px;
  top: 60%; /* 550px / 700px */
  left: 68%; /* 1014px / 800px */
  animation: upDown 2s infinite, disappear 32s infinite;
}

.video-emoji{
  position: absolute;
  width: 40px;
  height: 40px;
  top: 84.9%;
  left: 83.75%;
  animation: upDown 2s infinite, disappear 30s infinite;
}

@keyframes typing {
  0% {
    width: 0; /* Start with no visible text */
  }
  50% {
    width: 100%; /* Fully typed out */
  }
  100% {
    width: 0; /* Reset to no visible text */
  }
}

.login-center-container{
  display: flex;
  flex-direction: column;
  gap:30px;
  margin-top:20%;
}


.login-center-container-head{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.login-center-container-head .login-header-login{
  font-size:26px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #272848;
  border-bottom: none;
  display: flex;
}

.login-center-container-head .login-header-sub-login{
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #272848;
  border-bottom: none;
}




/* form style .css */
 .form-overall-container-login{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap:10px;
}

.form-overall-container-login label {
  font-size: 16px;
  font-weight: 600;
  line-height: 14.4px;
  text-align: left;
  display: block;
  margin-bottom: 0.5rem;
  color: rgba(39, 40, 72, 1);
}

.form-overall-container-login  .form-control-custom {
  border-radius: 7px;
  padding:10px 20px;
  box-shadow: none;
  transition: border-color 0.3s ease;
  background-color: #ffffff;
  outline: none;
  width: 307px;
  border: 1px solid #272848;
}

.form-overall-container-login  .form-control-custom:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}


.form-overall-container-login  .btn-custom-first {
  width: 306px;
  height: 49px;
  border: 1px solid rgba(86, 140, 176, 1);
  border-radius:50px;
  background: #0067FF;
  padding: 0.5rem 2rem;
  color: #ffffff;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease, transform 0.3s ease;
}


.form-overall-container-login  .btn-custom-first:hover {
  border: 1px solid #0167ff;
  background-color: #edf3ff;
  color: #0167ff;
  transform: scale(1.05);
}





