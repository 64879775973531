.add-doctor-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.836);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 15px;
}
  
.add-doctor-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  height: 45vh; /* Occupy most of the viewport height */
  overflow-y: auto; /* Enable vertical scrolling */
  margin: 0 auto;
}
/* Scrollbar Styling */
.add-doctor-modal-content::-webkit-scrollbar {
  width: 8px;
}
.add-doctor-modal-content::-webkit-scrollbar-thumb {
  background-color: #ccc;
  border-radius: 4px;
}

.add-doctor-modal-content h2{
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  color: rgba(39, 40, 72, 1);
  margin-bottom: 20px; 
}
  
.add-doctor-modal-content .add-doctor-modal-label{
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: rgba(39, 40, 72, 1);
  margin-bottom: 10px;
}

.add-doctor-modal-content .add-doctor-modal-form-group{
 margin-bottom: 10px;
}
.add-doctor-modal-form-group input{
  width: 100%;
  height: 45px;
  color: #272848;
  border-radius: 8px;
  padding: 0 22px;
  border: 2px solid #2E37A41A;
  outline: none;
  margin-top: 5px;
}
/* Close Button */
.our-providers-close-btn {
  display: flex;
  justify-content: end;
  cursor: pointer;
}
.our-providers-close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  color: rgba(1, 103, 255, 1);
  font-size: 20px;
}
.our-providers-close-icon:hover {
  background: rgba(133, 133, 133, 1);
}
.our-providers-close-btn:hover .our-providers-close-icon {
  color: #ffffff;
}
.add-doctor-modal-form-group .add-doctor-modal-textarea-container{
  width: 100%;
  height:auto;
  border-radius: 8px;
  padding: 10px;
  background-color: #ffffff;
  border: 2px solid #2E37A41A;
  outline: none;
  display: flex;
  justify-content:center;
  align-items: center;
  margin-top: 5px;
}

.add-doctor-modal-textarea-container textarea{  
  font-size: 16px;
  font-weight: 400;
  color: #272848;
  display: inline-block;
  border: none;
  outline: none;
  width: 100%;
  padding:0px 10px;
  overflow-y: auto;
  border-radius: 5px;
  background-color: #ffffff;
  box-sizing: border-box;
}

.add-doctor-modal-textarea-container textarea::-webkit-scrollbar {
  width: 0px;
}

/* Save Changes Button */
.add-doctor-modal-save-button{
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  width: 150px;
  height: 40px;
  padding: 5px;
  border-radius: 5px;
  background-color:  #272848;
  font-size: 16px;
  font-weight: 400;
  line-height: 14.4px;
  color: white;
  outline: none;
  margin-top: 30px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.add-doctor-modal-save-button:hover {
  border: 0.4px solid #272848;
  background: none;
  color: #272848;
  transform: scale(1.05);
}
 
  
  