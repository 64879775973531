.Adim-MA-PTR-edit-profile-container{
    padding: 0px 20px;
}

.Adim-MA-PTR-edit-profile-container .Adim-MA-PTR-edit-profile-title{
    font-size: 25px;
    font-weight: 700;
    line-height: 28.8px;
    color: #272848;
    display: inline-block;
    padding:20px 10px 0 5px;
    margin-bottom: 100px;
}

.Adim-MA-PTR-edit-profile-form{
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   gap:40px;
}
.Adim-MA-PTR-form-sub-container{
    display: flex;
    justify-content: center;
    gap:40px;
    max-width: 1200px;
    width: 100%;

}
  
.Adim-MA-PTR-form-group {
    position: relative;
    width:100%;
    height:50px;
}

.Adim-MA-PTR-form-input {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0 18px;
    border: 2px solid #2E37A41A;
    outline: none;
}


.Adim-MA-PTR-form-input-placeholder{
    position: absolute;
    top: -0.8rem;
    left: 1rem;
    padding: 0 0.5rem;
    transition: all 200ms ease-in-out;
    font-weight: 500;
    background: #ffffff;
}

.Adim-MA-PTR-select-box-head{
    position: relative;
    display: inline-block;
}

.Adim-MA-PTR-select-box-head .Adim-MA-PTR-select-box-input {
    -webkit-appearance: none; /* For Chrome */
    -moz-appearance: none; /* For Firefox */
    appearance: none; /* For modern browsers */
    outline: none;
    cursor: pointer;

}

.Adim-MA-PTR-select-box-head  .Adim-MA-PTR-select-box-arrow-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    font-size: 20px;
    color: orange;
    pointer-events: none;
}

.Adim-MA-PTR-button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    max-width: 100%;
    width: 1200px;
    margin: 40px auto;
    padding: 0 10px;
}

.Adim-MA-PTR-update,
.Adim-MA-PTR-Back-to-Accounts,
.Adim-MA-PTR-Back-to-Transfer-Requests {
    width: auto;
    max-width: 100%;
    height: auto;
    padding: 10px 20px;
    font-size: 1rem; /* Adjusts with root font size */
    font-weight: 600;
    line-height: 1.5;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
}

.Adim-MA-PTR-update {
    background: #0167ff;
    color: #ffffff;
    border: none;
}

.Adim-MA-PTR-update:hover {
    background-color: #edf3ff;
    color: #0167ff;
    transform: scale(1.05);
}

.Adim-MA-PTR-update:active {
    background-color: #003f7f;
    transform: translateY(0);
}

.Adim-MA-PTR-Back-to-Accounts,
.Adim-MA-PTR-Back-to-Transfer-Requests {
    border: 1px solid #0167ff;
    background-color: transparent;
    color: #0167ff;
}

.Adim-MA-PTR-Back-to-Accounts:hover,
.Adim-MA-PTR-Back-to-Transfer-Requests:hover {
    background-color: #edf3ff;
    color: #0167ff;
    transform: scale(1.05);
}
/* Responsive Design */
@media (max-width: 999px) {
    .Adim-MA-PTR-edit-profile-container .Adim-MA-PTR-edit-profile-title{
        margin-bottom: 30px;
    }

    .Adim-MA-PTR-form-group,
    .Adim-MA-PTR-select-box-head {
        max-width: 100%;
        width: 100%;
    }

    .Adim-MA-PTR-form-sub-container {
        flex-direction: column;
        gap: 30px;
    }

    .Adim-MA-PTR-button-group {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        max-width: 100%;
        margin: 40px auto;
        padding: 0 10px;
    }
    
}


/* Media Queries for Smaller Screens */
@media (max-width: 768px) {
    .Adim-MA-PTR-button-group {
        flex-direction: column;
        align-items: stretch;
        gap: 15px;
        margin-top: 20px;
    }

    .Adim-MA-PTR-update,
    .Adim-MA-PTR-Back-to-Accounts,
    .Adim-MA-PTR-Back-to-Transfer-Requests {
        width: 100%;
        text-align: center;
        font-size: 0.9rem;
    }
}

@media (max-width: 480px) {
    .Adim-MA-PTR-button-group {
        padding: 0 5px;
    }

    .Adim-MA-PTR-update,
    .Adim-MA-PTR-Back-to-Accounts,
    .Adim-MA-PTR-Back-to-Transfer-Requests {
        padding: 8px 15px;
        font-size: 0.8rem;
    }
}

.Adim-MA-PTR-savebutton {
    position: relative;  /* Make the button a positioning context */
    border-radius: 5px;
    background: #0167ff;
    color: #ffffff;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: auto;
    max-width: 100%;
    height: auto;
    padding: 10px 20px;
    font-size: 1.5rem; /* Adjusts with root font size */
    font-weight: 600;
    line-height: 1.5;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  

  .Adim-MA-PTR-savebutton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .Adim-MA-PTR-savebutton-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  
  /* Spinner overlay */
  .Adim-MA-PTR-spinner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);  /* Optional: Semi-transparent background */
    width: 100%;
    height: 100%;
    border-radius: 5px;  /* Match button's border radius */
  }
  
  /* Small spinner style */
  .Adim-MA-PTR-small-spinner {
    width: 24px;
    height: 24px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  