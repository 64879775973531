.profile-content-background-color{
    background-color: #ffffff;
    width:100%;
    height: auto;
    padding: 0 10px;
}



  
 
  
