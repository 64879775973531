@import url('https://fonts.googleapis.com/css2?family=Matter:wght@400;500;600;700&display=swap');

.award-background{
    background-color: #FFFFFF;
    width: 880px;
    height: 182px;
    position: relative;
    left: 500px;
    top: -300px;
}
.award-section{
    /* background: linear-gradient(180deg, #EDF3FF 0%, #FFFFFF 100%); */
    position: relative;
    top:-50px

}

.award-area {
    padding: 30px;


}

.award-area h2 {
    position: relative;
    top: 40px;
    font-family: 'Matter', sans-serif;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: 25px !important;
    left: -300px;
    text-align: center;
    color: #272848;
        
}

.doctor-cards-awards{
    position: relative;
    display: flex;
    gap: 10px;


}

.card-award {
    position: relative;
    width: 261px;
    height: 82px;
    top: 50px;
    left: -20px;
    border-radius: 10px ;
    background: #FFFFFF;
    border: 1px solid #0000001A    

}





.award-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.award-image1{
    
    position: relative;
    z-index: 0;

}

.award-image2{
    position: absolute;
    z-index: 1;
    margin-left: -34px;
    margin-top: 15px;

}



.award-title {
    position: relative;
    width: 173px;
    height: 75px;
    top: -60px;
    left: 10px;
    font-family: 'Matter', sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    text-align: center;
    color: #272848;
        
}

.award-description {
    font-size: 14px;
    font-family: 'Matter', sans-serif;
    font-weight: 500;
    color: #272848;
    text-align: center;
    position: relative;
    top: -115px;
    left:-30px ;
}

.awardimg {
    position: relative;
    top: 0px;
    left: -100px;
}
@media (max-width: 412px) {
    .award-section{
 
        top:0px
    
    }
    .doctor-cards-awards{
        position: relative;
        display:contents;
        align-items: center;
        justify-content: center;
    }
    .card-award-container{
        width:100%;
        max-width: 80% ;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 8%;
    }
    .card-award {
        position: relative;
        width:100%;
        max-width: 100% ;
        height: 229px;
        justify-content: center;
   align-items: center;
left: 0;
        margin-top: 20px;
    }
    
    
.award-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    

}

.award-area h2 {
    width: 100%;
    text-align: center;
    font-size: 36px; 
    left: 0;
    position: absolute;
    line-height: 45px;

}


    
}
@media (min-width: 413px) and (max-width: 600px) {

    .doctor-cards-awards{
        position: relative;
        display:contents;
        align-items: center;
        justify-content: center;
    }
    .card-award {
        position: relative;
        width:100%;
        max-width: 204px ;
        height: 229px;
        justify-content: center;
   align-items: center;

        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .card-award-container{
        width:100%;
        max-width: 80% ;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 0%;
    }
.award-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.award-area h2 {
    left: -50px;
    font-size: 30px; 
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    left: 0;
}

}
@media (min-width:601px) and (max-width: 676px) {
    .award-section{

        top:-170px
    
    }
    .doctor-cards-awards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
    }
    .card-award {
        position: relative;
        width:100%;
        max-width: 204px ;
        height: 229px;
        justify-content: center;
   align-items: center;

        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .card-award-container{
        width:100%;
        max-width: 80% ;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 0%;
    }
.award-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.award-area h2 {
    left: -50px;
    font-size: 30px; 
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    left: 0;
}

}
@media (min-width:678px) and (max-width: 914px) {
    .award-section{

        top:-150px
    
    }
    .doctor-cards-awards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
    }
    .card-award {
        position: relative;
        width:100%;
        max-width: 204px ;
        height: 229px;
        justify-content: center;
   align-items: center;

        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .card-award-container{
        width:100%;
        max-width: 80% ;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 0%;
    }
.award-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.award-area h2 {
    left: -50px;
    font-size: 30px; 
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    left: 0;
}

}

@media (min-width:915px) and (max-width: 1023px) {
    .award-section{

        top:-400px
    
    }
    .doctor-cards-awards {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
        left: 0;
    }
    .card-award {
        position: relative;
        width:100%;
        max-width: 204px ;
        height: 229px;
        justify-content: center;
   align-items: center;

        margin-top: 20px;
        margin-right: auto;
        margin-left: auto;
    }
    
    .card-award-container{
        width:100%;
        max-width: 80% ;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 0%;
    }
.award-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.award-area h2 {
    left: -50px;
    font-size: 30px; 
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    justify-content: center;
    left: 0;
}

}

/* @media (min-width: 992px) and (max-width: 1199px) {
    .doctor-cards-awards{
        left: -11.5%;
    }
    .award-area h2 {
        left: 220px;   
    }
} */
@media (min-width: 2560px) {}

@media (min-width:1024px) and (max-width: 1439px) {
    .award-section{

        top:600px
    
    }

    .card-award {
        position: relative;
        width: 204px;
        height: 229px;
        top: 10px;
        left: 0px;
        margin-left: auto;
        margin-right: auto;
justify-content: center;
    
        border-radius: 10px ;
        background: #FFFFFF;
        border: 1px solid #0000001A    
        
    
    }
    .award-area h2 {
        position: relative;
        width: 408px;
        height: 25px;
        top: -60px;
        left: 0;
        font-family: 'Matter', sans-serif;
        font-size: 36px;
        font-weight: 700;
        line-height: 25px;
        text-align: center;
        color: #272848;
        margin-left: auto;
        margin-right: auto;
            
    }
    .doctor-cards-awards{
        position: relative;
        display: flex;
        gap: 20px;
        justify-content: center;
   
    
    }
    
}
@media (min-width:1441px) and (max-width: 4086px) {    .award-section{
  
        top:-100px
    
    }
    
}