.ManageAccounts-admin-page-container{
    padding: 0px 20px;
}

.ManageAccounts-admin-page-container .ManageAccounts-admin-page-title{
    font-size: 25px;
    font-weight: 700;
    line-height: 28.8px;
    color: #272848;
    display: inline-block;
    padding:20px 10px 0 5px;
    margin-bottom: 20px;
}
.ManageAccounts-admin-page-table-control{
    overflow-y: auto;
    height: calc(100vh - 180px);
    padding-bottom:10px;
}

.ManageAccounts-admin-page-table-control::-webkit-scrollbar {
    width: 0px;
}

.Ma-ap-table{
    width: 100%;
    border-collapse: collapse;
    text-align: center;
}

.Ma-ap-thead .Ma-ap-header{
    background: #272848;
    color: #FFFFFF;
    padding: 8px;
    font-size: 16px;
    font-weight: 700;
}



.Ma-ap-tbody .table-cell {
    color: #272848;
    font-size: 16px;
    font-weight: 500;
    padding: 18px 15px;
    border-bottom: 4px solid #f0f0f5;
}
  
.Ma-ap-view-button {
    background: none;
    color: #0163F7;
    border: 1.5px solid #0163F7;
    border-radius: 5px;
    padding: 5px 20px;
    font-weight: 600;
    transition: background-color 0.3s ease;
}
  
.Ma-ap-view-button:hover {
    background-color: #0163F7;
    color: #ffffff;
}

