.nutrition-tips-container {
  font-family: 'Matter' !important;
  max-width: 100% !important;
  padding: 0 !important;
  background-color: #fff;
}

/* @media screen and (min-device-width: 1435px) and (max-device-width: 2560px) { 
  .nutrition-container1 {
    height: 45vh !important;
  }
} */

.nutrition-tips-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.nutrition-tips-title {
  font-family: 'Matter';
  color: #181A2A;
  font-size: 26px;
  font-weight: 600;
}

.nutrition-tips-button {
background-color: #FF7F50;
  /* Optional: add any specific styles for the button here */
}

@media (max-width: 600px) {
  .nutrition-tips-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .similar-articles-section .articles {
    display: flex;
    flex-direction: column !important;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 0;
  }
  
  .similar-articles-section .articles .article-card {
    width: 100% !important;
   
    border-radius: 8px;
    padding: 15px;
   
    margin-bottom: 20px;
    text-align: left;
  }
  
  .similar-articles-section .articles .article-card .article-image {
    width: 100%;
    height:200px;
    object-fit: cover;
    border-radius: 8px;
  }
}
@media (min-width: 1000px) and (max-width : 1500px){
  .nutrition-tips-image-container {
    width: 100%;
    height: 60vh; /* You can adjust this value if needed */
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensures that the image doesn't overflow */
    border-radius: 20px;
    margin-left: 0px;
    margin-top: -130px !important;
  
  }
  

}
.nutrition-container1{
  padding-top: 40px;
  width: 100%;
  height: 51vh;
  background-color: #eef4ff;
}
.nutrition-container2{
  padding-bottom: 30px;

}
.nutrition-tips-subheader {
  color: #757575;
  font-size: 14px;
  margin-bottom: 10px !important;
}
.nutrition-tips-subheader .small-profile{
  border-radius: 50%;
  width: 35px;
  height: 35px;
}

.nutrition-tips-image-container {
  width: 100%;
  height: 60vh; /* You can adjust this value if needed */
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures that the image doesn't overflow */
  border-radius: 20px;
  margin-left: 0px;
  margin-top: -180px;

}

.nutrition-tips-image {
  width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the container while maintaining its aspect ratio */
}

.nutrition-tips-content {
  line-height: 1.6;
  margin-top: 6.5rem;
  margin-bottom: 2.5rem;
}

@media (max-width: 600px) {
  .nutrition-tips-content {
    font-size: 0.9rem;
  }
}

.nutrition-tips-content-heading {
  font-size: 20px;
  margin-bottom: 1rem;
}


.nutrition-tips-content-headings{
  font-size: 33px;
  margin-bottom: 1rem;
}

.nutrition-tips-content-paragraph {
  margin-bottom: 3rem;
text-align: justify;
color:  #718096;

}



/* About the Author Section */
.about-author-section {
  margin-bottom: 40px;
}

.author-cards{
  display: flex;
  align-items: center;
  padding: 20px;
 border: none; 
 
  
}


.author-image {
  width: 150px;
  height: 150px;
  border: orangered 2px solid;
  margin-right: 30px;
  margin-left: -20px;
  object-fit: cover;
  border-radius: 8px;
}

.author-info h3 {
  margin: 0;
  font-size: 1.5em;
}

.author-info p {
  margin: 4px 0;
  color: #666;
}
/* Leave Comments */

/*post-comment  Start*/

.UserBlog-leave-comment-cnt {
  display: flex;
  flex-direction: column;
  align-items:flex-start;
  justify-content: center;
  gap:10px;
  padding:1rem;
  border-radius:8px;
  box-shadow: 0 0 10px 0 #0000001A;
  background-color: #fff;
  /* display: inline; */
}

.UserBlog-textarea-comment-post {
  width: 100%;
  height: 10rem;
  position: relative;
  margin-top: 1rem;
  padding: 5px 0;
  border-radius: 0.5rem;
  border: 1px solid #e9e9e9;
}

.UserBlog-input-textarea-commit-post {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  padding: 1rem;
  overflow-y: scroll;
  outline: none;
  border: none;
  resize: none;
  color:#272848;
  font-size: 14px;
  font-weight: 500;
}

.UserBlog-input-textarea-commit-post::-webkit-scrollbar {
  width: 0px;
}

.UserBlog-input-placeholder-commit-post {
  position: absolute;
  top: 1rem;
  left: 1rem;
  padding: 0 0.5rem;
  transition: all 200ms ease-in-out;
  font-weight: 500;
  pointer-events: none;
}

.UserBlog-input-placeholder-commit-post.focused {
  top: -0.8rem;
  left: 1rem;
  font-size: 0.85rem;
  color: #999;
  background: #ffffff;
}

.UserBlog-comment-term-conformation-cnt {
  display: flex;
  align-items:center;
  gap: 0.5rem;
  margin-top: 1rem;
}

.UserBlog-comment-checkbox {
  width: 15px;
  height: 15px;
}

.UserBlog-checkbox-comment-txt {
  font-size: 14px;
  font-weight: 500;
  line-height: 19.5px;
  color: #333448;

}

.UserBlog-submit-button {
  margin-top: 1rem;
  padding: 0.2rem 0.8rem;
  border: none;
  background: none;
  border-radius:5px;
  color: #272848;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  border: 1.5px solid #0167FF;
}



.UserBlog-comment-top-input-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 10px;
  gap:1rem;
} 

.UserBlog-comments-title {
  color: #272848;
  font-size: 18px;
  font-weight: 600;
  margin: 0;
}
/*post-comment  End*/

/* Similar Articles Section */
.similar-articles-section h2 {
  margin: 20px 0px;
}

.similar-articles-section .articles {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 0;
}

.similar-articles-section .articles .article-card {
  width: 30%;
 
  border-radius: 8px;
  padding: 15px;
 
  margin-bottom: 20px;
  text-align: left;
}

.similar-articles-section .articles .article-card .article-image {
  width: 100%;
  height:200px;
  object-fit: cover;
  border-radius: 8px;
}

.article-info .category {
  font-size: 0.9em;
  font-weight: bold;
  color: #007bff;
  
}

.article-info{
  font-family: 'Matter';

  margin-top: 10px !important;
}

.article-info .date {
  font-size: 0.8em;
  color: #999;
  /* margin-bottom: 30px; */
}

.article-info .article-title {
  /* margin: 10px 0; */
  font-size: 1.1em;
  font-weight: 600;
  color: #1F2B6C;
}

.article-info p {
  color: #666;
  font-size: 0.9em;
  font-weight: 500;
  word-wrap: 200px;
  overflow-wrap: break-word;
}

.learn-more {
  display: inline-block;
  margin-top: 10px;
  padding: 0px 12px;
  
  color: #007bff;
  border-radius: 4px;
  text-decoration: none;
}



.blognew-view-all {
  border: none;
  outline: none;
  display: block;
  margin: 20px auto 0;
  padding: 5px 30px;  
  color: white;
  font-size: 14px;
  font-weight: 600;
  border-radius: 5px;
  background-color: #0167ff;
  text-align: center;
  line-height: 37px;
  transition: background 0.3s ease, transform 0.3s ease;
}

.blognew-view-all:hover {
  background-color: #edf3ff;
  border: 0.4px solid #0167ff;
  color: #0167ff;
  transform: scale(1.05);
}
.author-infos{
  font-size: 20px;
  color: #007bff;
}


.nutrition-tips-content-headingse{
  color: #272848;
  font-size: 36px;
  font-weight: 600;
  margin-bottom: 40px;
}

.article-info .categorysimg img{
  width: 60px;
  height: 60px;
  
  color: #BFD2F8 !important;
  background: #1F2B6C;
  border-radius: 50%;
  margin-left: 145px;
  margin-top: -70px;
padding: 20px;

}

.social-media-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 30px;

  /* flex-direction: space-; */
  align-items: center;
}

.social-icons {
  display: flex;
  gap: 10px;
}

.icon {
  font-size: 24px;
  color: #007bff;  /* You can adjust the color as needed */
  text-decoration: none;
}

.icon:hover {
  color: #007bff; /* Change to your preferred hover color */
}

.helpful-text {
  font-weight: 500;
  color: #000;
  text-wrap: nowrap;
  margin-top: 10px;
  /* margin-left: 50px; */
  font-size: 14px;
}
.helpful-text span{
  color: #007bff; /* Change to your preferred hover color */

}

.blogPage-loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 9999;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.blogPage-loading-spinner {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  border: 5px solid rgba(255, 255, 255, 0.2);
  border-top: 5px solid #ffffff;
}

