@import url('https://fonts.googleapis.com/css2?family=Matter:wght@400;500;600;700&display=swap');

.main-article {
    position: relative;
    /* background: linear-gradient(180deg, #EDF3FF 0%, #FFFFFF 100%); */
    top: -230px;
}

.background-article {
    padding: 70px;
background-color: #ffff;
width: 880px;
height: 697px;
position: relative;
left: 340px !important;
border-radius: 12px;
}

.articles-doctor {
    position: relative;
    font-family: 'Matter', sans-serif;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #272848;
    text-align: center;
    top:-30px;
    left: -380px;



}
.card-align{
    position: relative;
    display: flex;
    gap: 20px;
    left: -30px;
    overflow: hidden;

}
.card-article {
    width: 317px;
    height: 552px;

    border-radius: 5px ;

    opacity: 0.1px;
    border: 1px solid #1f2b6c18    
}

.card-article img.card-img-top {
    width: 317px;
    height: 300px;
}
.card-content{
padding: 20px;
}

.card-content img.cardlogo {
    width: 80px;
    height: 80px;
    margin-left: 200px;
    margin-top: -60px;
}

.overlay-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 317px;
    height: 300px;
    opacity: 0.7;
    z-index: 1;
}

.Telemedicine {
    font-family: 'Matter', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #0167FF;
    margin-top: -35px;


}

.date {
    position: relative;
    font-family: 'Matter', sans-serif;

    font-size: 14px;
    line-height: 12px;
    color: #717171;

font-size: 14px;
font-weight: 400;

text-align: left;

}

.maintitle {
    font-family: 'Matter', sans-serif;
    font-size: 26px;
    line-height: 31.2px;
    font-weight: 500;
    color: #1F2B6C;
}

.description {
    font-family: 'Matter', sans-serif;
    font-size: 16px;
    line-height: 22.4px;
    font-weight: 400;
    color: #212124;
}

.left-arrow {
    width: 40px;
    height: 40px;
}

.right-arrow {
    width: 40px;
    height: 40px;
}
.articles-navigation{
    position: relative;
    display: flex;
    margin-top: -23%;
  
}

.Article-next {
    margin-left: 110%;

    cursor: pointer;
}

.Article-previous {
    margin-left: -127%;

    cursor: pointer;
}

.Learnmore {
    font-family: 'Matter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 22.4px;
    color: #0067FF;

}

.hidden {
    visibility: hidden;
}

.Article-previous svg{
position: relative;
left: 90px;
}

.Article-next svg{
    position: relative;
    right: 90px;
    }
    .background-article  .row{
        margin-left: 35px;
    }

    @media(max-width:412px){
        .main-article {

            top: 10px;
        }
        
        .card-align {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 20px;
            left: 0; 
        }
        .card-article {
  width: 100%;
  max-width: 100%;
         
        }
        .overlay-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
    
        }
        .card-article img.card-img-top {
            width: 100%;
            max-width: 100%;
    
        }
        .background-article  {
            padding: 20px;
        
        }
        .next {
            margin-left: 90%;
            margin-top: 0%;
 
        }
        
        .previous {
            margin-left: -5%;
            margin-top: 0%;
   
        }
        .card-content img.cardlogo {
            width: 22.8%;
            height: 22.8%;
            margin-left: auto;
            margin-right: auto;
            left: 75%;
            position: relative;
         
        }
        
    }

    
    @media (min-width:413px) and (max-width: 600px) {
        .main-article {

            top: 10px;
        }
        
        .card-align {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 20px;
            left: 0; 
        }
        .card-article {
  width: 317px;
  margin-left: auto;
  margin-right: auto;

         
        }
        .overlay-img {
            position: absolute;
            top: 0;
            left: 0%;
            width: 317px;
            margin-left: auto;
            margin-right: auto;

    
        }
        .card-article img.card-img-top {
            width: 100%;
            max-width: 100%;
    
        }
        .background-article  {
            padding: 20px;
        
        }
        .next {
            margin-left: 90%;
            margin-top: 15%;
 
        }
        
        .previous {
            margin-left: -5%;
            margin-top: -14%;
   
        }
        .card-content img.cardlogo {
            width: 60px;
            height: 60px;
         
        }
        
    }
    @media (min-width:601px) and (max-width: 767px) {
                .main-article {

            top: -100px;
        }
        
        .card-align {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 20px;
            left: 0; 
        }
        .card-article {
         
  width: 100%;
  max-width: 70%;
  margin-left: auto;
         margin-right: auto;
        }
        .overlay-img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
    
        }
        .card-article img.card-img-top {
            width: 100%;
            max-width: 100%;
    
        }
        .background-article  {
            padding: 20px;
        
        }
        .next {
            margin-left: 90%;
            margin-top: 10%;
 
        }
        
        .previous {
            margin-left: -2%;
            margin-top: -9%;
   
        }
        .card-content img.cardlogo {
            width: 60px;
            height: 60px;
         
        }
    }
    @media (min-width:768px) and (max-width: 914px) {
        .main-article {

    top: 50px;
}

.card-align {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    left: 0; 
}
.card-article {
 
width: 317px;

margin-left: auto;
 margin-right: auto;
}
.overlay-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

}
.card-article img.card-img-top {
    width: 100%;
    max-width: 100%;

}
.background-article  {
    padding: 20px;

}
.next {
    margin-left: 90%;
    margin-top: 10%;

}

.previous {
    margin-left: -2%;
    margin-top: -9%;

}
.card-content img.cardlogo {
    width: 60px;
    height: 60px;
 
}
}
@media (min-width:915px) and (max-width: 1023px) {
    .main-article {

top: -260px;
}

.card-align {
position: relative;
display: flex;
flex-direction: column;
gap: 20px;
left: 0; 
}
.card-article {

width: 100%;
max-width: 50%;
margin-left: auto;
margin-right: auto;
}
.overlay-img {
position: absolute;
top: 0;
left: 0;
width: 100%;

}
.card-article img.card-img-top {
width: 100%;
max-width: 100%;

}
.background-article  {
padding: 20px;

}
.next {
margin-left: 90%;
margin-top: 10%;

}

.previous {
margin-left: -2%;
margin-top: -9%;

}
.card-content img.cardlogo {
width: 60px;
height: 60px;

}
}
    @media (min-width:1024px) and (max-width: 1439px) {
        .main-article {

            top: -280px;
        }
     
        .articles-navigation{
            position: relative;
            display: flex;
            margin-top: -23%;
          
        }
        
        .next {
            margin-left: 115.5%;
        
            cursor: pointer;
        }
        
        .previous {
            margin-left: -140%;
        
            cursor: pointer;
        }
  
        .card-align{
            position: relative;
            display: flex;
            gap: 20px;
            left: 0px;
            margin-left: auto;
            margin-right: auto;
            justify-content: center;
        }
        
    }

    @media (min-width:1441px) and (max-width: 4086px) {        .main-article {
            top: 510px;
        }
    }