.landingPage-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: hsla(0, 20%, 99%, 0.549) !important; 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.landingPage-modal-content {
  background-color: #fff !important;
  padding: 30px; 
  border-radius: 10px !important;
  height: 380px;
  max-width: 700px !important; 
  width: 100%; 
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1); 
  text-align: center;
  position: relative;
}

.landingPage-closebtn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 24px; 
  cursor: pointer;
}

.landingPage-modal-overlay h1 {
  font-size: 28px; 
  margin-bottom: 20px; 
  white-space: nowrap; 
}

.landingPage-modal-overlay p {
  font-size: 18px; 
  margin-bottom: 20px;
}
.landingPage-modal-overlay-overview-para{
  text-align: justify;
}

.landingPage-submitbtn {
  background-color: #007bff; 
  color: white;
  padding: 6px 20px; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 17px; 
  margin: 20px auto; 
  display: block; 
}


.landingPage-submitbtn:hover {
  background-color: #0056b3;
}

/* Responsiveness */
@media (max-width: 768px) {
  .landingPage-modal-content {
    padding: 15px;
    max-width: 90%; 
  }

  .landingPage-modal-overlay h1 {
    font-size: 20px; 
  }

  .landingPage-modal-overlay p {
    font-size: 14px; 
  }

  .landingPage-submitbtn {
    padding: 8px 16px; 
    font-size: 14px; 
  }
}

/* Ensure modal is above other content */
.landingPage-modal-overlay {
  z-index: 2000; 
}