.sidebar-menu.disabled .menu-link {
  pointer-events: none;
  opacity: 0.5;
}

.sidebar-menu.disabled .menu-link:hover {
  cursor: not-allowed;
}

.verify-popup-doctor h1 {
  font-size: 28px; 
  margin-bottom: 20px; 
  white-space: nowrap; 
}

.verify-popup-doctor p {
  font-size: 18px; 
  margin-bottom: 20px;
}

.submitbtn {
  background-color: #007bff; 
  color: white;
  padding: 6px 12px; 
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 17px; 
  margin: 20px auto; 
  display: block; 
}

  .submitbtn:hover {
    background-color: #0056b3;
  }
  .submitbtn-trial {
    background-color: #007bff; 
    color: white;
    padding: 6px 12px; 
    border: none;
    border-radius: 10px;
    cursor: pointer;
    font-size: 17px; 
    margin: 20px auto; 
    display: block; 
    margin-left: -80px;
  }
  
    .submitbtn-trial:hover {
      background-color: #0056b3;
    }



.sidebar {
  z-index: 1000;
  width: 270px;
  height: 100vh;
  background-color: #F7FAFF;
  border-left: 30px solid #F7FAFF;
  transition: width 0.3s;
  overflow: hidden;
}

.sidebar.closed {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 60px;
}

.logo-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 45px 10px 0px 10px;
}

.logo {
  display: inline-block;
  height: 40px;
}

.toggle-button {
  display: none;
  background: none;
  border: none;
  color: #000000;
  font-size: 20px;
  cursor: pointer;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin-top: 15px;
  flex-direction: column;
  display: flex;
  align-items: start;
  justify-content: space-evenly;
  height: calc(100vh - 180px);
}

.menu-item {
  display: flex;
  align-items: start;
  width: 100%;
  margin: -50px 0px -10px 0px;
  padding: 16px 0px 16px 20px;
  color: #A0AAC8;
}

.menu-item .menu-link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 400;
  font-size: 16px;
  color: inherit;
  width: 100%;
}

.sidebar-icon {
  display: flex;
  align-items: center;
  margin-right: 20px;
  font-size: 16px;
}

/* Curve design */
.menu-item:hover,
.menu-item.active {
  position: relative;
  color: #42427D;
  background-color: #FFFFFF;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.menu-item:hover:before,
.menu-item.active:before {
  position: absolute;
  content: '';
  height: 40px;
  width: 40px;
  background-color: #F7FAFF;
  right: 0;
  bottom: 100%;
  border-bottom-right-radius: 20px;
  box-shadow: 0 17px 0 #FFFFFF;
}

.menu-item:hover:after,
.menu-item.active:after {
  position: absolute;
  content: '';
  height: 40px;
  width: 40px;
  background-color: #F7FAFF;
  right: 0;
  top: 100%;
  border-top-right-radius: 20px;
  box-shadow: 0 -17px 0 #FFFFFF;
}

.menu-item span {
  flex-grow: 1;
}

.sidebar.closed .menu-item span {
  display: none;
}

.sidebar.closed .sidebar-icon {
  margin-right: 0;
}

.sidebar.open .close-icon {
  display: none;
}

.sidebar.closed .close-icon {
  display: block;
}

@media (max-width: 1024px) {
  .sidebar {
    width: 250px;
  }

  .sidebar.closed {
    width: 60px;
  }

  .logo-container {
    margin: 38px 10px 0px 10px;
  }

  .menu-item {
    margin: -40px 0px -10px 0px;
    padding: 12px 0px 12px 15px;
  }
}

@media (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    border-left: 15px solid #F7FAFF;
  }

  .sidebar.open {
    width: 250px;
  }

  .toggle-button {
    display: block;
  }

  .sidebar-menu .menu-item span {
    display: none;
  }

  .sidebar.open .sidebar-menu .menu-item span {
    display: inline;
  }

  .sidebar-menu {
    margin-top: 10px;
  }

  .menu-item {
    padding: 15px 20px;
  }

  .menu-item:hover,
  .menu-item.active {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }

  .menu-item:hover:before,
  .menu-item.active:before {
    height: 20px;
    width: 20px;
    border-bottom-right-radius: 15px;
    box-shadow: 0 9px 0 #FFFFFF;
  }

  .menu-item:hover:after,
  .menu-item.active:after {
    height: 20px;
    width: 20px;
    border-top-right-radius: 15px;
    box-shadow: 0 -9px 0 #FFFFFF;
  }
}