/* Overlay Animation */
.SubmissionPopup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  opacity: 0; /* Start with overlay hidden */
  animation: fadeIn 0.4s ease-out forwards; /* Fade in animation */
}

/* Popup Content Animation */
.SubmissionPopup-content {
  background: white;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  width: 300px;
  transform: translateY(-30px); /* Start from above */
  opacity: 0; /* Start with content hidden */
  animation: slideUp 0.4s ease-out forwards 0.2s; /* Slide up animation */
}

.SubmissionPopup-content.success {
  border: 2px solid #007bff;
}

.SubmissionPopup-content.error {
  border: 2px solid #FF7F50;
}

.SubmissionPopup-btn {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.SubmissionPopup-btn:hover {
  background-color: #007bff;
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideUp {
  0% {
    transform: translateY(-30px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

