.main-dashboard-page {
  padding: 0 20px;
}

.main-dashboard-page h1 {
  font-size: 25px;
  font-weight: 700;
  line-height: 28.8px;
  color: #272848;
  display: inline-block;
  padding: 20px 10px 0 5px;
  margin-bottom: 20px;
}

.dashboard-scoll-head {
  height:calc(100vh - 170px);
  overflow-y: auto;
}

.dashboard-scoll-head::-webkit-scrollbar {
  width:0px;
}

/*Scroll display none */
/* .dashboard-scoll-head::-webkit-scrollbar-thumb {
  background: #8f2121;
  border-radius: 10px;
} */



.dashboard-gird-layout{
  display: grid;
  grid-template-areas: 
    "insight-patient income-head profiles-head"
    "consultation booking-rate booking-rate"
    "reviews booking-rate booking-rate"
    "schedule schedule schedule";
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 330px 170px  auto;
  grid-gap: 20px;
  margin: 5px 5px 5px 5px;
}

.insight-patient {
  grid-area: insight-patient;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #0000001A;
  height: 100%;
}

.income-head {
  grid-area: income-head;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #0000001A;
  height: 100%;
}

.profiles-head {
  grid-area: profiles-head;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px #0000001A;
  height: 100%;
}

.consultation {
  grid-area: consultation;
  background: #5577D1;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px #0000001A;
  height: 100%;
}

.booking-rate {
  grid-area: booking-rate;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #0000001A;
  height: 100%;
}

.reviews {
  grid-area: reviews;
  background: #2A3F74;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 0 10px #0000001A;
  height: 100%;
}

.schedule {
  grid-area: schedule;
  background: #FFFFFF;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px #0000001A;
  height: 100%;
}
/* Desktop view: 1280px and above */

@media (max-width: 1440px)and (min-width: 1310px) {
  .dashboard-gird-layout {
    grid-template-areas: 
      "insight-patient income-head profiles-head"
      "consultation booking-rate booking-rate"
      "reviews booking-rate booking-rate"
      "schedule schedule schedule";
    grid-template-columns: repeat(1fr,1fr,1fr);
    gap: 20px;
  }
  .insight-patient, .income-head, .profiles-head{
    max-width: 1200px;
  }
  .consultation, .reviews{
    width: 100%;
  }
  .booking-rate{
    width:100%;
  } 
  .schedule{
    width: 100%;
  }
  .chart-area {
    width:300px;
    height: 200px;
  }

}

@media (max-width: 1280px) {
  .dashboard-gird-layout {
    grid-template-areas: 
      "insight-patient income-head profiles-head"
      "consultation booking-rate booking-rate"
      "reviews booking-rate booking-rate"
      "schedule schedule schedule";
    grid-template-columns: repeat(1fr,1fr,1fr);
    grid-template-rows: 330px 170px auto;
    gap: 20px;
  }
  .insight-patient, .income-head, .profiles-head{
    width: 300px;
  }
  .consultation, .reviews{
    width: 300px;
  }
  .booking-rate{
    width: 620px;
  } 
  .schedule{
    width: 940px;
  }

}




/*======================insight-patient =========start======================*/
.insight-patient .dashboard-head-common{
  display: flex;
  justify-content: space-between;
  align-items:start;
}

.insight-patient .dashboard-head-common p{
  font-size: 22px;
  font-weight: 600;
  line-height: 26.4px;
  color: #272848;
}

.insight-patient .dashboard-head-common .select-container .select-box-common {
  padding: 5px 50px 5px 10px;
  background: #f7faff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 600;
  font-size: 12px;
  color: #272848;
  cursor: pointer;
  -webkit-appearance: none; /* For Chrome */
  -moz-appearance: none; /* For Firefox */
  appearance: none; /* For modern browsers */
  outline: none;
}

.insight-patient .insight-item {
  background: #F7FAFF;
  display: flex;
  align-items: center;
  gap: 20px;
  margin:10px 0px 20px 0px;
  padding:5px;
}

.insight-item .insight-img-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left:10px;
}

.insight-item .insight-img-container.blue-color {
  background-color: #007bff;
}

.insight-item .insight-img-container.dark-blue-color {
  background-color: #2A3F74;
}

.insight-item .insight-img-container.green-color {
  background-color: #4caf50;
}
.insight-item .insight-img-container .image-insight{
  width: 30px;
  height: 30px;
  object-fit:cover; 
}

.insight-info h4 {
  color: #A0AAC8;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  margin: 0;
}

.insight-info p {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  font-weight: 600;
  margin: 0;
}
/*======================insight-patient =========End======================*/

/*======================your income =========start======================*/
.income {
  position: relative;
}

.income .dashboard-head-common {
  display: flex;
  justify-content: space-between;
  align-items: start;
}

.income .dashboard-head-common p {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.4px;
  color: #272848;
}

.income .dashboard-head-common .select-container .select-box-common {
  padding: 5px 50px 5px 10px;
  background: #f7faff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  color: #272848;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
}

.chart-area {
  max-width: 340px;
  height: 200px;
  margin-top: 40px;
  position: relative;
  right: 15px;
}

.coming-soon-overlay {
  position: absolute;
  top: -20px;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: #272848;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.8);
}  

/*====================== your income end =========End======================*/

/*====================== dashboard-profile =========start======================*/
.profiles-head .profiles-imgage{
  width: 80px;
  height: 80px;
  border-radius:50%;
}

.profiles-head .name-head {
  color: #272848;
  font-size: 20px;
  font-weight: 600;
  line-height:30px;
}

.profiles-head .subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height:10px;
  color: #A0AAC8;
}

.profiles-head .dashboardprofile-logo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 16px;
}

.dashboardprofile-logo .content-background {
  background: #F7FAFF;
  padding: 8px;
  border-radius:5px; 
  width: 100px;
  height: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.content-background  .logo-background {
  background: linear-gradient(180deg, #004EC4 0%, #0167FF 100%);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-background  .logo-background  .profiles-icons {
  color: #FFFFFF;
}

.dashboardprofile-logo .content-background .logo-name {
  font-size: 12px;
  font-weight: 600;
  margin: 0; 
  color: #272848;

}

.dashboardprofile-logo .content-background .logo-count {
  font-size: 12px;
  font-weight: 400;
  color: #A0AAC8;
  margin: 0;
}

.profiles-head .button-profiles{
  margin: 15px auto;
}
.profiles-head .button-profiles button{
  border: 1.5px solid #0163F7;
  border-radius: 2px;
  background: none;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #0163F7;
  width: 150px;
  height: 38px;
}
/*====================== profile end =========End======================*/

/*====================== consultation =========start======================*/
.consultation .consultation-coverarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.consultation-coverarea .consultation-info .consultation-count {
  font-size: 62px;
  font-weight: 600;
  line-height: 90px;
  color: #FFFFFF;
  margin: 0;
}

.consultation-coverarea .consultation-info .consultation-label {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #FFFFFF;
  text-align: left;
  margin-top:-10px;
}

.consultation-coverarea .consultation-img {
  width: 165px;
  height: 150px;
} 

/* @media (max-width: 768px) {
  .consultation-img {
    margin-bottom: -23px;
    width: 170px;
    height: 150px;
    margin-left: 20px;
  }
} */
/*====================== consultation end =========End======================*/

/*====================== Review =========start======================*/
.reviews .reviews-coverarea {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reviews-coverarea .reviews-info .reviews-count {
  font-size: 62px;
  font-weight: 600;
  line-height: 90px;
  color: #FFFFFF;
  margin: 0;
}

.reviews-coverarea .reviews-info .reviews-label {
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  color: #FFFFFF;
  text-align: left;
  margin-top:-10px;
}

.reviews-coverarea .reviews-img {
  width: 140px;
  height: 130px;
}
/*====================== review end =========End======================*/

/*====================== booking rate =========start======================*/
.booking-rate .booking-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.booking-header .booking-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.4px;
  color: #272848;
}

.booking-header .recently {
  padding: 5px 50px 5px 10px;
  background: #f7faff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  color: #272848;
  cursor: pointer;
  -webkit-appearance: none; /* For Chrome */
  -moz-appearance: none; /* For Firefox */
  appearance: none; /* For modern browsers */
  outline: none;
}

.booking-area {
  margin: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.rate {
  flex: 1 1 250px;
}

.rate h1 {
  font-size: 50px;
  font-weight: 600;
  line-height: 30px;
  text-align: left;
  color: #00A500;
}

.booking-description {
  font-size: 24px;
  font-weight: 500;
  line-height: 28.8px;
  text-align: left;
  color: #272848;
}

.increase {
  font-size: 14px;
  font-weight: 500;
  width: 60%;
  line-height: 16.8px;
  text-align: left;
  color: #A0AAC8;
}

.chart {
  flex: 2 1 350px;
  max-width: 600px;
  padding-top: 50px;
}

@media (max-width: 768px) {

  .rate {
    flex: 1 1 100px;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .rate h1 {
    font-size: 2rem;
  }

  .rate .booking-description{
    font-size: 1rem;
    line-height:20px;
  }
  .rate .increase{
    font-size: 12px;
    line-height:15px;
  }

  .chart {
    flex: 2 1 250px;
    max-width:300px;
  }
}


/*====================== booking rate =========End======================*/

/*====================== schedule =========start ======================*/

.schedule .schedule-header {
  display: flex;
  justify-content: space-between;
}

.schedule-header h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 26.4px;
  color: #272848;
}

.select-container {
  position: relative;
  display: inline-block;
}

.schedule-header .recently {
  padding: 5px 50px 5px 10px;
  background: #f7faff;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-weight: 500;
  font-size: 12px;
  color: #272848;
  cursor: pointer;
  -webkit-appearance: none; /* For Chrome */
  -moz-appearance: none; /* For Firefox */
  appearance: none; /* For modern browsers */
  outline: none;
}

.arrow-icon-filter {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #ff7f50;
  pointer-events: none;
}

.schedule-container .calendar {
  display: flex;
  justify-content: space-between;
  background: #f7faff;
  margin: 10px 0;
}

.schedule-container .calendar button {
  border: none;
  outline: none;
  background: none;
}

.schedule-container .calendar button .icon-arrow-calendar {
  color: #272848;
}

.schedule-container .calendar .day {
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  flex: 1;
  padding: 4px;
  cursor: pointer;
}

.schedule-container .calendar .day span {
  display: block;
  font-size: 16px;
  font-weight: 500;
  line-height: 27px;
  color: #a0aac8;
}

.schedule-container .calendar .day.selected {
  background: linear-gradient(180deg, #004ec4 0%, #0167ff 100%);
  color: #fff;
}

.schedule-container .schedule-list {
  max-height: 260px; /* Adjust this height to show 3 items */
  overflow-y: auto;
  
}

.schedule-container .schedule-list::-webkit-scrollbar {
  width:5px;
}

.schedule-container .schedule-list::-webkit-scrollbar-thumb {
  background: #5577d1;
  opacity: 90%;
  border-radius: 10px;
}

.schedule-list .schedule-item {
  display: flex;
  align-items: center;
  padding: 10px;
}

.schedule-list .schedule-item:last-child {
  border-bottom: none;
}

.schedule-list .schedule-item .time {
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  width: 200px;
}

.schedule-list .schedule-item .details {
  flex: 3;
  background: #f7faff;
  border-radius: 8px;
  border-left: 10px solid #2a3f74;
}

.schedule-list .schedule-item .details .details-section {
  display: flex;
  align-items: center;
  padding-left: 20px;
  gap: 10px;
}

.schedule-list .schedule-item .details .details-section .schedule-image-container {
  background: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  position: relative;
  top: 0;
  left: 0;
}

.schedule-list .schedule-item .details .details-section .schedule-image-container .scheduleimg {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 7px;
  left: 5px;
}

.schedule-list .schedule-item .details .details-section .schedule-title {
  font-size: 17px;
  font-weight: 600;
  line-height: 25.5px;
  text-align: left;
  margin-left: 5px;
  margin-top: 5px;
}

.schedule-list .schedule-item .details .details-section .sub-details {
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  color: #a0aac8;
  margin-left: 10px;
}


/*====================== schedule end =========End======================*/