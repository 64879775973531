.color-back-condition {
  background: linear-gradient(180deg, #edf3ff 0%, #ffffff 100%);
}

.condition-lib-head1 {
  font-size: 36px;
  font-weight: 600;
  padding-bottom: 10px;
}
.condition-lib-head1 span {
  color: #0067ff;
}

.condition-lib-info{
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #52525b;
}

.popular-conditions-heading {
  display: inline-block;
  font-size: 2rem;
  font-weight: 600;
  color: #000;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.popular-conditions-heading::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: #FF7F50;
  border-radius: 10px;
}
.popular-condition-name {
  cursor: pointer;
  font-weight: 600;
}

.popular-condition-name:hover {
  color: #0067ff;
}

.allConditionsConditionLibraries {
  width: 100%;
  display: inline-block;
  font-size: 2rem;
  font-weight: 600;
  color: #000;
  position: relative;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.allConditionsConditionLibraries::after {
  content: "";
  position: absolute;
  width: 100%; 
  height: 4px; 
  background-color: #ff7f50; 
  bottom: 0;
  left: 0;
  border-radius: 10px;
}
.allConditionsLib ul li {
  font-weight: 600;
  font-size: 1.2rem;
  cursor: pointer;
  margin-bottom: 20px;
}

.allConditionsLib ul li:hover {
  color: #0067ff;
}

.FindConditionsConLib {
  padding: 20px 40px;
  background-color: #deebff;
}

.conditions-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-items: center;
}

.condition-card {
  text-align: center;
}

.condition-card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.condition-card p {
  margin-top: 10px;
  font-size: 1.2rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .popular-conditions-heading {
    font-size: 1.7rem;
  }
  .popular-condition-name {
    font-weight: 600;
    font-size: 1.5rem;
  }
  .allConditionsLib ul li {
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .FindConditionsConLib {
    padding: 10px;
    background-color: #deebff;
  }
}
