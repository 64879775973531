.resetButtonContainer {
    display: flex !important;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 20px 40px 0px 0px;
}

.resetButton {
    border: none;
    background: none;
    font-size: 24px;
    font-weight: 900;
    line-height: 18px;
    text-align: right;
    color: #272848;
}

.mapHead {
    padding: 0px 0px 0px 30px;
    margin-top: 108px;
}

.mapExpanded-mapHead {
    padding: 0px 0px 50px 7px;
    /* margin-top: 25px; */
}

.mapContainer {
    width: 310px;
    height: 100vh;
    border-radius: 15px;
    /* border: 1px solid #e6e6e6; */
    position: relative;
    overflow: hidden;
    transition: width 0.5s, height 0.5s, background-color 0.5s;
}

.mapContainer.expanded {
    border-radius: 15px ;
    width: 550px;
    height: 100vh;
    margin-top: 20px;
    padding: 10px;
}

.mapFrame {
    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: none;
}
.mapFrame.expanded{
    border-radius: 20px;

}

.searchButtonContainer {
    position: absolute;
    top: 50%; /* Adjust positioning */
    right: 25%; /* Adjust positioning */
}

.searchButton {
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    padding: 10px 20px;
    border-radius: 7px;
    border: 1px solid #14bef0;
    display: flex;
    align-items: center;
}

.searchButton svg {
    margin-left: 5px;
}

.searchInputContainer {
    background-color: #fff !important;
    position: absolute;
    bottom: 80%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    display: flex;
    width: 280px;
    height: 56px;
    border-radius: 7px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.searchInput {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    border: none;
    background: none;
}

.map-container-edit {
    display: flex;
    flex-direction: column;
    position: relative;
}

.map-sort-by {
    display: flex;
    justify-content: end;
    width: 100%;
    text-wrap: nowrap;
    /* padding: 10px 40px; */
    /* margin-left: -30px; */
    margin-bottom: 20px;
    box-sizing: border-box;
}

.map-form-group {
    display: flex;
    align-items: center;
}

.map-form-group label {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    color: #272848;
}

.map-form-group select {
    border: 1px solid #78798d;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 100px 8px 8px;
    border-radius: 4px;
    appearance: none;
    outline: none;
    color: #272848;
    background: transparent;
}

.map-arrow-icon-filter {
    position: relative;
    right: 20px;
    pointer-events: none;
    color: #FF7F50;
}

.location-denied-prompt {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
}

.location-denied-prompt p {
    margin-bottom: 15px;
    font-size: 16px;
}

.location-denied-prompt button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.location-denied-prompt button:hover {
    background-color: #0056b3;
}

.gm-ui-hover-effect {
    display: none !important;
}

/* Responsive Design */
/* @media (max-width: 1200px) {
    .mapContainer {
        max-width: 300px;
        height: 550px;
    }
} */

@media (max-width: 992px) {
    .mapContainer {
        max-width: 450px;
        height: 500px;
    }
}

@media (max-width: 768px) {
    .mapContainer {
        max-width: 400px;
        height: 450px;
    }
}

@media (max-width: 576px) {
    .mapContainer {
        max-width: 350px;
        height: 400px;
    }
}

@media (max-width: 480px) {
    .mapContainer {
        max-width: 300px;
        height: 350px;
    }
}
