.admin-edit-patient {
    padding: 0px 20px;
}

.admin-edit-patient h2 {
    font-size: 25px;
    font-weight: 700;
    line-height: 28.8px;
    color: #272848;
    display: inline-block;
    padding: 20px 10px 0 5px;
    margin-bottom: 50px;
}

.admin-edit-patient-container {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    justify-content: space-between;
    padding: 10px 18px;
}

.admin-edit-patient-header {
    position: relative;
    width:500px;
    max-width:100%;
    height:50px;
}

.admin-edit-patient-input {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0 18px;
    border: 2px solid #2E37A41A;
    outline: none;
}

.admin-edit-patient-input-placeholder{
    position: absolute;
    top: -0.8rem;
    left: 1rem;
    padding: 0 0.5rem;
    transition: all 200ms ease-in-out;
    font-weight: 500;
    background: #ffffff;
}

/*emergency-container*/

.emergency-container {
    max-height: auto;
    overflow-y: auto;
}

.emergency-container::-webkit-scrollbar {
    width: 0px;
}

.emergency-label {
    padding: 0 1.5rem;
    transition: all 200ms ease-in-out;
    font-weight: 500;
    margin-bottom: 10px;
}

.admin-edit-patient-add-icon {
    color: #218838;
    font-size: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.admin-edit-patient-contact-row{
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    padding: 0 18px;
    border: 2px solid #2E37A41A;
    outline: none;
}

.admin-edit-patient-contact-input{
    width: 100%;
    border: none;
    outline: none;
}

.admin-edit-patient-remove-button {
    border: none;
    background-color: transparent;
    color: #ff4d4f;
    cursor: pointer;
}

/*image file*/

.admin-edit-patient-file {
    position: relative;
    border-radius: 10px;
    border: 2px solid #2E37A41A;
    outline: none;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    padding: 0 18px;
}

.admin-edit-patient-file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
}

.admin-edit-patient-file-name{
    margin-top: 18px;
    font-size: 15px;
    font-weight: 400;
    color: #333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.admin-edit-patient-choose-file{
    position: absolute;
    right: 0;
    width: 8rem;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: #F7FAFF;
    border-radius: 0 10px 10px 0;
    border-left: 1px solid #2E37A41A;
}

.admin-edit-patient-choose-file span {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: #0167FF;
}

.admin-edit-patient-button-header {
    display: flex;
    justify-content: end;
    width: 100%;
    gap: 20px;
}

.admin-edit-patient-button-header .submit-button{
    width: 100%;
    max-width: 150px;
    height: 50px;
    background-color:#272848;
    color: white;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.admin-edit-patient-button-header .cancel-button{
    background-color: aliceblue;
    padding: 5px 40px;
    height: 50px;
    color:#272848;
    font-weight: 500;
    border: none;
    border-radius: 10px;
    display: flex;
    place-items:center;
    text-decoration: none;
}

@media (max-width: 1440px) {
    
    .admin-edit-patient-header {
      width: 400px; 
    }
  
    .admin-edit-patient h2 {
      font-size: 22px; 
      margin-bottom: 20px; 
    }
     
    .admin-edit-patient-container {
        gap: 20px;
    }

    .admin-edit-patient-input {
      padding: 0 15px; 
    }
}

@media (max-width: 1200px) {
    
    .admin-edit-patient-header {
      width: 315px;
    }
    .admin-edit-patient-input {
      padding: 0 15px;
    }
}
  
@media (max-width: 998px) {
    
    .admin-edit-patient h2 {
        font-size: 20px; 
    }

    .admin-edit-patient-container {
      flex-direction: column; 
      gap: 20px;
      padding: 10px; 
    }
  
    .admin-edit-patient-header {
      width: 100%;
    }
  
    .admin-edit-patient-input {
      padding: 0 12px; 
    }
  
    /* .admin-edit-patient-button-header {
      flex-direction:row; 
      gap: 10px;
    }
  
    .admin-edit-patient-button-header .submit-button, 
    .admin-edit-patient-button-header .cancel-button {
      max-width: 100%; 
      height: 45px; 
    } */
  
    .admin-edit-patient-file {
      padding: 0 12px; 
    }
  
    .admin-edit-patient-choose-file {
      width: 7rem; 
    }
}





