/* General Styles */
.subscription-background {
    background-color: white;
    padding: 70px;
    display: flex;
    justify-content: center;
}

.subscription-plans {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 20px;
}

.title {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 10px;
    color: #272848;
    padding-top: 10px;
}

.subtitle {
    font-size: 1.2rem;
    margin-bottom: 20px;
    color: #1F2937;
}

.toggle-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    background-color: #F8F8F8;
    padding: 5px;
    border-radius: 18px;
    width: 270px;
    height: 50px;
    margin-left: auto;
    margin-right: auto;
}

.toggle-btn {
    padding: 5px 35px;
    margin: 0 5px;
    border: 2px solid #007bff;
    background-color: transparent;
    color: #007bff;
    cursor: pointer;
    border-radius: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;

}

.toggle-btn.selected {
    background-color: #007bff;
    color: white;
}

.toggle-btn:hover {
    background-color: #0056b3;
    color: white;
    transform: scale(1.05);
}

/* Discount Banner */
.discount-banner {
    display: flex;
    justify-content: center;
    background-color: #ff7a59;
    width: 300px;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease, opacity 0.3s ease;
    animation: bannerAnimation 2s infinite;
}

/* Enterprise Notice */
.enterprise-notice {
    font-size: 18px;
    font-weight: 500;
    /* margin-bottom: 20px; */
    color: #1F2937;
}

/* Plans Table */
.plans-table {
    width: 100%;
    margin-top: -20px;
    border-collapse: collapse;
}

.plans-table th,
.plans-table td {
    padding: 15px;
    text-align: center;
    border: 1px solid #DDE9FF;
}

.plans-table thead th {
    padding: 30px 15px !important;
    background-color: white;
    font-weight: bold;
}

.plan-header {
    background-color: white;
    font-size: 1rem;
    font-weight: bold;
    position: relative;
}

/* Overlay Effect for Selected Column */
.plan-column-selected {
    position: relative;
    background-color: white;
}

.plan-column-selected::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-left: 3px solid #c5cbd3;
    border-right: 3px solid #c5cbd3;
    pointer-events: none;
}

/* border for bottom when selected */

.plans-table td.feature-1-free-article.plan-column-selected {
    border-bottom: 3px solid #c5cbd3; 
  }

  /* border for top when selected */

  .plan-header.plan-column-selected {
    border-top: 3px solid #c5cbd3; 
border-top-left-radius: 4px !important;
}


/* Adjusting Features Column */
.features-column {
    margin-bottom: 10px !important;
}

.feature-space {
    padding: 30px 10px;
    position: relative;
    background: #F7FAFF !important;
    left: -13px;
    border-radius: 8px !important;
}

.plan-name {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: hsl(215, 28%, 17%);
text-align: center;}

.plan-price {
    font-size: 1.3rem;
    margin-bottom: 10px;
    color: #1F2937;
text-align: center;}

/* Buttons */
.add-contact-btn {
    background-color: transparent;
    color: #007bff;
    padding: 5px 5px;
    border: 2px solid #007bff;
    border-radius: 5px;
    cursor: pointer;
    width: 150px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
}

.add-contact-btn.active {
    background-color: #007bff;
    color: white;
}

.add-contact-btn:hover {
    background-color: #0056b3;
    color: white;
    transform: scale(1.05);
}

/* Feature Titles */
.feature-title {
    /* max-width: 200px; */
    font-size: 16px;
    font-weight: 600;
    /* margin-left: 80px; */
    text-align: center;
    /* vertical-align: top; */
}

.feature-titlee {
    max-width: 200px;
    font-size: 14px;
    font-weight: 400;
    margin-left: 10px;
    text-align: left;
    vertical-align: top;
}

.feature-titles {
    max-width: 260px;
    font-size: 12px;
    font-weight: 300;
    padding-left: 25px !important;
    background: #F1F6FF;
    text-align: left !important;
    vertical-align: top;
    border: 1px solid white !important;
}

.special-note {
    /* width: 219px; */
    font-size: 12px;
    font-weight: 400;
    margin-top: 15px;
    text-align: center;
    margin-bottom: 15px;
}

.tick-icon {
    color: #ff7a59;
    font-size: 20px;
    margin-top: 10px;
}


/* Table Header Styling */
.plans-table thead {
    border: none;
}

.plans-table thead th {
    padding-top: 20px;
    padding-bottom: 30px;
    margin: 30px;
    border: none;
}

.plans-table thead tr {
    margin-bottom: 20px;
}

.plans-table thead tr::after {
    content: '';
    display: block;
    height: 20px;
}

.feature-subtitle {
    font-size: 12px;
    color: #5A6675;
    margin-top: 5px;
    text-align: left;
    margin-left: 10px;
}

/* Ensure the content inside the column is not affected by the border */
.plan-content {
    padding: 20px;
    background-color: white;
}

.plans-wrapper {
    padding: 40px;
    margin-bottom: 20px;
}

/* mobile responsive layout started here */

.subscription-mobile-responsive-container {
    width: 100%;
    padding: 30px;
  }
  
  .mobile-suscription-button-section {
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
  }
  
  .mobile-suscription-button {
    padding: 8px 16px;
    border-radius: 10px;
    border: 2px solid #007bff;
    color: #007bff;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;

  }
  .mobile-suscription-button:hover {

  background:#007bff;
  color: #fff;
  }
  
  .currentplan {

    background:#007bff;
    color: #fff;
    }

  
  .mobile-suscription-content-section {
    padding: 20px;
  }
  

  
  .mobile-suscription-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    background: rgba(242, 246, 255, 1);
    border: 0.5px solid rgba(1, 103, 255, 0.5);
    border-radius: 10px;
    margin-bottom: 5px;
    color: rgba(31, 41, 55, 1);
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  
  .mobile-suscription-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    gap: 10px;
    background: rgba(248, 250, 255, 0.5);
    


}
  .mobile-suscription-details-value{
    display: flex;
    justify-content: space-between;
    color: rgba(31, 41, 55, 1);
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;

  }
  .mobile-suscription-details-button{
    padding: 5px 10px;
    border-radius: 10px;
    border: 2px solid #007bff;
    color: #007bff;
  }

 .mobile-suscription-details-button:hover {
    background:#007bff;
    color: #fff;

 }
  
  /* Arrow styling */
  .arrow {
    width: 0; 
    height: 0; 
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000;
    transition: transform 0.3s ease;
  }
  
  .arrow.down {
    transform: rotate(-90deg);
  }
  
  .arrow.right {
    transform: rotate(0);
  }
  
  .mobile-subscription-tick{
    height: 25px;
    width: 25px;
  }

  @media only screen and (max-width: 1024px) {
    .title {
        font-size: 1.6rem; 
    }

    .subtitle {
        font-size: 1rem; 
    }

    .toggle-buttons {
        width: 90%; 
        padding: 5px;
        border-radius: 10px;
    }

    .toggle-btn {
        padding: 5px 20px; 
        margin: 0 3px; 
    }

    .discount-banner {
        width: auto; 
        padding: 8px 15px; 
        font-size: 0.9rem; 
    }

    .enterprise-notice {
        font-size: 0.9rem; 
    }

    .plans-table th,
    .plans-table td {
        padding: 10px; 
    }

    .feature-title,
    .feature-titlee,
    .feature-titles {
        font-size: 0.8rem; 
        margin-left: 0; 
        text-align: center;
    }

    .plan-name,
    .plan-price {
        font-size: 1rem; 
        width: 100%; 
    }

    .plans-wrapper {
        padding: 10px; 
    }

    .special-note {
        width: 100%;
        font-size: 0.8rem; 
    }

    .add-contact-btn {
        width: 100%; 
        padding: 10px 5px;
        font-size: 10px; 
    }
}

/* Medium Screen Responsive Adjustments */
@media only screen and (max-width: 1200px) {
 
    .title {
        font-size: 1.8rem; 
    }

    .subtitle {
        font-size: 1.1rem; 
    }

    .toggle-buttons {
        width: 90%; 
        padding: 5px;
        border-radius: 10px;
    }

    .toggle-btn {
        padding: 5px 25px; 
        margin: 0 5px; 
    }

    .discount-banner {
        width: auto; 
        padding: 10px 15px; 
        font-size: 0.95rem; 
    }

    .enterprise-notice {
        font-size: 1rem; 
    }

    .plans-table th,
    .plans-table td {
        padding: 12px; 
    }

    .feature-title,
    .feature-titlee,
    .feature-titles {
        font-size: 0.9rem; 
        margin-left: 0; 
        text-align: center;
    }

    .plan-name,
    .plan-price {
        font-size: 1.1rem; 
        width: 100%; 
    }

    .plans-wrapper {
        padding: 15px; 
    }

    .special-note {
        width: 100%;
        font-size: 0.9rem; 
    }

}

  /* Hide the mobile view on larger screens */
@media (min-width: 1024px) {
    .subscription-mobile-responsive-container {
      display: none;
    }
  }
  
  /* Hide the desktop view on smaller screens */
  @media (max-width: 1023px) {
    .plans-wrapper {
      display: none;
    }
    .subscription-background {
    display: none;
}
  }
  
  
@media (max-width: 480px) {
    .enterprise-notice{
        margin-bottom: 0px;
    }
    .subscription-plans{
        padding: 20px;
        margin-bottom: 0px;
    }
    .subscription-mobile-responsive-container {
        width: 100%;
        padding: 10px;
      }
    .mobile-suscription-button-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 10px;
        padding: 20px;
      }
    .mobile-suscription-button {
        font-size: 12px;
        padding: 6px 12px;
    }

    .mobile-suscription-header {
        font-size: 12px;
    }

    .mobile-suscription-details-value {
        font-size: 11px;
    }

    .mobile-suscription-details-button {
        font-size: 11px;
        padding: 3px 6px;
    }
}



