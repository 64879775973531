.header-container {
  background-color: #ffffff;
  padding: 16px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.25px solid #AFB8CF;
  /* box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); */
  border-top-right-radius: 12px;
  position: relative;
}

.chatProfile {
  display: flex;
  align-items: center;
  gap:10px;
}

.chatProfile img {
  width: 45px;
  height: 45px;
  border-radius:5px;
}

.chatProfile-name {
  font-weight: bold;
  font-size: 1rem;
  opacity: 0.6;
}

.header-actions svg {
  margin-left: 20px;
  cursor: pointer;
  font-size: 1em;
  color: #666;
}

@media (max-width: 768px) {
  .header-container {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .chatProfile img {
    width: 50px;
    height: 50px;
  }
  
  .header-actions svg {
    margin-left: 10px;
  }
}
