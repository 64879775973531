.PP-FAQ-section {
  display: flex;
  flex-direction: column;
  max-width: 1279px;
  width: 100%;
  gap: 24px;
  margin-top: 20px;
  padding: 10px 20px 20px 40px;
  border: 1px solid rgba(193, 193, 193, 1);
  background: rgba(255, 255, 255, 1);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
}

.PP-FAQ-section h2 {
  font-size: 22px;
  font-weight: 500;
  line-height: 25px;
  color: #272848;
  margin: 0;
}

.PP-FAQ-container {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.PP-FAQ-image-container {
  flex: 2;
  max-width: 500px;
  height: 370px;
  overflow: hidden;
}

.PP-FAQ-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.PP-FAQ-content {
  flex: 3;
}

.PP-FAQ-list {
  display: flex;
  flex-direction: column;
}

.PP-FAQ-item {
  max-width: 550px;
  height: 86px;
}

.PP-FAQ-question {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  color: #1B3C74;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PP-FAQ-icon {
  font-size: 30px;
  font-weight: 900;
  line-height: 18px;
  color: #2aa7ff;
  cursor: pointer;
}

.PP-FAQ-answer {
  max-width:520px;
  margin-top:5px;
  color: #555;
  font-size: 14px;
  font-weight: 450;
}

.PP-FAQ-error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.PP-FAQ-loading-message {
  text-align: center;
  color: #555;
}

/* Responsive Design */
@media (max-width: 1024px) {
    .PP-FAQ-container {
      flex-direction: column;
      gap: 30px;
      align-items: center;
      text-align: center;
    }
  
    .PP-FAQ-content {
      max-width: 100%;
    }
  }
  
  @media (max-width: 768px) {
    .PP-FAQ-section {
      padding: 15px;
    }
  
    .PP-FAQ-question {
      font-size: 16px;
    }
  
    .PP-FAQ-icon {
      font-size: 18px;
    }
  
    .PP-FAQ-answer {
      font-size: 14px;
    }
  }
  
  @media (max-width: 480px) {
    .PP-FAQ-container {
      flex-direction: column;
      gap: 20px;
    }
  
    .PP-FAQ-image-container {
      max-width: 100%;
    }
  
    .PP-FAQ-question {
      font-size: 15px;
    }
  
    .PP-FAQ-answer {
      font-size: 13px;
    }
  }