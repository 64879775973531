.heroalter-logo-slider-main{
  background-color: #fff; 
}

.heroalter-logo-slider-content{
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap:20px;
}

.heroalter-logo-slider {
  width: 75%;
  overflow: hidden;
  position: relative;
}

.heroalter-logo-track {
  display: flex;
  transition: transform 0.5s ease-in-out;
  position: relative;
}

.heroalter-logo-slide {
  min-width: 25%;
  box-sizing: border-box;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.heroalter-logo-slide img {
  max-width: 70%;
  width: 100%;
  height: auto;
  filter: grayscale(100%);
  object-fit: contain;
}

.heroalter-logo-slider-main .heroalter-logo-titile {
  font-size: 35px;
  font-weight: 50;
  color: #272848;
}

.heroalter-logo-slider-main .heroalter-logo-titile span {
  font-weight: 600;
  color: #272848;
}

@media (max-width: 1024px) {
  .heroalter-logo-slide img {
    max-width: 100%;
  }
}

@media (max-width: 998px) {

  .heroalter-logo-slide {
    min-width: 35%;
    padding:10px 30px;
  }
  .heroalter-logo-slider {
    width: 68%;
    overflow: hidden;
    position: relative;
  }
  .heroalter-logo-slider-main .heroalter-logo-titile {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .heroalter-logo-slider-content{
    padding:30px 0px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  .heroalter-logo-slider-main .heroalter-logo-titile {
    margin: 0;
    display: inline;
  }
  .heroalter-logo-slider {
    width: 90%;
    overflow: hidden;
    position: relative;
  }
  
  .heroalter-logo-slide {
    padding: 0 30px;
  }

}

@media (max-width: 480px) {
  .heroalter-logo-slider-content{
    padding:20px 10px;
  }

  .heroalter-logo-titile {
    font-size: 28px;
  }
  .heroalter-logo-slide {
    width:100%;
    padding: 10px;
  }
}
