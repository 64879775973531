.GlobalPartner-healthcare-conatiner {
  background-color: #ffffff;
  padding: 40px 0 80px 0;
}

/*==============healthcare-section-one=========start========*/
.healthcare-section-one {
  display: flex;
  align-items: center;
  justify-content:center;
  gap:100px;
  padding:0px 20px;
}

.healthcare-content-one {
  text-align: justify;
  max-width: 628px;
  height:fit-content;
}

.healthcare-content-one h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  color: #000;
  margin-bottom: 20px;
}

.healthcare-content-one h1 span {
  color: #0167ff;
}

.healthcare-content-one p {
  font-size: 1rem;
  color: #535353;
  line-height: 1.6;
}

.healthcare-image-one {
  width: 411px;
  display: flex;
  justify-content: start;
  align-items: start;
}

.healthcare-image-one img {
  width: 100%;
  /* border-top-right-radius: 25%;
  border-bottom-left-radius: 25%; */
  object-fit: cover;
}
/*==============healthcare-section-one=========End========*/
/*==============healthcare-section-two=========start========*/
.healthcare-section-two {
  display: flex;
  align-items: center;
  justify-content: center;
  gap:100px;
  margin-top: 80px;
  padding:0px 20px;
}

.healthcare-image-two {
  width: 402px;
  height: 396px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.healthcare-image-two img {
  width: 100%;
  object-fit: cover;
}

.healthcare-content-two {
  max-width: 628px;
  height:fit-content;
}

.healthcare-content-two h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  color: #000;
  margin-bottom: 20px;
}

.healthcare-content-two h1 span {
  color: #0167ff;
}

.healthcare-content-two p {
  font-size: 1rem;
  color: #535353;
  line-height: 1.6;
}
/*==============healthcare-section-two=========End=======*/
/*==============challenges-container=========Start=======*/
.challenges-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 80px;
}
.challenges-heading {
  text-align: center;
}

.challenges-heading h2 {
  font-size: 35px;
  font-weight: 600;
  text-align: center;
  line-height: 42px;
  margin-bottom: 20px;
}

.challenges-heading .highlight {
  color: #0167ff;
}
.challenges-heading p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  color: #535353;
}

.cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
  margin-top: 35px;
}

.cards-head-global {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 25px;
}

.cards-items-global {
  width: 478px;
  height: 190px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #0167ff;
  border: 0.5px solid #0167ff80;
  padding: 20px;
}

.cards-items-global img {
  width: 45px;
  height: 45px;
}

.cards-items-global h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #ffffff;
}

.cards-items-global p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #ffffff;
}

.cards-items-global-all {
  width: 478px;
  height: 190px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: transparent;
  border: 0.5px solid #0167ff80;
  padding: 20px;
}

.cards-items-global-all img {
  width: 45px;
  height: 45px;
}

.cards-items-global-all h3 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #000000;
}

.cards-items-global-all p {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #000000CC;
  margin: 0;
}
/*==============challenges-container=========End=======*/
/*==============timeline-container=========Start=======*/
.physician-timeline-container {
  padding: 100px 40px 0 40px;
}

.physician-timeline-container h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
  color: #000;
}

.physician-timeline-container h1 span {
  color: #0167ff;
}

.physician-timeline-container-sub {
  position: relative;
  margin: 150px auto 0 auto;
}

/* Timeline vertical line */
.physician-timeline-container-sub:before {
  content: '';
  position: absolute;
  top: 0px;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  display: block;
  width: 3px;
  background-image: linear-gradient(to bottom, #0167FF4D 60%, transparent 40%);
  background-position: center bottom;
  background-repeat: repeat-y;
  background-size: 3px 30px;
}

.physician-timeline-item {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 10%;
  margin-top: 100px;
  position: relative;
  height: 100%;
}

.physician-timeline-item.physician-timeline-left .physician-timeline-content {
  order: 1;
  text-align: left;
  width: 450px;
  height: 405px;
}

.physician-timeline-item.physician-timeline-right .physician-timeline-content {
  order: 2;
  text-align: left;
  width: 450px;
  height: 405px;
}

/* This number box bottom big dotted */
.physician-timeline-item:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  height: 20px;
  width: 20px;
  background-color: #FF7043;
  border-radius: 50%;
}

/* === Numbers in the timeline === */
.physician-timeline-number {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-weight: 600;
  line-height: 36px;
  text-align: center; 
  color: #ffffff; 
  width: 65px;
  height: 65px;
  background-color: #FF7043;
  padding: 15px 17px 14px 17px;
  border-radius: 3px;
}

.physician-timeline-item.physician-timeline-left .physician-timeline-image {
  order: 2;
}

.physician-timeline-item.physician-timeline-right .physician-timeline-image {
  order: 1;
}

.physician-timeline-image {
  width: 468px;
  height: 312px;
}

.physician-timeline-image img {
  width: 100%;
  border-radius: 8px;
}

.physician-timeline-content {
  width: 100%;
}

.physician-timeline-content h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  text-align: left;
  color: #000000;
  margin: 0;
}

.physician-timeline-points {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
}

.physician-timeline-point {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.physician-timeline-point p {
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: #000000CC;
  margin: 0;
}

.physician-timeline-point .physician-timeline-point-title-icon {
  display: flex;
  gap: 10px;
}

.physician-timeline-point .physician-timeline-point-title-icon img {
  width: 24px;
  height: 24px;
}

.physician-timeline-point .physician-timeline-point-title-icon h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #000000;
  margin: 0;
}


/*==============timeline-container=========End=======*/
/*==============provider-community-section=========Start=======*/
.provider-community-section {
  width: 100%;
  min-height: 523px; 
  background: #0167FF;
  padding: 60px 0px;
  text-align: center;
  position: relative;
  top:0;
  right: 0;
  overflow: hidden;
  z-index: 1;
}

.provider-community-section::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 300px;
  background-color: #ffffff;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  z-index: 2;
}

.provider-community-header h2 {
  color: #FFFFFF;
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
  margin-bottom: 20px;
  z-index: 2;
  position: relative;
}

.provider-community-header p {
  color: #FFFFFF;
  font-size: 13px;
  font-weight: 500;
  line-height: 17.55px;
  text-align: center;
  margin-bottom: 60px;
  z-index: 2;
  position: relative;
}

.provider-cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  z-index: 2;
  position: relative;
}

.provider-card {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  text-align: left;
  width: 100%;
  max-width: 273px; 
  height: 100%; 
  max-height: 247px;
  border: 0.5px solid #0167FF80;
  transition: transform 0.3s ease;
  position: relative;
  z-index: 3;
}

.provider-card:hover {
  transform: translateY(-5px);
  background: #F2F6FF;
  box-shadow: 0px 4px 4px 0px #00000040;
}

.card-number {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  color: #FFFFFF;
  background-color: #0067ff;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-bottom: 15px;
  z-index: 3;
}

.provider-card h3 {
  width: 210px;
  height: 44px;
  font-size: 18px;
  font-weight: 500;
  line-height: 21.6px;
  text-align: left;
  color: #000000;
  margin-bottom: 10px;
}

.provider-card p {
  width: 223px;
  height: 68px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  color: #000000CC;
}   
/*==============provider-community-section=========End=======*/
/*==============Our Vision=========Start=======*/
.healthcare-content-two .Empowering-color{
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #0167FF;
}
.Empowering-para p{
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: justify;
  color: #535353;
}
/*==============Our Vision===========End=====*/
/*==============healthcare-platform===========Start=====*/
.healthcare-platform {
  display: flex;
  justify-content:center;
  align-items: center;
  padding:0px 40px;
  gap:30px;
  margin-top: 100px;
}

.healthcare-platform-title-head{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.healthcare-platform-title-head h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  text-align: left;
  color: #000000;
  
}

.healthcare-platform-title-head h1 .highlight {
  color:#0167FF;
}

.healthcare-platform-title-head p {
  font-size: 14px;
  font-weight: 400;
  line-height: 18.9px;
  text-align: left;
  color: #535353;
}

.features-list {
  display: flex;
  gap:40px;
  max-width: 50%;
}

.features-list-flex{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.feature-item{
  display: flex;
  flex-direction: column;
  gap:10px;
}

.feature-text{
  display:flex ;
  align-items: center;
  gap: 10px;
}

.feature-text img {
  width: 24px;
  height: 24px;
} 

.feature-text h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #000000;
  margin: 0;
}

.feature-item p{
  width: 270px;
  font-size: 13px;
  font-weight: 400;
  line-height: 15.6px;
  text-align: left;
  color: #000000CC;
  margin: 0;
}

.image-section {
  width: 508px;
  height: 506px;  
}

.creative-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/*==============healthcare-platform===========End=====*/
/*==============plans-section===========Start=====*/
.plans-section {
 margin-top: 130px;  
 padding: 0 40px;

}

.plans-head{
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap:30px;
}

.plans-sub-head{
  display: flex;
  justify-content: center;
  align-items: center;
  gap:30px;
}

.plans-header{
  width: 370px;
  height: 133px;
  display: flex;
  align-items: center;
  justify-content:start;
}

.plans-header h2 {
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  padding-left: 20px;  
}

.plans-header .highlight {
  color: #0167FF;
}

.plan-card{
  width: 370px;
  height: 133px;
  border-radius: 12px;  
  border: 0.5px solid #0167FF80;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap:10px;
}

.plan-card:hover{
  background: #F2F6FF;
}
.plan-card .plan-card-flex{
  display: flex;
  align-items: center;
  gap:10px;
}
.plan-card-flex .plan-img{
  width: 45px;
  height: 45px;
}

.plan-card-flex h3{
  font-size: 16px;
  font-weight: 500;
  line-height: 19.2px;
  text-align: left;
  color: #000000;
}

.plan-card  p{
  font-size: 12px;
  font-weight: 400;
  line-height: 14.4px;
  text-align: left;
  color: #000000CC;
}
/*==============plans-section===========End=====*/
/*==============global-network-container===========Start=====*/
.global-network-container{
 margin-top: 80px;
}
.global-network-heading{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap:30px;
}

.global-network-heading h1 {
  font-size: 35px;
  font-weight: 600;
  line-height: 42px;
  text-align: center;
  color: #000;
}

.global-network-heading h1 span {
  color: #0167ff;
}
.global-network-heading p{
  font-size: 14px;
  font-weight: 400;
  line-height: 22.26px;
  text-align: center;
  color: #000000;
}

.global-network-heading button{
  font-size: 16px;
  font-weight: 600;
  line-height: 25px;
  color: #ffffff;
  width:137px;
  height:39px;
  padding: 7px 20px;
  outline: none;
  border: none;
  gap: 10px;
  border-radius: 5px;
  background: #0167FF;
}
/*==============global-network-container===========End=====*/


/* Responsive Design */
@media (max-width: 1200px) {
  /*==============healthcare-platform===========Start=====*/
  .healthcare-platform-title-head {
    gap:5px;
  }
  .healthcare-platform-title-head h1 {
    font-size: 28px;
  }

  .feature-text img {
    width: 20px;
    height: 20px;
  }
  .feature-text h4 {
    font-size: 14px;
  }
  /*==============healthcare-platform===========End=====*/
  /*==============provider-community-section=========Start=======*/
  .provider-cards-container {
    justify-content: space-between; 
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 70px; 
  }

  .provider-card {
    width: calc(50% - 20px); 
    max-width: none;
    height: fit-content;
    padding: 15px; 
  }

  .provider-card h3 {
    font-size: 16px; 
    line-height: 20px;
    width: 100%;
  }

  .provider-card p {
    font-size: 12px; 
    line-height: 15px;
    width: 100%;
    margin: 0;
  }

  .card-number {
    width: 40px; 
    height: 40px;
    font-size: 18px; 
  }
  /*==============provider-community-section=========End=======*/
  /*==============plans-section===========Start=====*/
  .plans-sub-head{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:30px;
  }
  .plans-header {
    width: 270px;
    height: 133px;
  }
  .plan-card {
    width: 270px;
    height: 133px;
  }
  .plans-header h2 {
    font-size: 25px;
    line-height: 38px;
  }
  /*==============plans-section===========End=====*/
}

@media (max-width: 1024px) {

  /*==============healthcare-platform===========Start=====*/
  .healthcare-platform-title-head {
    gap:5px;
  }
  .healthcare-platform-title-head h1 {
    font-size: 25px;
  }
  .features-list {
    gap: 25px;
  }

  .feature-text img {
    width: 18px;
    height: 18px;
  }
  .feature-text h4 {
    font-size: 12px;
  }
  .feature-item p {
    width: 250px;
    font-size: 12px;
  }
  /*==============healthcare-platform===========End=====*/
  /*==============challenges-container=========Start=======*/
  .cards-head-global {
    gap: 20px;
  }
  .cards-items-global {
    width: 400px;
    height: 220px;
    padding: 20px;
  }
  .cards-items-global-all {
    width: 400px;
    height: 220px;
    padding: 20px;
  }
  /*==============challenges-container=========End=======*/
 
}

@media screen and (max-width: 998px) {
   /*==============healthcare-section-one========Start========*/
  .healthcare-section-one {
    flex-direction: column;
    text-align: center;
  }
  
  .healthcare-content-one,
  .healthcare-image-one {
    max-width: 100%;
  }

  .healthcare-image-one {
    margin-top: 20px;
  }

  .healthcare-image-one img {
    width: 100%;
    /* border-top-right-radius: 0%;
    border-bottom-left-radius: 0%;
    border-top-left-radius: 25%;
    border-bottom-right-radius: 25%; */
    object-fit: cover;
  }

  .healthcare-content-one h1 {
    font-size: 2rem;
  }

  .healthcare-content-one p {
    font-size: 0.9rem;
  }
  /*==============healthcare-section-one========End========*/
  /*==============healthcare-section-Two========Start========*/
  .healthcare-section-two {
    flex-direction: column;
    text-align: center;
    gap:20px;
  }
  .healthcare-image-two{
    width: 380px;
    height: 380px;
    order: 2;
  }
  
  .healthcare-content-two {
    max-width: 100%;
    order: 1;
  }
 

  .healthcare-content-two h1 {
    font-size: 2rem;
  }

  .healthcare-content-two p {
    font-size: 0.9rem;
  }
  /*==============healthcare-section-Two========End========*/
  /*==============challenges-container=========Start=======*/
  .cards-head-global {
    flex-direction: column;
    gap: 20px;
  }

  .cards-items-global,
  .cards-items-global-all {
    width: 100%; 
    max-width: 600px; 
    height:fit-content;
  }

  /*==============timeline-container=========Start=======*/
  .physician-timeline-container {
    padding: 50px 20px 0 20px;
  }
  
  .physician-timeline-container h1 {
    font-size: 28px;
    line-height: 36px;
  }
  
  .physician-timeline-container-sub {
    margin: 100px auto 0 auto;
  }
  
  .physician-timeline-item {
    flex-direction: column;
    gap: 20px;
    margin-top: 50px;
  }
  
  .physician-timeline-item.physician-timeline-left .physician-timeline-content,
  .physician-timeline-item.physician-timeline-right .physician-timeline-content {
    width: 100%;
    height: auto;
    text-align: center;
    order: 2;
  }
  
  .physician-timeline-image {
    width: 100%;
    height: auto;
    order: 1;
  }
  
  .physician-timeline-number {
    top: -40px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    line-height: 30px;
  }
  
  .physician-timeline-content h2 {
    font-size: 20px;
    line-height: 25px;
  }
  
  .physician-timeline-points {
    gap: 20px;
    margin-top: 15px;
  }
  
  .physician-timeline-point p {
    font-size: 12px;
    line-height: 14px;
  }
  
  .physician-timeline-point .physician-timeline-point-title-icon h4 {
    font-size: 14px;
    line-height: 16px;
  }
  
  .physician-timeline-item:before {
    display: none;
  }
  
  .physician-timeline-number {
    display: none;
  }
  
  .physician-timeline-container-sub:before {
    display: none;
  }
  
   /*==============timeline-container=========End=======*/

  /*==============healthcare-platform===========Start=====*/
  .healthcare-platform {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    gap:50px;
  }

  .healthcare-platform-title-head h1 {
    text-align:center;
  }
  .healthcare-platform-title-head p {
    text-align:center;
  }
  .feature-item p{
    width: 300px;
    text-align: left;
    line-height: 18px;
  }

  .features-list {
    padding-top: 20px;
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 40px;
    max-width:100%;
  }
  /*==============healthcare-platform===========End=====*/
  /*==============plans-section===========Start=====*/
  .plans-sub-head{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items:start;
    gap:30px;
    max-width: 100%;
  }
 
  
  .plans-header{
    height: fit-content;
    max-width: 100%;
    display: flex;
    align-items: start;
    justify-content:start;
  }


  .plan-card{
    width:100%;
    height:fit-content;
    max-width: 100%;
    border-radius: 12px;  
    border: 0.5px solid #0167FF80;
    padding:10 20px;
    display: flex;
    flex-direction: column;
    gap:20px;
  }
  /*==============plans-section===========End=====*/
  .provider-cards-container {
    justify-content: center; 
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 20px; 
  }
}

@media screen and (max-width: 768px) {
  /*==============challenges-container=========Start=======*/
  .cards-container {
    padding:0 40px;
  }
  .cards-items-global {
    width: 100%;
    height:fit-content;
    padding: 20px;
  }
  .cards-items-global-all {
    width: 100%;
    height:fit-content;
    padding: 20px;
  }
  
  /*==============challenges-container=========End=======*/
    .physician-timeline-container {
      padding: 50px 20px 0 20px;
    }
  
    .physician-timeline-container h1 {
      font-size: 28px;
      line-height: 36px;
    }
  
    .physician-timeline-container-sub {
      margin: 100px auto 0 auto;
    }
  
    .physician-timeline-item {
      flex-direction: column;
      gap: 20px;
      margin-top: 50px;
    }
  
    .physician-timeline-item.physician-timeline-left .physician-timeline-content,
    .physician-timeline-item.physician-timeline-right .physician-timeline-content {
      width: 100%;
      height: auto;
      text-align: center;
      order: 2;
    }
  
    .physician-timeline-image {
      width: 100%;
      height: auto;
      order: 1;
    }
  
    .physician-timeline-number {
      top: -40px;
      width: 50px;
      height: 50px;
      font-size: 24px;
      line-height: 30px;
    }
  
    .physician-timeline-content h2 {
      font-size: 20px;
      line-height: 25px;
    }
  
    .physician-timeline-points {
      gap: 20px;
      margin-top: 15px;
    }
  
    .physician-timeline-point p {
      font-size: 12px;
      line-height: 14px;
    }
  
    .physician-timeline-point .physician-timeline-point-title-icon h4 {
      font-size: 14px;
      line-height: 16px;
    }

    .physician-timeline-item:before {
      display: none;
        }
        
        .physician-timeline-number {
          display: none;
        }
        .physician-timeline-container-sub:before {
          display: none;
        }
  

  
  /*==============timeline-container=========End=======*/

  /*==============healthcare-platform===========Start=====*/
  .feature-item p {
   width: 270px;
  }
  .image-section {
    width: 380px;
    height: 380px;  
  }
  /*==============healthcare-platform===========End=====*/
  .global-network-heading {
   padding: 0 40px;
  }
  /*==============provider-community-section=========Start=======*/
  .provider-card {
    width: calc(100% - 20px); /* Full width on smaller screens */
  }
}

@media screen and (max-width: 567px) {
 /*==============healthcare-platform===========Start=====*/
  .features-list {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    max-width: 100%;
  }
  /*==============healthcare-platform===========End=====*/
  /*==============timeline-container=========Start=======*/

}  

@media screen and (max-width: 480px) {
  .provider-community-header h2 {
    font-size: 24px;
    line-height: 30px;
  }

  .provider-community-header p {
    font-size: 11px;
  }

  .provider-card h3 {
    font-size: 16px;
    width: none;
    height: none;
  }

  .provider-card p {
    font-size: 12px;
    width: none;
    height: none;
  }
}

@media screen and (max-width: 400px) {
  .healthcare-image-two {
    width: 300px;
    height: 300px;
    padding: 0 20px;
  }

  /* .physician-timeline-item.physician-timeline-left .physician-timeline-content,
    .physician-timeline-item.physician-timeline-right .physician-timeline-content {
        text-align: left;
        width: 300px;
        height: auto;
        padding: 0 20px;
    }
  
    .physician-timeline-image {
        width: 300px;
        height: auto;
        padding: 0 20px;
    } */

  /* .physician-timeline-item.physician-timeline-left .physician-timeline-content {
    text-align: left;
    width: 300px;
    height: auto;
    height: fit-content;
    padding: 0 20px;
  }
  
  .physician-timeline-item.physician-timeline-right .physician-timeline-content {
    text-align: left;
    width: 300px;
    height: auto;
    height: fit-content;
    padding: 0 20px;
  } */

  /* .physician-timeline-image {
    width: 300px;
    height: auto;
    height: fit-content;
    padding: 0 20px;
  } */


  .image-section {
    width:200px;
    height: 200px;  
  }
  
  .creative-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (max-width: 480px) {
  .physician-timeline-container {
    padding: 30px 15px 0 15px;
  }

  .physician-timeline-container h1 {
    font-size: 24px;
    line-height: 32px;
  }

  .physician-timeline-container-sub {
    margin: 60px auto 0 auto;
  }

  .physician-timeline-item {
    gap: 15px;
    margin-top: 40px;
  }

  .physician-timeline-image {
    width: 100%;
    height: auto;
  }

  .physician-timeline-number {
    top: -30px;
    width: 40px;
    height: 40px;
    font-size: 20px;
    line-height: 24px;
  }

  .physician-timeline-content h2 {
    font-size: 18px;
    line-height: 22px;
  }

  .physician-timeline-points {
    gap: 15px;
    margin-top: 10px;
  }

  .physician-timeline-point p {
    font-size: 11px;
    line-height: 13px;
  }

  .physician-timeline-point .physician-timeline-point-title-icon h4 {
    font-size: 12px;
    line-height: 14px;
  }

  .physician-timeline-point .physician-timeline-point-title-icon img {
    width: 20px;
    height: 20px;
  }
}
