.our-products-overview-activity-container {
    display: flex;
    max-width: 1279px;
    width: 100%;
    gap: 24px;
    margin-top: 20px;
  }
  
  .our-products-overview {
    max-width: 827px;
    width: 100%;
    height:fit-content;
    border-radius: 16px;
    border: 1px solid rgba(193, 193, 193, 1);
    background: rgba(255, 255, 255, 1);
    padding: 10px 20px 20px 40px;
  }
  
  .our-products-overview-edit-icons-contains{
    display: flex;
    justify-content: end;
  }
  
  .our-products-overview-edit-icons-head{
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
  }
  .our-products-overview-edit-icons{
    color: rgba(1, 103, 255, 1);
  }
  
  .our-products-overview-edit-icons-head:hover {
    background:rgba(133, 133, 133, 1);
    box-shadow: none;
  }
  
  .our-products-overview-edit-icons-head:hover .our-products-overview-edit-icons {
    color: #ffffff;
  }
  
  .our-products-overview h2 {
    font-size: 20px;
    font-weight: 400;
    line-height: 24px;
    color: rgba(37, 37, 37, 1);
  }
  
  .our-products-overview p {
    font-size: 12px;
    font-weight: 400;
    line-height: 16.4px;
    color: rgba(134, 134, 134, 1);
  }
  
  .our-products-our-specialties-icons-container {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-wrap: wrap;
    gap: 30px;
    margin-top: 15px;
  }
  
  .our-products-our-specialties-icons-container .our-specialties-icons-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    color: rgba(187, 187, 187, 1);
    font-size: 10px;
    font-weight: 600;
    line-height: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .our-products-our-specialties-icons-container
    .our-specialties-icons-item
    .our-specialties-icons-image {
    background: rgba(255, 255, 255, 1);
    width: 65px;
    height: 56px;
    object-fit: contain;
    padding: 6px;
    border-radius: 8px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
      rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }
  
  .our-specialties-icons-item:hover {
    color: rgba(255, 127, 80, 1);
    transform: scale(1.1);
  }
  
  
  
  .our-products-activity-container {
    width: 426px;
    height:fit-content;
    border-radius: 16px;
    background: rgba(255, 255, 255, 1);
    border: 1px solid rgba(193, 193, 193, 1);
  }
  
  .our-products-activity-container .our-products-activity-flex-head {
    padding:20px 20px 15px 20px;
  }
  
  .our-products-activity-container .our-products-activity-flex-head h2 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: rgba(37, 37, 37, 1);
  }
  
  .our-products-activity-underline {
    border-bottom: 1px solid rgba(210, 210, 210, 1);
  }
  
  .our-products-post-card .our-products-activity-body-content {
    display: flex;
    align-items: center;
    padding: 13px 20px;
  }
  
  .our-products-activity-body-content p {
    font-size: 13.37px;
    font-weight: 400;
    line-height: 16.05px;
    color: rgba(37, 37, 37, 1);
    margin: 0;
  }
  
  .our-products-activity-body-content ul li {
    font-size: 13.37px;
    font-weight: 400;
    line-height: 12px;
    color: rgba(170, 170, 170, 1);
  }
  
  .our-products-activity-body-image-content {
    display: flex;
    align-items: center;
    gap: 25px;
    padding: 10px 20px;
  }
  
  .our-products-activity-body-image-content .post-image {
    width: 98.07px;
    height: 98.07px;
    border-radius: 50%;
  }
  .our-products-activity-body-image-content-details h3 {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    color: rgba(37, 37, 37, 1);
  }
  
  .our-products-activity-body-image-content-details p {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    color: rgba(158, 158, 158, 1);
    margin: 0;
  }
  
  .our-products-activity-body-image-content-details span {
    font-size: 11px;
    font-weight: 400;
    line-height: 14.4px;
    color: rgba(109, 168, 255, 1);
    cursor: pointer;
  }
  
  .our-products-post-card h2 {
    text-align: center;
    color: rgba(1, 103, 255, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    cursor: pointer;
    padding-bottom: 5px;
  }
  
  .our-products-post-card h2 .show-all-conditions-icon {
    color: rgba(108, 108, 108, 1);
    margin-left: 10px;
  }
  
  
  /*===============Modal Styles========================*/
  
  
  
  .fade-in{
    animation: fadeIn 0.5s ease-out;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .our-products-overview-modal-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.836);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    padding: 20px;
   
  }
  
  .our-products-overview-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 800px;
    width: 100%;
    height: 90vh; 
    overflow-y: auto;
    margin: 0 auto;
  }
  
  /* Scrollbar Styling */
  .our-products-overview-modal-content::-webkit-scrollbar {
    width: 8px;
  }
  .our-products-overview-modal-content::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 4px;
  }
  
  .our-products-overview-close-btn {
    display: flex;
    justify-content: end;
    cursor: pointer;
  }
  
  .our-products-overview-close-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
    color: rgba(1, 103, 255, 1);
    font-size: 20px;
  }
  .our-products-overview-close-icon:hover {
    background: rgba(133, 133, 133, 1);
  }
  .our-products-overview-close-btn:hover .our-products-overview-close-icon {
    color: #ffffff;
  }
  
  .our-products-overview-modal-content h2{
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    color: rgba(39, 40, 72, 1);
    margin-bottom: 20px;
  }
  
  .our-products-overview-textarea-container{
    display: flex;
    flex-direction: column;
    gap:10px;
    margin-bottom: 20px;
  }
  
  .our-products-overview-textarea-container .our-products-overview-label {
    display: block;
    font-size: 16px;
    font-weight: 500;
    color: rgba(39, 40, 72, 1);
    margin-top:10px;
  }
  
  .our-products-overview-textarea-container .our-products-overview-textarea-head{
    width: 100%;
    height:auto;
    border-radius: 8px;
    padding: 10px;
    background-color: #ffffff;
    border: 2px solid #2E37A41A;
    outline: none;
    display: flex;
    justify-content:center;
    align-items: center;
  }
  .our-products-overview-textarea-head .editable-textarea {
    font-size: 16px;
    font-weight: 400;
    color: #272848;
    display: inline-block;
    border: none;
    outline: none;
    width: 100%;
    padding:0px 10px;
    overflow-y: auto;
    border-radius: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
  }
  
  .our-products-overview-textarea-head .editable-textarea::-webkit-scrollbar {
    width: 0px;
  }
  
  .our-products-specialty-edit-total-container{
    display: flex;
    align-items: center;
    gap:27px;
    flex-wrap: wrap;
    margin-bottom: 25px;
  }
  
  .our-products-specialty-edit-container .our-products-specialty-edit-image-name{
    display: flex;
    gap:15px;
    align-items: center;
    justify-content:space-evenly;
  }
  
  .our-products-specialty-edit-image-name .our-products-specialty-image-preview{
    background: rgba(255, 255, 255, 1);
    width: 70px;
    height: 65px;
    object-fit: contain;
    padding: 6px;
    border-radius: 8px;
    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em,
    rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
  }
  
  .our-products-specialty-edit-image-name p{
    width: 70px;
    font-size: 13.37px;
    font-weight: 400;
    line-height: 12px;
    color: rgba(170, 170, 170, 1);
  }
  
  
  
  .our-products-specialty-edit-popup-remove-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    padding:5px 8px;
    border-radius: 5px;
    border: 0.4px solid orange;
    font-size: 14px;
    font-weight: 400;
    line-height: 14.4px;
    color: orange;
    background: none;
    outline: none;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .our-products-specialty-edit-popup-remove-button:hover {
    background-color: orange;
    border: 0.4px solid orange;
    color: white;
    transform: scale(1.05);
  }
  
  .our-products-specialty-add-new-one{
     display: flex;
     align-items: center;
     justify-content: space-between;
     gap:20px;
  }
  
  .our-products-specialty-add-new-one .our-products-specialty-inputs-style{
    max-width:200px;
    width: 100%;
    height: 45px;
    color: #272848;
    border-radius: 8px;
    padding: 0 22px;
    border: 2px solid #2E37A41A;
    outline: none; 
  }
  
  .our-products-specialty-add-new-one .our-products-specialty-popup-add-photo {
    display: flex;
    align-items: center;
    width: 150px;
    height: 45px;
    gap: 10px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color: rgba(39, 40, 72, 0.61);
    border: 1px solid rgba(39, 40, 72, 0.61);
    border-radius: 8px;
    padding: 8px;
    margin: 0;
  }
  .our-products-specialty-add-new-one .our-products-specialty-popup-add-photo:hover {
    border: 1px solid rgba(39, 40, 72, 1);
    color: rgba(39, 40, 72, 1);
  }
  
  .our-products-specialty-edit-popup-button-container{
    display: flex;
    align-items: center;
    justify-content: end;
    margin: 0 5px;
    gap:20px;
  }
  
  .our-products-specialty-edit-popup-button-container 
  .our-products-specialty-edit-popup-save-chnages-button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    width: 150px;
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    background-color:  #272848;
    font-size: 16px;
    font-weight: 400;
    line-height: 14.4px;
    color: white;
    outline: none;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .our-products-specialty-edit-popup-button-container 
  .our-products-specialty-edit-popup-save-chnages-button:hover {
    border: 0.4px solid #272848;
    background: none;
    color: #272848;
    transform: scale(1.05);
  }
  
  .our-products-specialty-edit-popup-button-container 
  .our-products-specialty-edit-popup-add-new-changes-button{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:10px;
    width: 150px;
    height: 40px;
    padding: 5px;
    border-radius: 5px;
    border: 0.4px solid #272848;
    background:none;
    font-size: 16px;
    font-weight: 400;
    line-height: 14.4px;
    color: #272848;
    outline: none;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .our-products-specialty-edit-popup-button-container 
  .our-products-specialty-edit-popup-add-new-changes-button:hover {
    border: 0.4px solid #272848;
    background-color:  #272848;
    color: white;
    transform: scale(1.05);
  }
  
  
  @media (max-width: 1440px) and (min-width: 1301px) {
    .our-products-activity-body-image-content {
      display: flex;
      align-items: center;
      gap: 25px;
      padding: 10px 10px 10px 20px;
    }
  }
  
  @media (max-width: 1300px) and (min-width: 1199px) {
    .our-products-overview {
      max-width: 700px;
    }
  
    .our-products-activity-container {
      width: 450px;
    }
  
    .our-products-our-specialties-icons-container {
      gap: 15px;
    }
  
    .our-products-activity-body-image-content {
      display: flex;
      align-items: center;
      gap: 25px;
      padding: 10px 10px 10px 20px;
    }
  }
  
  @media (max-width: 1198px) and (min-width: 1025px) {
    .our-products-overview {
      max-width: 600px;
      height: fit-content;
    }
  
    .our-products-our-specialties-icons-container {
      display: flex;
      justify-content: start;
      align-items: start;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 15px;
    }
  
    .our-products-activity-container {
      width: 480px;
    }
  
    .our-products-activity-body-image-content {
      display: flex;
      align-items: center;
      gap: 25px;
      padding: 10px 10px 10px 20px;
    }
  
    .our-products-overview h2,
    .our-products-activity-content h2 {
      font-size: 18px;
    }
    .our-products-overview p,
    .our-products-activity-content p,
    .our-products-activity-body-content p {
      font-size: 12px;
    }
  }
  
  @media (max-width: 1024px) and (min-width: 999px) {
    .our-products-overview {
      max-width: 500px;
      height: fit-content;
    }
    
    .our-products-our-specialties-icons-container {
      display: flex;
      justify-content: start;
      align-items: start;
      flex-wrap: wrap;
      gap: 20px;
      margin-top: 15px;
    }
  
    .our-products-activity-container {
      width: 480px;
    }
  
    .our-products-activity-body-image-content {
      display: flex;
      align-items: center;
      gap: 25px;
      padding: 10px 10px 10px 20px;
    }
  
    .our-products-overview h2,
    .our-products-activity-content h2 {
      font-size: 18px;
    }
    .our-products-overview p,
    .our-products-activity-content p,
    .our-products-activity-body-content p {
      font-size: 12px;
    }
  }
  
  @media (max-width: 999px) {
    .our-products-overview-activity-container {
      flex-direction: column;
      align-items: center;
      gap: 16px;
    }
  
    .our-products-overview,
    .our-products-activity-container {
      width: 100%;
      max-width: 100%;
      height: fit-content;
    }
  
  
    .our-products-overview p,
    .our-products-activity-content p,
    .our-products-activity-body-content p {
      font-size: 11px;
    }
    .our-products-our-specialties-icons-container {
      flex-wrap: wrap;
    }
  
    .our-specialties-icons-image {
      width: 50px;
      height: 45px;
    }
   
  
    .our-products-activity-body-image-content {
      gap: 15px;
      padding: 20px;
    }
    .our-products-activity-body-image-content .post-image {
      width: 80px;
      height: 80px;
    }
    .our-products-activity-body-image-content-details h3 {
      font-size: 12px;
    }
    .our-products-post-card h2 {
      font-size: 14px;
      padding-bottom: 10px;
    }
  }
  
  @media (max-width: 768px) {
    .our-products-overview{
      padding: 20px;
    }
  
    .our-products-overview{
      border-radius: 0px;
      padding: 20px;
    }
    .our-products-activity-container {
      border-radius: 0px;
    }
  
    /*=========Popup style===========*/
    .our-products-overview-modal-content {
      max-width: 90%; /* Adjust width for smaller screens */
      height: 85vh; /* Reduce height to fit within smaller screens */
      padding: 15px; /* Add some padding for better spacing */
    }
  
    .our-products-overview-close-icon {
      width: 25px; /* Scale down the close button */
      height: 25px;
      font-size: 16px;
    }
  
    .our-products-overview-modal-content h2 {
      font-size: 22px; /* Scale down the heading */
      line-height: 28px;
      margin-bottom: 15px;
    }
  
    .our-products-overview-textarea-container .our-products-overview-label {
      font-size: 14px; /* Adjust label font size */
    }
  
    .our-products-overview-textarea-head .editable-textarea {
      font-size: 14px; /* Adjust textarea font size */
      padding: 8px; /* Adjust padding */
    }
  
    .our-products-specialty-edit-total-container {
      gap: 20px; /* Reduce gap for smaller screens */
      flex-wrap: wrap;
    }
  
    .our-products-specialty-edit-container .our-products-specialty-edit-image-name {
      justify-content:start;
      flex-wrap: wrap;
    }
  
    .our-products-specialty-edit-image-name .our-products-specialty-image-preview {
      width: 100px;
      height: 100px;
    }
  
    .our-products-specialty-add-new-one .our-products-specialty-inputs-style,
    .our-products-specialty-add-new-one .our-products-specialty-popup-add-photo {
      width: 100%; /* Make inputs take full width */
      font-size: 14px; /* Adjust font size */
      padding: 10px;
    }
  
    .our-products-specialty-edit-popup-button-container {
      flex-direction: column; /* Stack buttons vertically */
      gap: 10px;
    }
  
    .our-products-specialty-edit-popup-button-container 
    .our-products-specialty-edit-popup-save-chnages-button,
    .our-products-specialty-edit-popup-button-container 
    .our-products-specialty-edit-popup-add-new-changes-button {
      width: 100%; /* Make buttons take full width */
    }
    .our-products-specialty-add-new-one {
      flex-direction: column;
      gap:10px;
    }
  
  }
  
  
  @media (max-width: 480px) {
    .our-products-our-specialties-icons-container {
      display: flex;
      justify-content: start;
      align-items: start;
      gap: 20px;
      margin-top: 15px;
    }
  }
  
  
  
  
  @media (max-width: 320px) {
    .our-products-overview {
      padding: 10px 10px 20px 10px;
    }
  
    .our-products-activity-container .our-products-activity-flex-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      gap: 30px;
    }
  }
  