.hero-choose-path-container {
    padding:70px 40px;
    background-image: linear-gradient(
        rgb(212, 224, 247) -17.3%,
        rgb(243, 246, 253) 80.46%
    );
}

.hero-choose-path-container2 {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content:center;
    gap:20px;
}

.hero-choose-path-and-content{
    display: flex;
    align-items: center;
    justify-content:center;
    gap:50px;
}
.hero-choose-path-and-content h2{
    font-weight: 400;
    font-style: italic;
    color: rgb(2, 48, 71);
    text-align: start;
    line-height: 58px;
    font-size: 58.6668px;
}
.hero-choose-path-and-content h2 .hero-choose-path-highlight{
    font-weight: 700;
}
.hero-choose-path-and-content p{
    font-size: 18px;
    line-height: 28px;
    font-weight: 400px;
    color: rgb(2, 48, 71);
    max-width: 775px;
}


.hero-choose-path-subcontainer {
    display: flex;
    align-items: center;
    justify-content:center;
    gap:50px;
}

.hero-choose-path-subcontainer  .hero-choose-path-sideimg {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:260px;
    height:100px;
}

.hero-choose-path-sideimg img {
    width: 100%;
    height: 100%;
    object-fit:contain;
}

/* Individual Option */

.hero-choose-path-options{
    display: flex;
    gap:15px;
    max-width: 775px;
}
.hero-choose-path-option {
    display: flex;
    justify-content:start;
    align-items: end;
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius:10px;
    width: 180px;
    height: 250px;
    transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    cursor: pointer;
}

.hero-choose-path-option:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.15);
}

.hero-choose-path-option span {
    width: 100%;
    height: 60px;
    padding: 20px 0;
    text-align: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    color: rgb(255, 255, 255);
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    background-image: linear-gradient(to bottom, rgba(0,0,0,0),rgba(0,0,0,5) );
}


/* Background Images */
.hero-choose-path-option.patient {
    background-image: url("../Assets/HeroAlterChoosePath-1.png");
}

.hero-choose-path-option.healthcare-provider {
    background-image: url("../Assets/HeroAlterChoosePath-2.jpeg");
}

.hero-choose-path-option.corporate {
    background-image: url("../Assets/Cororate.jpg");
}

.hero-choose-path-option.medical-supplier {
    background-image: url("../Assets/HeroAlterChoosePath-3.jpeg");
}

@media (max-width: 1200px) {
    .hero-choose-path-and-content h2{
        line-height: 40px;
        font-size: 40.6668px;
    }

    .hero-choose-path-and-content p{
        max-width: 670px;
    }

    .hero-choose-path-subcontainer  .hero-choose-path-sideimg {
        width:200px;
        height:100px;
    }

    .hero-choose-path-option {
        width: 160px;
        height: 250px;
    }
}

@media (max-width: 998px){
    .hero-choose-path-and-content h2{
        line-height: 40px;
        font-size: 28.6668px;
    }
    .hero-choose-path-and-content p{
        max-width:74%;
    }


    .hero-choose-path-options {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        max-width: 775px;
    }

    .hero-choose-path-subcontainer  .hero-choose-path-sideimg {
        width:250px;
        height:100px;
    }

    .hero-choose-path-option {
        width: 250px;
        height: 200px;
    }
}

@media (max-width: 778px){
    .hero-choose-path-container {
        background-image: linear-gradient(
            rgb(212, 224, 247) -10.3%,
            rgb(243, 246, 253) 75.46%
        );
    }
    .hero-choose-path-container2 {
        gap: 40px;
    }
    .hero-choose-path-and-content {
        flex-direction: column;
        gap: 25px; 
    }

    .hero-choose-path-and-content p {
        max-width: 100%;
        font-size: 16px;
        text-align:start;
    }

    .hero-choose-path-subcontainer {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 70px;
    }
    .hero-choose-path-sideimg img {
        transform: rotate(90deg);
    }

    .hero-choose-path-options {
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        gap: 20px;
        position: relative;
        width: 100%;
        overflow: hidden;
        transition: transform 0.5s ease-in-out; 
    }
      
    .hero-choose-path-option {
        width: 180px;
        height: 250px;
        transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
        transition: opacity 0.5s ease-in-out;
    }
    .hero-choose-path-option.active {
        opacity: 1;
    }
      
    .hero-choose-path-left-arrow,
    .hero-choose-path-right-arrow{
        color: #023047;
    }       
}

@media (max-width: 576px) {
    .hero-choose-path-subcontainer .hero-choose-path-sideimg {
        width: 80px;
        height: 100px;
    }

    .hero-choose-path-option {
      width:150px;  
      height: 250px;
    }
    .hero-choose-path-and-content {
        gap: 20px;
    }
    .hero-choose-path-subcontainer {
        gap: 50px;
    }

}

@media (max-width: 480px) {
    .hero-choose-path-option {
        width:110px;  
        height: 250px;
    }
}
  
@media (max-width: 380px) {

    .hero-choose-path-container {
      padding:70px 20px;
    }

    .hero-choose-path-option {
      width: 90px; 
      height: 200px; 
    }
}




