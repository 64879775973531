
.down-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30vh;
  background-color: white;

}

.down-arrow .image-arrow {
  width: 100px;
  height: 100px;
  margin-top: -20px;
}

.color-style {
  background-color: white;
  border-bottom: 1px solid #C3CFE0;
  height: 71px;
}

.navbar-back {
overflow: hidden;
width: 100%;
  max-width: 100%;
height: 74px;
top: 71px;
padding: 16px 81px 16px 80px;
gap: 0px;
border: 0px 0px 1px 0px;

background: #F6F8FC;
border-bottom: 1px solid #C3CFE0



}

.navbar-back form {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  gap: 20px;
  flex-wrap: wrap;
}

.navbar-back .form-control-one {
  border: 1px solid #C3CFE0;
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 7px 10px; 
  flex: 1; 
  min-width: 730px; 
}

.navbar-back .form-control-two {
  border: 1px solid #C3CFE0;
  background: #FFFFFF;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 7px 10px; 
  flex: 2; 
  min-width: 200px;   
}

.navbar-back .form-control-one label, .navbar-back .form-control-two label {
  font-size: 15px;
  font-weight: 500;
  color: #272848;
  margin: 0;
}

.navbar-back .form-control-one input, .navbar-back .form-control-two input {
  background: none;
  border: none;
  outline: none;
  padding-left: 10px;
  width: 100%;
  
 
}

.navbar-back .form-control-two input {
  color: #535353;
  font-size: 16px;
  font-weight: 400;
}

.navbar-back .button-color {
  background: #0167FF;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 500;
  width: 140px;
  height: 42px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex-shrink: 0;
}
/* Dropdown styling */
.dropdown-list {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #C3CFE0;
  border-radius: 5px;
  list-style: none;
  max-height: 200px; /* Limits the dropdown height */
  overflow-y: auto; /* Adds scroll if content overflows */
  z-index: 10; /* Ensures dropdown is above other elements */
  /* margin-top: 5px; */
}
/* Specific styles for What dropdown */
.what-dropdown {
  width: 48%;
  top: 100%; /* Positions the dropdown directly below the input */
}

/* Specific styles for Where dropdown */
.where-dropdown {
  width: 30%;
  top: 100%; /* Positions the dropdown directly below the input */
}

/* Dropdown item styling */
.dropdown-item {
  padding: 8px 15px;
  cursor: pointer;
  font-size: 15px;
  color: #272848;
}

.dropdown-item:hover {
  background-color: #f1f1f1; /* Background color on hover */
}

/* Arrow icon styling */
/* .arrow-icon-filter{
  position: absolute;
  top: 75%;
  left: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: orange;
  pointer-events: none;
} */

/* .input-wrapper {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.input-wrapper .width-input {
  flex: 1;
  padding-right: 30px; 
}

.arrow-icon-doctor-filter {
  font-size: 20px;
  color: orange;
  pointer-events: none;
} */
.dropdown-item.disabled{
  color: #FF7F50 !important;
  opacity: 1 !important;
}
@media (min-width: 1000px) and (max-width: 1022px) {
.navbar-back {
  overflow: hidden;
    width:100%;
    max-width: 100%;

height: 70px;
top: 71px;
padding: 16px 81px 16px 80px;
}
.navbar-back .form-control-one {

  padding: 6px 7px; 
  flex: 1; 
  min-width: 400px; 

}
.navbar-back .form-control-two {

padding: 6px 7px; 
  flex: 2; 
  min-width: 40px;  
  
  
}
.navbar-brand{

  margin-bottom: 60px;


}
.brand-img{
  width: 80px;
height: 31px;
top: 43px;
left: 10px;
gap: 0px;
opacity: 1px;


}
.find-doctor{

  font-size: 10px;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 60px;

width: 120px;
height: 40px;



}
.for-providers,.for-corporates{


  font-size: 10px;
  font-weight: 500;
  line-height: 10px;

 

}
.about-nav{
  font-size: 10px;
  font-weight: 500;
  line-height: 10px;


 

}

.navbar-head-style .nav-signin-button {

padding: 5px 6px;
font-size: 8px;
font-weight: 500;
width: 100px;
border-radius: 5px;



}


.navbar-head-style .nav-register-button {

padding: 5px 6px;
font-size: 8px;
font-weight: 500;


margin-bottom: 50px;
border-radius: 5px;


} 

}
/* Media query for tablet view */
@media (min-width: 1023px) and (max-width: 1439px) {
.navbar-back {
  overflow: hidden;
    width:100%;
    max-width: 100%;

height: 70px;
top: 71px;
padding: 16px 81px 16px 80px;
}
.navbar-back .form-control-one {

  padding: 6px 7px; 
  flex: 1; 
  min-width: 400px; 

}
.navbar-back .form-control-two {

padding: 6px 7px; 
flex: 2; 
min-width: 80px;  


}
.navbar-back .button-color {



text-align: center;
}
.navbar-back .button-color {

font-size: 14px;
font-weight: 500;
width: 150px;
height: 38px;
border-radius: 5px;
text-align: center;
padding: 4px 20px;



}


.color-style {
overflow: hidden;
background-color: white;
width:100%;

border-bottom: 1px solid #C3CFE0;

height: 70px;
}
.navbar-back .form-control-one label, .navbar-back .form-control-two label {
font-size: 14px;
font-weight: 500;
}
.navbar-back .form-control-one input, .navbar-back .form-control-two input {

padding-left: 30px;
width: 100%;
font-size: 14px;
font-weight: 500;



}
}

@media (min-width: 2560px) {
.navbar-back {
  overflow: hidden;
    max-width:100%;

height: 104px;
top: 71px;
padding: 24px 101px 24px 120px;
}
.navbar-back .form-control-one {
margin-left: 50px;
  padding: 14px 15px; 
  flex: 1; 
  min-width: 1200px; 
}
.navbar-back .form-control-two {
margin-right: 40px;
padding: 14px 15px; 
flex: 2; 
min-width: 200px;   

}
.navbar-back .button-color {

margin-right: 40px;

text-align: center;
}
.navbar-back .button-color {
background: #0167FF;
color: #FFFFFF;
font-size: 22px;
font-weight: 700;
width: 240px;
height: 60px;
border: none;
border-radius: 5px;
cursor: pointer;
flex-shrink: 0;
text-align: center;
padding: 15px 30px;
}

.color-style {
background-color: white;
width:100%;
max-width: 100%;
border-bottom: 1px solid #C3CFE0;
height: 104px;
}
.navbar-back .form-control-one label, .navbar-back .form-control-two label {
font-size: 22px;
font-weight: 500;
}
.navbar-back .form-control-one input, .navbar-back .form-control-two input {

padding-left: 30px;
width: 100%;
font-size: 22px;
font-weight: 500;



}
}


@media (min-width: 3840px) {
.navbar-back {
  overflow: hidden;
    width:100%;
    max-width: 100%;
height: 104px;
top: 71px;
padding: 24px 101px 24px 120px;
}
.navbar-back .form-control-one {
margin-left: 50px;
  padding: 14px 15px; 
  flex: 1; 
  min-width: 1200px; 
}
.navbar-back .form-control-two {
margin-right: 40px;
padding: 14px 15px; 
flex: 2; 
min-width: 200px;   

}
.navbar-back .button-color {

margin-right: 40px;

text-align: center;
}
.navbar-back .button-color {
background: #0167FF;
color: #FFFFFF;
font-size: 22px;
font-weight: 700;
width: 240px;
height: 60px;
border: none;
border-radius: 5px;
cursor: pointer;
flex-shrink: 0;
text-align: center;
padding: 15px 30px;
}

.color-style {
background-color: white;
width:100%;
max-width: 100%;
border-bottom: 1px solid #C3CFE0;
height: 104px;
}
.navbar-back .form-control-one label, .navbar-back .form-control-two label {
font-size: 22px;
font-weight: 500;
}
.navbar-back .form-control-one input, .navbar-back .form-control-two input {

padding-left: 30px;
width: 100%;
font-size: 22px;
font-weight: 500;



}
}


.video-wrapper {
background-color: #FFFFFF;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
height: 100vh;
/* margin: 0px auto 115px auto; */
}

.video-player {
transition: transform 1s ease, width 1s ease, height 1s ease;
}

.video-player.zoomed {
width: 100%;
transform: scale(1);
}

.video-player.zoomed-out {
width: 60%;
/* Adjust width as needed */
height: auto;
/* Adjust height as needed */
transform: scale(1);
/* Adjust initial scale */
}

.video {
width: 100%;
transition: transform 1s ease;
}


@media (max-width: 540px){

.video-wrapper {
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  margin: 0px auto;
}

.down-arrow {
  height: 20vh;
}
.down-arrow .image-arrow {
  width: 100px;
  height: 100px;
}

} 

@media (max-width: 412px) {
  .navbar-back {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: auto;
    top: 71px;
    padding: 16px 10px;
    border: 0px 0px 1px 0px;
    background: #F6F8FC;
    border-bottom: 1px solid #C3CFE0;
  }

  .navbar-back form {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure items are spaced evenly */
    gap: 10px;
    width: 100%;
    min-width: 0;
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .navbar-back .form-control-one, 
  .navbar-back .form-control-two {
    border: 1px solid #C3CFE0;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 7px 10px; 
    flex: 1; 

    min-width: 0; /* Allow shrinking */
  }

  .navbar-back .form-control-one {
    flex: 2.2;
  }

  .navbar-back .form-control-two {
    flex: 1;
  }

  .navbar-back .form-control-one label, 
  .navbar-back .form-control-two label {
    font-size: 12px;
    font-weight: 500;
    color: #272848;
    margin: 0;

  }

  .navbar-back .form-control-one input, 
  .navbar-back .form-control-two input {
    background: none;
    border: none;
    outline: none;
    padding-left: 10px;
    width: 100%;
    font-size: 12px;
  }

  .navbar-back .form-control-two input {
    color: #535353;

    font-weight: 400;
  }

  .navbar-back .button-color {
    background: #0167FF;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    width: 60px;
    height: 42px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 02px 2px;
    flex-shrink: 0; /* Prevent shrinking */
    align-self: center; /* Center the button within the form */
    flex: 1.5;
  }
}




@media (min-width: 413px) and (max-width: 600px) {
  .navbar-back {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: auto;
    top: 71px;
    padding: 16px 10px;
    border: 0px 0px 1px 0px;
    background: #F6F8FC;
    border-bottom: 1px solid #C3CFE0;
  }

  .navbar-back form {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure items are spaced evenly */
    gap: 10px;
    width: 100%;
    min-width: 0;
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .navbar-back .form-control-one, 
  .navbar-back .form-control-two {
    border: 1px solid #C3CFE0;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 7px 10px; 
    flex: 1; 

    min-width: 0; /* Allow shrinking */
  }

  .navbar-back .form-control-one {
    flex: 2.2;
  }

  .navbar-back .form-control-two {
    flex: 1.2;
  }

  .navbar-back .form-control-one label, 
  .navbar-back .form-control-two label {
    font-size: 12px;
    font-weight: 500;
    color: #272848;
    margin: 0;

  }

  .navbar-back .form-control-one input, 
  .navbar-back .form-control-two input {
    background: none;
    border: none;
    outline: none;
    padding-left: 10px;
    width: 100%;
    font-size: 12px;
  }

  .navbar-back .form-control-two input {
    color: #535353;

    font-weight: 400;
  }

  .navbar-back .button-color {
    background: #0167FF;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 500;
    width: 60px;
    height: 42px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 4px;
    flex-shrink: 0; /* Prevent shrinking */
    align-self: center; /* Center the button within the form */
    flex: 1.5;
  }

}

@media (min-width: 601px) and (max-width: 767px) {
  .navbar-back {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: auto;
    top: 71px;
    padding: 16px 10px;
    border: 0px 0px 1px 0px;
    background: #F6F8FC;
    border-bottom: 1px solid #C3CFE0;
  }

  .navbar-back form {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure items are spaced evenly */
    gap: 10px;
    width: 100%;
    min-width: 0;
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .navbar-back .form-control-one, 
  .navbar-back .form-control-two {
    border: 1px solid #C3CFE0;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 7px 10px; 
    flex: 1; 

    min-width: 0; /* Allow shrinking */
  }

  .navbar-back .form-control-one {
    flex: 2.2;
  }

  .navbar-back .form-control-two {
    flex: 1.2;
  }

  .navbar-back .form-control-one label, 
  .navbar-back .form-control-two label {
    font-size: 12px;
    font-weight: 500;
    color: #272848;
    margin: 0;

  }

  .navbar-back .form-control-one input, 
  .navbar-back .form-control-two input {
    background: none;
    border: none;
    outline: none;
    padding-left: 10px;
    width: 100%;
    font-size: 12px;
  }

  .navbar-back .form-control-two input {
    color: #535353;

    font-weight: 400;
  }

  .navbar-back .button-color {
    background: #0167FF;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    width: 60px;
    height: 42px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 0px 6px;
    flex-shrink: 0; /* Prevent shrinking */
    align-self: center; /* Center the button within the form */
    flex: 1;
  }
}
@media (min-width: 768px) and (max-width: 999px) {
  .navbar-back {
    overflow: hidden;
    width: 100%;
    max-width: 100%;
    height: auto;
    top: 71px;
    padding: 16px 10px;
    border: 0px 0px 1px 0px;
    background: #F6F8FC;
    border-bottom: 1px solid #C3CFE0;
  }

  .navbar-back form {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Ensure items are spaced evenly */
    gap: 10px;
    width: 100%;
    min-width: 0;
    flex-wrap: nowrap; /* Prevent wrapping */
  }

  .navbar-back .form-control-one, 
  .navbar-back .form-control-two {
    border: 1px solid #C3CFE0;
    background: #FFFFFF;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 7px 10px; 
    flex: 1; 

    min-width: 0; /* Allow shrinking */
  }

  .navbar-back .form-control-one {
    flex: 2.2;
  }

  .navbar-back .form-control-two {
    flex: 1.2;
  }

  .navbar-back .form-control-one label, 
  .navbar-back .form-control-two label {
    font-size: 14px;
    font-weight: 500;
    color: #272848;
    margin: 0;

  }

  .navbar-back .form-control-one input, 
  .navbar-back .form-control-two input {
    background: none;
    border: none;
    outline: none;
    padding-left: 10px;
    width: 100%;
    font-size: 12px;
  }

  .navbar-back .form-control-two input {
    color: #535353;

    font-weight: 400;
  }

  .navbar-back .button-color {
    background: #0167FF;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    width: 60px;
    height: 42px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding: 0px 6px;
    flex-shrink: 0; /* Prevent shrinking */
    align-self: center; /* Center the button within the form */
    flex: 1;
  }
}