.heroalter-container {
    position: relative;
    background: url("./Assets/heroalter-bg.png") no-repeat center center/cover;
    height: 100vh;
    display: flex;
    align-items: end;
    justify-content: start;
    color: white;
    text-align: start;

  }
  
  .heroalter-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2); 
    z-index: 1;
  }
  
  .heroalter-content {
    position: relative;
    z-index: 2;
    max-width: 700px;
    margin: 50px;
    
  }
  
  .heroalter-logo {
    width: 150px;
    margin-bottom: 20px;
  }
  
  .heroalter-content h1 {
    font-size: 4rem;
    font-weight: 100;
    letter-spacing: 2px; /* Add spacing between letters */
    margin-bottom: 30px;
    line-height: 1.2; /* Adjust line height for better spacing */
    width: 100%;
    display: block; /* Ensure full width */
    text-align: start; /* Align the text to the start */
    transform: scaleX(1); /* Stretch the text horizontally */
  }
  
  .heroalter-content h3 {
    font-size: 1.4rem; /* Slightly larger font size for subheading */
    font-weight: 500;
    letter-spacing: 1px; /* Spacing between letters for clarity */
  }
  
  .heroalter-content span {
    font-weight: 700;
    color: white; /* Highlight color for better visibility */
    letter-spacing: 1.5px;
  }
  
  
  .heroalter-button-group {
    display: flex;
    justify-content: start;
    gap: 20px;
  }
  
  .heroalter-button-group .btn {
    padding: 10px 50px;
    font-size: 1rem;
    font-weight: 600;
    border: none;
    cursor: pointer;
    border-radius: 30px;
    transition: all 0.3s ease;
  }
  
  .heroalter-button-group .btn.primary {
    background-color: white;
    color: #143f55;
  }
  .heroalter-button-group .btn.primary:hover {
    border: 3px solid white;
    background-color: transparent;
    color: white;
  }
  .heroalter-button-group .btn.secondary {
    background-color: transparent;
    border: 3px solid white;
    color: white;
  }
  .heroalter-button-group .btn.secondary:hover {
    background-color: white;
    color: #143f55;
  }
  
  .heroalter-button-group .btn:hover {
    opacity: 0.9;
    transform: scale(1.05);
  }
  