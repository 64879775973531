.Provider-profile-container{
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 40px 70px 40px 70px;
    align-items: center; 
    background: linear-gradient(180deg, #edf3ffc3 0%, #FFFFFF 100%); 
}

.Provider-profile-cover-profile-image-head {
    position: relative; 
    max-width: 1279px;
    width: 100%;
    height: 220px;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    z-index: 2;
}

.Provider-profile-cover-profile-image-head img {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    width: 100%;
    height: 100%; 
    object-fit: cover;
}

.Provider-profile-edit-cover-img {
    position: absolute;
    top: 20px;
    right: 30px;
    background: rgba(255, 255, 255, 1);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    padding: 5.27px 6px 6.73px 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;
}

.Provider-profile-edit-cover-img .Provider-profile-cover-edit-icon {
    color: rgba(1, 103, 255, 1);
    font-size: 20px;
}

.Provider-profile-edit-cover-img:hover {
    background:rgba(133, 133, 133, 1);
}

.Provider-profile-edit-cover-img:hover .Provider-profile-cover-edit-icon {
    color: #ffffff;
}


.Provider-profile-profile-info{
    position: absolute;
    top: 135px;  
    left:115px;
    transform: translateX(-50%); 
    z-index: 1; 
    display: flex;
    justify-content: center;
    align-items: center; 
}

.Provider-profile-profile-img {
    display: flex;
    justify-content: center; 
    align-items: center;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 150px;
    height: 150px;
    border-radius: 100%;
    overflow: hidden;    
}

.Provider-profile-profile-img img {
    width: 100%;
    height: 100%;
    object-fit:cover;
}


.Provider-profile-profile-details{
    background: rgba(255, 255, 255, 1);
    max-width: 1279px;
    width: 100%;
    height:fit-content;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap:40px;
    padding:70px 50px 30px 50px;

}

.Provider-profile-body{
    display: flex;
    flex-direction: column;
    gap: 5px;  
}

.Provider-profile-body .Provider-profile-body-title-container {
    display: flex;
    align-items: center;
    gap:30px;
}

.Provider-profile-body .Provider-profile-body-title-container h2 {
    font-size: 32px;
    font-weight: 500;
    line-height: 38.4px;
    color: rgba(1, 3, 8, 1); 
}

.Provider-profile-body .Provider-profile-body-title-container .Provider-profile-rating-number {
    background: rgba(255, 127, 80, 1);
    color: rgba(255, 255, 255, 1);
    width: 48.48px;
    height: 22px;
    border-radius: 20px;;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.8px;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap:6px;
}

.Provider-profile-body .subtitle{
    color: rgba(1, 3, 8, 1);
    font-size: 24px;
    font-weight: 400;
    line-height: 28.8px;
    margin: 0;  
}



.Provider-profile-profile-details .Provider-profile-body-buttons{
    display: flex;
    flex-direction: column;
    gap:20px;
}

/* Container for buttons */
.Provider-profile-body-buttons {
    display: flex;
    gap: 17px;
}

.Provider-profile-body-buttons-two {
    position: relative; /* Ensure dropdown aligns relative to this */
}
  

.Provider-profile-body-buttons .Provider-profile-body-buttons-two {
    display: flex;
    gap: 15px;
}
  
/* Edit Button Styling */
.Provider-profile-body-buttons-two .Edit-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(1, 103, 255, 1);
    width: 130px;
    padding: 10px;
    gap: 5px;
    border-radius: 5px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    outline: none;
    border: none;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
}
  
/* Follow Button Hover Effect */
.Provider-profile-body-buttons-two .Edit-button:hover {
    background-color: #edf3ff;
    border: 0.4px solid #0167ff;
    color: #0167ff;
    transform: scale(1.05);
}

/* verify Button Styling */
.Provider-profile-body-buttons-two .verify-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding: 10px;
    gap: 5px;
    border-radius: 5px;
    color: rgba(1, 103, 255, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    border: 1px solid rgba(1, 103, 255, 1);
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
}
  
.Provider-profile-body-buttons-two .verify-button:hover {
    background-color: rgba(230, 245, 255, 1);
    transform: scale(1.05);
}
  
.Provider-profile-body-buttons-two .DotsThreeCircle {
    cursor: pointer;
}

/* Appointment Button Styling */
.Provider-profile-body-buttons .appointment-button {
    border: 1px solid rgba(255, 127, 80, 1);
    padding: 10px;
    width: 238px;
    border-radius: 5px;
    color: rgba(255, 127, 80, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: background 0.3s ease, transform 0.3s ease;
}
  
.Provider-profile-body-buttons .appointment-button:hover {
    background-color: rgba(255, 230, 220, 1);
    transform: scale(1.05);
}
  
.Provider-profile-body-buttons-two .DotsThreeCircle .DotsThreeCircle-icon{
    width: 40px;
    height: 40px;
    color:rgba(133, 133, 133, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

/* Rotation animation on click */
.DotsThreeCircle-icon.rotate {
    transform: rotate(90deg); /* Rotate the icon on click */
}

/* Dropdown Menu Styling */
.Provider-profile-dropdown-content {
    position: absolute; 
    top: 45px; 
    right:0; 
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: 181px;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 8px 16px 8px;
    z-index: 2;
    opacity: 0; 
    transform: translateY(-10px); /* Start off slightly above */
    transition: opacity 0.3s ease, transform 0.3s ease; 
}

/* Show Dropdown */
.Provider-profile-dropdown-content.show {
    opacity: 1; /* Make visible */
    transform: translateY(0); /* Slide into position */
}

.Provider-profile-dropdown-item {
    display: flex;
    align-items: center;
    gap:10px;
    border: 1px solid rgba(225, 225, 237, 1);
    width: 165px;
    height: 41px;
    padding: 10px 12px 10px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(104, 104, 104, 1);
    transition: background-color 0.3s ease;
}
  
.Provider-profile-dropdown-item:hover {
    background-color: #007bff;
    color: rgba(236, 236, 236, 1);
}

.scroll-lock {
    overflow: hidden;
}
.Provider-profile-preview-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.7);
    padding: 10px;
    border-radius: 10px;
    text-align: center;
  }
  
  .Provider-profile-cover-preview {
    max-width: 200px;
    height: auto;
    border-radius: 5px;
    margin-bottom: 10px;
  }
  
  .Provider-profile-button-group {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .Provider-profile-confirm-btn, .Provider-profile-cancel-btn {
    padding: 5px 10px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .Provider-profile-confirm-btn {
    background: #007bff;
    color: white;
  }
  
  .Provider-profile-cancel-btn {
    background: #FF7F50;
    color: white;
  }
  
  .Provider-profile-savebutton {
    position: relative;  /* Make the button a positioning context */
    border-radius: 5px;
    background: #2489FF;
    color: white;
    padding: 8px 30px;
    border: none;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  .Provider-profile-savebutton:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .Provider-profile-savebutton-text {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
  }
  
  /* Spinner overlay */
  .Provider-profile-spinner-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.5);  /* Optional: Semi-transparent background */
    width: 100%;
    height: 100%;
    border-radius: 5px;  /* Match button's border radius */
  }
  
  /* Small spinner style */
  .Provider-profile-small-spinner {
    width: 24px;
    height: 24px;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-top-color: white;
    border-radius: 50%;
    animation: spin 0.6s linear infinite;
  }
@media (max-width: 1440px) and (min-width: 1301px) {

    .Provider-profile-container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 16px 40px;
        align-items: center;
        background: rgba(255, 255, 255, 1);
    } 
}

@media (max-width: 999px){
    .Provider-profile-container {
        padding:20px;
    }
}

@media (max-width:768px) {
    .Provider-profile-cover-profile-image-head {
        height: 200px;
    }
    .Provider-profile-cover-profile-image-head img {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px; 
    }
    .Provider-profile-profile-info{
        top: 95px;      
        left:95px;
    }
   
    .Provider-profile-profile-details{
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        display: flex;
        flex-direction: column;
        justify-content:start;
        align-items: start;
        gap:20px;
        padding:70px 20px 40px 20px;
    }
}

@media (max-width:480px) {
    .Provider-profile-container {
        padding:0px;
    }
    .Provider-profile-profile-info {
        top: 130px;      
        left:80px;
    }
    .Provider-profile-profile-img {
        width: 110px;
        height: 110px;   
    }
    .Provider-profile-profile-details{
        padding:50px 20px 40px 20px;
    }

    .Provider-profile-body .subtitle {
        font-size: 17px;
    }
}

@media (max-width:320px){
    .Provider-profile-profile-info{
        top: 120px;      
        left:65px;
    }
    .Provider-profile-profile-details{
        padding:60px 10px 40px 10px;
    }
}







