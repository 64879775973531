.hwmihappenbackgroundimage{
    background-image: url('../Assets/hwmibackgroundimage.png');
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height:fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
}

.hwmihappen-content-container{
    padding:120px 40px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap:20px;
}

.hwmihappen-content-container .hwmihappenen-title{
    font-size: 45px;
    line-height: 46px;
    font-weight: 200;
    text-align: center;
}

.hwmihappen-content-container .hwmihappenen-title span{
    font-weight: 700;
}
.hwmihappenen-description{
    max-width: 700px;
    text-align: center;
}

@media (max-width: 1024px) {
    .hwmihappen-content-container .hwmihappenen-title {
        font-size: 36px;
    }

    .hwmihappenen-description{
        max-width: 100%;
    }
}

@media (max-width:480px) {

    .hwmihappen-content-container {
        padding:30px;
    }

    .hwmihappen-content-container .hwmihappenen-title {
        font-size:7vw;
        margin: 0;
    } 
}