.profile-about-container{
  display: flex;
} 
.location-background-container-adjust{
  width: 1200px;
  height: 950px;
}
.card-container-patient{
  display: flex;
}
 .location-background-container{
  width: 880px;
  height: 488px;
  top: 6px;
  left: 490px;
  border-radius: 12px ;
position: relative;
  background: #FFFFFF;

 }
 
 .doctor-edit-name span {
  color: blue !important;
  margin-left: 8px;
  font-size: 0.5em;
}

.blue-tick {
  display: inline-block;
  vertical-align: middle;
  margin-left: 4px;
}



.edit-right {

  position: relative;
  top: 30%;
}



.doctor-profile-edit{
  width: 100%;
max-width: 100%;
height: 100%;
background: linear-gradient(180deg, #EDF3FF 0%, #FFFFFF 100%);
overflow: hidden;

}

.doctor-profile-edit-container{
  padding: 10px;
    width: 880px;
height: 394px;
margin-top: 4.5%;
left: -5%;
position: relative;
display: flex;
border-radius: 12px;
background-color: #ffffff;
/* justify-content: center;
margin-left: auto; */
/* margin-right: auto; */
}
.doctor-details-edit{
  position: relative;
 justify-content: center;
 transform: translateX(-3%);
 margin-left: auto;
margin-right: auto;
top: -90px;
}
.doctor-edit-name{
  width: 442px;
height: 60px;
top: 320px;
left: -20px;
position: relative;
font-family: Matter;
font-size: 22px;
font-weight: 600;
line-height: 60px;
text-align: left;
color: #272848;

}
.edit-profile-degree{
  width: 368px;
  height: 28px;
  top: 300px;
  left: -20px;
  position: relative;
  font-family: Matter;
  font-size: 14px;
  font-weight: 500;
  line-height: 38px;
  text-align: left;
  color: #515265;
 
    
}
.about-edit-profile{
  width: 63px;
height: 25px;
top: 100px;
left: 0px;
position: relative;
font-family: Matter;
font-size: 22px;
font-weight: 500;
line-height: 25px;
text-align: left;
color: #272848;


}
.edit-profile-discription{
  width: 800px;
height: 42px;
top: 100px;
left: 0px;

font-family: Matter;
font-size: 14px;
font-weight: 400;
line-height: 21px;
text-align: justified;
color: #515265;
position: relative;
}
.date-location-edit-container{
  display: flex;
  position: relative;
  top: 20px;
}
.doctor-edit-calender{
  width: 29.17px;
height: 29.17px;

left: 0px;
position: relative;

}
.edit-profile-date{
width: 78px;
height: 18px;
left: 0.5%;
top: 5px;
position: relative;
color: #000000CC;

font-family: Matter;
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;



}
.date-edit-vector{
  position: relative;
  width: 0px;
height: 25px;
top: 3px;
left: 20px;
border: 1px 0px 0px 0px;
opacity: 0px;
border: 1px solid #CACACA;

}
.doctor-edit-location{
  width: 20.42px;
height: 29.17px;
top: 0px;
left: 40px;
position: relative;
}
.doctor-edit-location-text{
width: 51px;
height: 18px;
top: 3px;
left: 45px;
position: relative;
font-family: Matter;
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;

color: #000000;
}
/* .doctor-details {
    flex: 1;
  }
   */

 .speciality-container{
  display: flex;
  position: relative;
  top: 40px;
 }  

 .award-logo{
  width: 20px;
height: 20px;
top: 0px;
left: -2px;
color: #0167FF;
position: relative;
 }
.doctor-profile-edit-img{
  position: relative;
  width: 350px;
  height: 486px;
  top: 70px;
  left:-2%;
  padding: 16px 35px 0px 35px;

  background:#ffffff;
  box-shadow: 0px 4px 4px 0px #00000040;
  overflow: hidden; 
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  
/* background-size: 135% 135%;
background-position:50% top;  */
}

.doctor-edit-profile-photo{
  width: 370px;
  height: 335px;

  top: -218px;
  left:-35px;
position: relative;

overflow: hidden; 
margin-left: auto;
margin-right: auto;
object-fit: cover;
}
.edit-profile-Heart-Specialist{
  width: 104px;
height: 18px;
position: relative;
top: 0  px;
left: 13px;
font-family: Matter;
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;
color: #000000;

}

.edit-profile-disease-container{
display: flex;
position: relative;
top: 60px;
}
.plus-icon-container{
  width: 17px;
  height: 17px;
  top: 0px;
  left: 0px;
  background: #0167FF;
  position: relative;
  border-radius: 50px;
}
.plus-icon{
  width: 17px;
  height: 17px;
  top: -2px;
  left: 0px;
  gap: 0px;
  opacity: 0px;
  color: #FFFFFF;
  position: relative;
  justify-content: center;
  
}



.edit-profile-disease{
  display: flex;
  width: 500px !important;
height: 18px;
top: 0px;
left: 2%;
position: relative;
font-family: Matter;
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;
color: #000000;
gap: 20px;

}
.date-edit-vector-two{
  width: 0px;
height: 25px;
top: -1px;
left: 0px;
position: relative;
border: 1px solid #CACACA
}
.general-medicine{
  width: 175px;
height: 28px;
top: 0px;
left: 30px;
gap: 0px;
opacity: 0px;
font-family: Matter;
font-size: 20px;
font-weight: 400;
line-height: 27.2px;
text-align: left;
color: #000000;
position: relative;
}
.video-consult-container{
  display: flex;
  position: relative;
  top: 88px;
}
.edit-person-icon{
  width: 20px;
height: 20px;
top: 0px;
left: 0px;
position: relative;
border-radius: 50px;
background: #0167FF;
}
.faUserMd-icon{
  color: #ffffff;
  position: relative;
  left: 3px;
  top: -2px;
  width: 14px;
  height: 14px;
}
.video-consult-text{
  width: 3cqmin;
height: 28px;
top: 0px;
left: 1.8%;
position: relative;
font-family: Matter;
font-size: 14px;
font-weight: 400;
line-height: 18px;
text-align: left;

}
.date-edit-vector-three{
  width: 0px;
height: 25px;
top:0px;
left: 17px;
position: relative;
border: 1px solid #CACACA
}
.edit-inperson{
  width: 91px;
height: 28px;
top: 0px;
left: 30px;
position: relative;
font-family: Matter;
font-size: 20px;
font-weight: 400;
line-height: 27.2px;
text-align: left;
color: #000000;

}
.social-links-container{
  display: flex;
  position: relative;
  gap: 30px;
  top: 150px;
  color: #ffffff;




}
.faFacebooks,.faEnvelope,.faInstagram,.faTwitter{
 background: #FF7F50;
 width: 32px;
height: 32px;
top: 0x;
left: 0px;
position: relative;
border-radius: 50px;

}
.faEnvelope-icon,.faInstagram-icon,.faTwitter-icon,.faFacebooks-icon{
  position: relative;
  left: 8px;
  top: 4px;
  color: #ffffff;
}
.edit-doctor-btns{
  gap: 30px
}

.edit-doctor-button{
  position: relative;
  width: 160px;
height:42px;
top: 320px;
left: -10px;
padding: 10px 20px 10px 20px;
gap: 0px;
border-radius: 5px ;
border: none;
background: #0167FF;
color: #FFFFFF;
font-family: Matter;
font-size: 16px;
font-weight: 400;
line-height: 18px;
}

.verify-doctor-button{
  position: relative;
  width: 260px;
height:42px;
top: 248px;
left: 160px;
padding: 10px 20px 10px 20px;
gap: 0px;
border-radius: 5px ;
border: none;
border: 1px solid #0167FF;
color: #0167FF;
font-family: Matter;
font-size: 14px;
font-weight: 400;
line-height: 18px;
}
.verify-doctor-button.pending {
  background-color: #FF7F50;
  
  color: white;
}


.Locations-edit-doc-profile{
  position: relative;
  width: 164px;
height: 25px;
left: -320px;
top: 20px;
font-family: Matter;
font-size: 22px;
font-weight: 500;
line-height: 25px;
margin-left: auto;
margin-right: auto;
justify-content: center;
color: #272848;

}
.doctor-appoinment-card-container-edit{
  position: relative;
  width: 100% !important;
height: 100% !important;
top: 40px !important;
left: 29px !important;
border-radius: 15px;
transition: transform 0.3s ease-in-out;


}
.doctor-appoinment-card-container-edit.moved {
  transform: translate(-250px, -50px);
}
.doctor-appoinment-card-one-edit{
  margin-top: 20px;
  gap:20px;
  position: relative;
  width: 800px;
height: 179px;
top: 0px !important;
left: 0px !important;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2

}
.doctor-appoinment-card-two{
  position: relative;
  width: 860px;
height: 214px;
top: -428px;
left: 230px;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2
}
.hospital-name-box{
  display: flex;
}
.hospital-name-box img {
  position: relative;
  width: 19px;
height: 20px;
top:15px;
left: 10px;

}
.hospital-name-text{
  position: relative;
  width: 237px;
height: 15px;
font-family: Matter;
font-size: 20px;
font-weight: 700;
line-height: 24px;
text-align: left;
color: #0067FF;
top:14px;
left: 15px;

}
.hospital-today{
  position: relative;
width: 76px;
height: 30px;
font-family: Matter;
font-size: 16px;
font-weight: 600;
line-height: 19.2px;
text-align: left;
color: #7A8EA0;
top:17px;
left: 37% !important;
}
.hospital-time{
  position: relative;
  width: 154px;
height: 27px;
font-family: Matter;
font-size: 16px;
font-weight: 500;
line-height: 19.2px;
text-align: left;
color: #7F92A4;
top:17px;
left: 37% !important;
}

.hospital-name-icon{
  position: relative;
  width: 15px;
height: 15px;
padding: 5.14px 3.52px 5px 3.52px;
top:8px;
left: 32% !important;

}
.hospital-charge{
  display: flex;
}
.hospital-charge-dollar{
  width: 24px;
height: 24px;
padding: 3px 7px 3px 7px;

}
.hospital-charge-ammount{
  position: relative;
  width: 16px;
height: 11px;
top: 12px;
left: 6px;

}
.hospital-charge-video{
  position: relative;
  width: 166px;
height: 33px;
font-family: Matter;
font-size: 14px;
font-weight: 600;
line-height: 16.8px;
text-align: left;
color: #B0B9C2;
top: 10px;
left: 12px;
}
.hospital-name-logo-container{
  display: flex;
}

.hospital-name-logo{
  width: 59px;
height: 60px;
position: relative;
border-radius: 15px ;
top: 10px;
left: 15px;
}
.hospital-name-logo-location{
  position: relative;
  width: 173px;
height: 17px;
font-family: Matter;
font-size: 14px;
font-weight: 600;
line-height: 16.8px;
text-align: left;
color: #697B8C;
left: 28px;
top: 12px;

}
.hospital-charge {
  position: relative;
  top: 10px !important;
  left: -3px;
}
.hospital-name-logo-location-container{
  display: flex;
}
.call-now{
  position: relative;
  width: 106px;
  height: 31px;
  font-family: Matter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #4F63E1;
  top: 10px !important;
  left: 84px !important;
color: #FF7F50 !important;

}
.get-direction{
  position: relative;
  width: 162px;
  height: 31px;
  font-family: Matter;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.2px;
  text-align: left;
  color: #4F63E1;
  top: 44px;
  left: -22px !important;
  color: #FF7F50 !important;
}
.hospital-charge-book-appoinment{
  position: relative;
  top:10px;
  left: 50px !important;
  width: 212px;
height: 40px;
padding: 11.62px 44.55px 11.38px 45.45px;
border-radius: 4px ;
background: #0067FF;
border: 1px solid #14BEF0

}
.hospital-charge-book-appoinment-text{
  width: 122px;
height: 17px;
top: 11.62px;
left: 45.45px;
font-family: Matter;
font-size: 14px;
font-weight: 500;
line-height: 16.8px;
text-align: center;
color: #FFFFFF;

}

@media (max-width: 412px) {
  .doctor-profile-edit{
    width: 100%;
      max-width: 100%;
  height: auto;
 /* overflow: hidden;   */
  
  }
  

  .doctor-profile-edit-container{
    width: 100%;
height: 1756px;
top: 3%;
left: 72px;
flex-direction: column;
position: relative;
display: flex;

}

.doctorname{
  width: 318px;
height: 28px;
top: -50px;
left: 50px;
font-size: 30px;

}
.education{
  top: -50px;
 
  margin-left: 22px;
}
.degree{

  width: 270px;
  height: 28px;
 left: 10px;

  

  font-size: 18px;
  font-weight: 600;
 
      
}
.doctor-discription{
  margin-top: 5%;
margin-left: 10%;
top:-30px;
}
.inperson{
margin-left: 0px;

}
.inperson svg{
  margin-left: 10px;
}
.videoconsultation{
  margin-left: 20px;
}
.videoconsultation svg{
  margin-left: 20px;
}
 

.onlineprescription{
  margin-left: 30px;
}

.doctor-objective{
top: 10px;
width: 100%;
max-width: 330px;
padding: 20px;
}

.doctor-profile-edit-img{
  width: 211px;
height: 241px;
top:30px;
border-radius: 53px 0px 53px 0px;
display: flex;
left: -20px;
}
.doctor-details-edit{

display: flex; 
flex-direction: column;
position: relative;
left: -100px;
top: 12%;
}






.doctor-appoinment-card-container-edit{
  position: relative;
  width: 100%;
  max-width: 90%;
height: 455px;
top: -750px;
left: 4.5%;
border-radius: 15px;
transition: transform 0.3s ease-in-out;

}


.doctor-appoinment-card-one-edit{

  position: relative;
  width: 100%;
  max-width: 100%;
height: 414px;
top: 190px;
left: 0px;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2;


}
.hospital-today{
top:250px;
left: -23%;
}
.hospital-time{
width: 100%;
top:250px;
left: -17%;
}
.hospital-charge-book-appoinment {
  position: absolute;
  top: 330px;
  left: 50%; 
  transform: translateX(-50%); 
  width: 200px; 
}
.hospital-name-icon{

  top:240px;
left: -50%;

}
.hospital-name-box{
  display: flex;
  width: 537px;
}
.hospital-name-text{
  position: relative;
  width: 337px;
height: 15px;

font-size: 14px;
font-weight: 700;



top:14px;
left: 15px;

}
.doctor-appoinment-card-two{
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 414px;
top: 200px;
left: 0px;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2
}
.doctor-details-header{
  display: flex;
  position: relative;
  width: 100%;
  max-width: 310px;
height: 48px;
top: -1050px;
left: 0px;
justify-content: center;
margin-left: auto;
margin-right: auto;

}
.doctor-details-header-Location{
  position: relative;
  width: 65px;
  height: 14px;
  left: 0px;
  font-size: 12px;
  


      

}
.doctor-details-header-Insurances{

  width: 97px;
  height: 14px;

  left: 0px;

  font-size: 12px;
  font-weight: 500;

}
.doctor-details-header-About{

  width: 64.83px;
  height: 14px;

  left:-1px;

  font-size: 12px;


}
.doctor-details-header-Ratings{

  width: 52.71px;
  height: 14px;
  left: 0px;
  font-size: 12px;
      
}
.doctor-details-header-faq{

  width: 43.74px;
  height: 12px;
  left: 0px;
  font-size: 12px;
      
}
.share-box{
  width: 100%;
height: 179px;
top:350px;
left: 0px;
}
.share-icon{

top: 140px;
left: 100px;
}
.share-text{
  top: 120px;
  left: 130px;

}

.save-icon{
  top: 86px;
  left: 200px;
}

.save-text{

  top: 67px;
  left: 230px;
}


}

@media (min-width:413px) and (max-width: 600px) {
  .doctor-profile-edit{
    width: 100%;
      max-width: 100%;
  height: auto;
 /* overflow: hidden;   */
  
  }
  

  .doctor-profile-edit-container{
    width: 100%;
height: 1756px;
top: 3%;
left: 72px;
flex-direction: column;
position: relative;
display: flex;

}

.doctorname{
  width: 318px;
height: 28px;
top: -50px;
left: 50px;
font-size: 30px;

}
.education{
  top: -50px;
 
  margin-left: 22px;
}
.degree{

  width: 270px;
  height: 28px;
 left: 10px;
  

  font-size: 18px;
  font-weight: 600;
 
      
}
.doctor-discription{
margin-left: 10%;
top:-30px;
}
.inperson{
margin-left: 0px;

}
.inperson svg{
  margin-left: 10px;
}
.videoconsultation{
  margin-left: 20px;
}
.videoconsultation svg{
  margin-left: 20px;
}
 

.onlineprescription{
  margin-left: 30px;
}

.doctor-objective{
top: 10px;
width: 100%;
max-width: 330px;
padding: 20px;
}

.doctor-profile-edit-img{
  width: 211px;
height: 241px;
top:30px;
border-radius: 53px 0px 53px 0px;
display: flex;
left: -20px;
}
.doctor-details-edit{

display: flex; 
flex-direction: column;
position: relative;
left: -100px;
top: 12%;
}


.doctor-appoinment-card-container-edit{
  position: relative;
  width: 100%;
  max-width: 90%;
height: 455px;
top: -750px;
left: 0%;
border-radius: 15px;
transition: transform 0.3s ease-in-out;
margin-left: auto;
margin-right: auto;
}


.doctor-appoinment-card-one-edit{

  position: relative;
  width: 440px;
height: 414px;
top: 190px;
left: 0px;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2;


}
.hospital-today{
top:250px;
left: -23%;
}
.hospital-time{
width: 100%;
top:250px;
left: -17%;
}
.hospital-charge-book-appoinment {
  position: absolute;
  top: 330px;
  left: 50%; 
  transform: translateX(-50%);
  width: 200px; 
}
.hospital-name-icon{

  top:240px;
left: -50%;

}
.hospital-name-box{
  display: flex;
  width: 537px;
}
.hospital-name-text{
  position: relative;
  width: 337px;
height: 15px;

font-size: 14px;
font-weight: 700;



top:14px;
left: 15px;

}
.doctor-appoinment-card-two{
  position: relative;
  width: 440px;
  height: 414px;
top: 200px;
left: 0px;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2
}


}


@media (min-width:600px) and (max-width: 914px) {
  .doctor-profile-edit{
    width: 100%;
      max-width: 100%;
  height: auto;
 /* overflow: hidden;   */
  
  }
  

  .doctor-profile-edit-container{
    width: 100%;
height: 1756px;
top: 3%;
left: 0px;
flex-direction: column;
position: relative;
display: flex;
margin-left: auto;
margin-right: auto;

}
.doctor-details-edit{
  margin-left: auto;
margin-right: auto;
left: 0;
justify-content: center;
display: flex; 
flex-direction: column;
top: 11%;
position: relative;
}

.doctorname{
  width: 318px;
height: 28px;
top: -50px;
left: -90px;
font-size: 30px;
margin-left: auto;
margin-right: auto;

}
.education{
  top: -50px;
  margin-left: auto;
  margin-right: auto;
  margin-left: 0px;
  left: 0;
  text-align: center;
}
.degree{

  width: 270px;
  height: 28px;
 left: 0px;
 margin-left: auto;
 margin-right: auto;
 text-align: center;
  

  font-size: 18px;
  font-weight: 600;
 
      
}
.doctor-discription{
  margin-left: auto;
  margin-right: auto;
top:-30px;
left: 0;
text-align: center;
position: relative;
text-align: left;
top: -10px;
}
.doctor-objective{
  left: 0%;
}
.inperson{
margin-left: 0px;

}
.inperson svg{
  margin-left: 10px;
}
.videoconsultation{
  margin-left: 20px;
}
.videoconsultation svg{
  margin-left: 20px;
}
 

.onlineprescription{
  margin-left: 30px;
}

.doctor-objective{
top: 10px;
width: 100%;
max-width: 330px;
padding: 20px;
}

.doctor-profile-edit-img{
  width: 311px;
  height: 341px;
top:25px;
border-radius: 53px 0px 53px 0px;
display: flex;
left: 0px;
margin-left: auto;
margin-right: auto;
}





.doctor-appoinment-card-container-edit{
  position: relative;
  width: 100%;
  max-width: 90%;
height: 455px;
top: -700px;
left: 4.5%;
border-radius: 15px;
transition: transform 0.3s ease-in-out;

}

.doctor-appoinment-card-container-edit.moved {
  transform: translate(-0px, -50px);
}
.doctor-details-header.moved {
  transform: translate(-0px, -0px); 
}
.doctor-appoinment-card-one-edit{

  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 440px;
 
height: 350px;
top: 190px;
left: 0px;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2;


}
.hospital-today{
top:220px;
left: -23%;
}
.hospital-time{
width: 100%;
top:220px;
left: -17%;
}
.hospital-charge-book-appoinment {
  position: absolute;
  top: 280px;
  left: 45%; 
  transform: translateX(-50%);
  width: 200px; 
}
.hospital-name-icon{

  top:210px;
left: -50%;

}
.hospital-name-box{
  display: flex;
  width: 537px;
}
.hospital-name-text{
  position: relative;
  width: 337px;
height: 15px;
font-size: 14px;
font-weight: 700;
top:14px;
left: 15px;

}
.doctor-appoinment-card-two{
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 440px;
  height: 350px;
top: 200px;
left: 0px;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2
}

      

}





@media (min-width:915px) and (max-width: 1023px) {
  .doctor-profile-edit-container{
    width: 1307px;
height: 1756px;
top: 100px;
left: 72px;
position: relative;
display: flex;
}

  .doctor-details-header.moved {
    transform: translate(-100px, -83px); 
  }
  .doctor-appoinment-card-container-edit{
    position: relative;
    width: 794px;
  height: 455px;
  top: -300px;
  left:0%;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  }
  .doctor-appoinment-card-one-edit{
    position: relative;
    width: 860px;
  height: 214px;
  top: -452px;
  left: -4%;
  justify-content: center;


  }
  .doctor-appoinment-card-two{
    position: relative;
    width: 860px;
  height: 214px;
  top: -428px;
  left: -4%;
  justify-content: center;
  }

  .doctor-appoinment-card-container-edit.moved {
    transform: translate(0px, 0px);
  }

.doctor-details-header{
width: 743px;
height: 48px;
top: -1270px;
left: 130px;
}
.doctor-profile-edit{
height: 100%;
}






}

@media (min-width:1024px) and (max-width: 1439px) {


  .edit-left {
    left:-15%; /* Optional: space between columns */
    position: relative;
    top: -1%;
  
  }
  
  
  .edit-right {
    left: -15%; /* Optional: space between columns */
    position: relative;
    top: 7%;
  }
  
  .doctor-profile-edit-container{
    width: 1307px;
height: 1756px;
top: 100px;
left: 72px;
position: relative;
display: flex;
}

  .doctor-details-header.moved {
    transform: translate(-100px, -83px); 
  }
  .doctor-appoinment-card-container-edit{
    justify-content: center !important;
    position: relative;
    width: 794px;
  height: 455px;
  top: -900px !important;
  left:0%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 15px;
  transition: transform 0.3s ease-in-out;
  justify-content: center;
  }

  .doctor-appoinment-card-container-edit.moved {
    transform: translate(0px, 0px);
  }
  .doctor-appoinment-card-one-edit{
    position: relative;
    width: 860px;
  height: 214px;
  top: -452px;
  left: 0%;



  }
  .doctor-appoinment-card-two{
    position: relative;
    width: 860px;
  height: 214px;
  top: -428px;
  left: 0%;

  }

.doctor-profile-edit{
height: 100%;
}



.edit-profile-discription{
  width: 400px;
  max-width: 600px !important;
height: 109px;
top: 20px;
left: 0px;

font-family: Matter;
font-size: 18px;
font-weight: 400;
line-height: 27.2px;
text-align: justified;
color: #515265;
position: relative;
}

.Locations-edit-doc-profile{
  position: relative;
  width: 164px;
height: 25px;
left: 0px;
top: -950px;
font-family: Matter;
font-size: 36px;
font-weight: 700;
line-height: 25px;
margin-left: auto;
margin-right: auto;
justify-content: center;
color: #272848;

}
}
@media (min-width:1441px) and (max-width: 2000px) {
  .doctor-profile-edit-container{
    width: 100%;
height: auto;
top: 3%;
left: 0px;
position: relative;
display: flex;

}
.doctor-details-edit{
  left: 20.5%;
  position: relative;
  transform: translateX(-50%);

}
  .doctor-profile-edit-img{
    /* margin-left: auto;
    margin-right: auto; */
    transform: translateX(-50%);
    left: 7.5%;
  }
  .share-box{
    width: 100%;
height: 179px;

}


.doctor-appoinment-card-container-edit{

  position: relative;
  width: 100% !important;
height: 100% !important;
top: 250px !important;
left: 9px !important;
border-radius: 15px;
transition: transform 0.3s ease-in-out;

}
.doctor-appoinment-card-one-edit{

  margin-top: 20px;
  gap:20px;
  position: relative;
  width: 860px;
height: 214px;
top: 0px !important;
left: 0px !important;
margin-left: auto;
margin-right: auto;
padding: 8px 12px 8px 8px;
border-radius: 15px;
border: 1px solid #E2E2E2

}
.Locations-edit-doc-profile{
  position: relative;
  width: 164px;
height: 25px;
left: 0px;
top: 200px;
font-family: Matter;
font-size: 36px;
font-weight: 700;
line-height: 25px;
margin-left: auto;
margin-right: auto;
justify-content: center;
color: #272848;

}
.edit-left {
  left:-25%; 
  position: relative;
  top: -2%;

}


.edit-right {
  left: 32%; 
  position: relative;
  top: -58%;
}


}



@media (min-width:2001px) and (max-width: 4086px) {
  .doctor-profile-edit-container{
    width: 100%;
height: auto;
top: 3%;
left: 0px;
position: relative;
display: flex;
margin-left: auto;
margin-right: auto;
}
.doctor-details-edit{
  left: 30%;
  position: relative;
  transform: translateX(-50%);

}
  .doctor-profile-edit-img{
    /* margin-left: auto;
    margin-right: auto; */
    transform: translateX(-50%);
    left: 24%;
  }
  .share-box{
    width: 100%;
height: 179px;

}


.doctor-appoinment-card-container-edit{

top: 680px;
left: 0px;
margin-left: auto;
margin-right: auto;


}
.doctor-appoinment-card-one-edit{

top: -452px;
left: 0px;

}
.doctor-appoinment-card-two{
  
top: -428px;
left: 0px;

}

}


































































































/* .doctor-profile{
  width: 1445px;
height: 4400px;
  background: linear-gradient(180deg, #EDF3FF 0%, #FFFFFF 100%);


} */