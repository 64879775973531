/* popup-overlay */

.Claimprofile-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.836);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 10px;
}

.Claimprofile-popup-content {
  background-color: #ffffff; 
  border-radius: 12px;
  padding:20px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  color: #333; 
  animation: popup-animation 0.4s ease-in-out;
  position: relative;
}

/* Smooth popup animation */
@keyframes popup-animation {
  from {
    transform: translateY(-20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

/* Close icon */
.Claimprofile-popup-close-btn {
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.Claimprofile-popup-close-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  color: rgba(1, 103, 255, 1);
  font-size: 20px;
}

.Claimprofile-popup-close-icon:hover {
  background: rgba(133, 133, 133, 1);
}

.Claimprofile-popup-close-btn:hover .Claimprofile-popup-close-icon {
  color: #ffffff;
}

.Claimprofile-popup-content h2 {
  font-size: 28px;
  font-weight: 600;
  line-height: 33.6px;
  color: rgba(39, 40, 72, 1);
  margin-bottom: 20px;
}

.Claimprofile-popup-content .Claimprofile-popup-para{
  font-weight: 500;
  color: black;
  margin: 20px 0;
}

.Claimprofile-popup-form-container{
  display:flex;
  flex-direction:column;
  gap:25px;
}

.Claimprofile-popup-input-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:5px;
}

.Claimprofile-popup-input-container label{
  font-size: 16px;
  font-weight: 500;
  color: rgba(39, 40, 72, 1);
  margin:0;
}

.Claimprofile-popup-input-container .Claimprofile-popup-input {
  width: 100%;
  border-radius: 10px;
  padding:10px 18px;
  border: 2px solid #2E37A41A;
  outline: none;
  font-size: 15px;
}
.Claimprofile-popup-input-container .Claimprofile-popup-input::placeholder{
  color:black;
}

/* popup PDF uploaded */
.Claimprofile-popup-upload-pdf-container{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap:5px;
}

.Claimprofile-popup-upload-pdf-container label{
  font-size: 16px;
  font-weight: 500;
  color: rgba(39, 40, 72, 1);
  margin:0;
}

.Claimprofile-popup-header-file{
  position: relative;
  border-radius: 10px;
  width: 100%;
  padding:8px 18px;
  border: 2px solid #2E37A41A;
  outline: none;
}

.Claimprofile-popup-file-input{
  width: 100%;
  opacity: 0;
  pointer-events: none;
}

.Claimprofile-popup-file-name{
  position: absolute;
  left: 1rem;
  top: 0;
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  color:black;
  font-size: 15px;
}

.choose-file-Claimprofile-popup {
  position: absolute;
  right: 0;
  width: 7rem;
  top: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #F7FAFF;
  border-radius:0 8px 8px 0 ;
}

.choose-file-Claimprofile-popup span {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px; 
  color:#0167FF;  
}

.Claimprofile-popup-placeholder {
  position: absolute;
  top: -0.8rem;
  left: 1rem;
  padding: 0 0.5rem;
  transition: all 200ms ease-in-out;
  font-weight: 500;
  pointer-events: none;
  background: #ffffff;
}

.Claimprofile-claim-popup-buttons{
  display: flex;
  justify-content:flex-start;
  align-items: center;
}
.Claimprofile-claim-popup-buttons .popup-claim-btn{
  width: 190px;
  height: 39px;
  padding: 5px 20px;
  text-align: center;
  border:1px solid  #0167ff;
  background-color:transparent;
  color: #0167ff;
  font-size:14px;
  font-weight: 600;
  line-height: 25px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;  
}

.Claimprofile-claim-popup-buttons .popup-claim-btn:hover {
  background-color: #edf3ff;
  color: #0167ff;
  transform: scale(1.05);
  margin:0 5px;
}