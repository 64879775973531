.toast-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  padding: 16px;
  color: #0067FF;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
}
.toast-center .Toastify__close-button {
  color: #0067FF; 
}

.toast-center .Toastify__progress-bar {
  color: #0067FF !important;
}
.toast-center .Toastify__toast-icon {
  color: #0067FF; 
}

/* .toast-success {
  background-color: blue;
}

.toast-fail {
  background-color: orange;
} */

  body{
    background-color: #eef4ff !important;
  }
  .doc-card-header h4{
    font-size: 30px;
    text-wrap: nowrap;
    font-weight: 600;
    color: #272848;
  }
  .doc-card-header p{
    text-wrap: wrap;
    margin-left: 3px;
    color: #999ba8;
    font-size: 14.5px;
  }
 
 .sort-by {
  text-wrap: nowrap;
  font-size: 13px;
  position: relative;
  display: flex;
  /* align-items: end; */
  width: 100%;
  margin-bottom: 0 !important;
  padding-right: 0;
}
.hospital-sort-by{
  text-wrap: nowrap;
  font-size: 10px;
  position: relative;
  display: flex;
  /* align-items: end; */
  width: 75%;
}

.sort-by .form-group {
  display: flex;
  align-items: flex-end;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px !important;
}
.hospital-sort-by .form-group{
  display: flex;
  align-items: flex-end;
  width: 100%;
  /* margin-top: -10px; */
  margin-bottom: 0px !important;
}

.sort-by .form-group select,
.sort-by .form-group input {
  border: 1px solid #78798d;
  font-size: 14px;
  font-weight: 400;
  padding: 8px 12px 8px 8px;
  border-radius: 4px;
  -webkit-appearance: none; /* For Chrome */
  -moz-appearance: none; /* For Firefox */
  appearance: none; /* For modern browsers */
  outline: none;
  width: 100%;
  box-sizing: border-box;
  color: #272848;
  background: transparent;
}

.hospital-sort-by .form-group select,
.hospital-sort-by .form-group input {
  border: 1px solid #afafb3;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  border-radius: 4px;
  -webkit-appearance: none; /* For Chrome */
  -moz-appearance: none; /* For Firefox */
  appearance: none; /* For modern browsers */
  outline: none;
  width: 100%;
  box-sizing: border-box;
  color: #272848;
  background: transparent;
}


.sort-by .form-group label {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #272848;
  white-space: nowrap;
}
.hospital-sort-by .form-group label {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
  color: #272848;
  white-space: nowrap;
}
.sort-by .form-group .arrow-icon-filter {
  position: absolute;
  top: 25%;
  right: 20px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #FF7F50;
  pointer-events: none;
}

.hospital-sort-by .form-group .arrow-icon-filter {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 20px;
  color: #FF7F50;
  pointer-events: none;
}

 .sort-by .form-group .date-input-container {
  position: relative;
}
.hospital-sort-by .form-group .date-input-container {
  position: relative;
}


  .doctor-card {
    background-color: #fff;
    padding: 20px 5px 20px 5px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    flex-wrap: wrap;
    overflow: hidden;
    transition: width 0.5s ease-in-out, font-size 0.5s ease-in-out, margin 0.5s ease-in-out;
}
.row.doctor-card:hover{
  /* background-color: #0067FF; */
  box-shadow: rgba(35, 88, 194, 0.35) 0px 5px 15px;
}
  
  .doctor-info {
    display: flex;
  }
  
  .sponsored-doctor-photo {
    width: 120px;
    height: 120px;
    border: #ff7f50 solid 2.5px;
    border-radius: 50%;
    margin-right: 20px;
  }
  .doctor-photo{
    width: 120px;
    height: 120px;
    border: #007bff solid 2.5px;
    border-radius: 50%;
    margin-right: 20px;
  }
  .doctor-details1 {
    transition: font-size 0.5s ease-in-out, margin-left 0.5s ease-in-out;
    width: 100%;
    /* margin-left: ; */
  }
  
  .doctor-details1 h2 {
    color: #007bff;
    margin: 0;
    font-size: 20px;
    font-weight: 600;
  }
  
  .doctor-details1 p {
    margin: 5px 0;
  }
  .speciality{
    color: #999ba8;
    font-size: 14px;
  }
  .experience{
    color: #999ba8;
    font-size: 14px;
  }
  .location{
    color: #000000;
    font-size: 15px;
    font-weight: 600;
  }
  .clinic{
    font-size: 14px;
    /* text-wrap: nowrap; */
  }
  .consultation-type {
    font-size: 15px;
    padding-bottom: 8px;
    border-bottom: #e1dede dashed 1px;
  }
  .consultation-type img{
    width: 20px;
    height: 20px;
    margin-right: 4px;
  }
  .consultation-type input{
    margin-top: 7px;
  }
  .percentage-data{
    margin-top: 15px;
    font-size: 15px;
  }
  .percentage-data span{
    font-size: 15px;
    font-weight: 700;
    color: #000000;
  }
  .liked{
    display: flex;
    justify-content: center;
    background-color: #ff7f50;
    padding: 3px 5px;
    width: 60px;
    margin-right: 10px;
    border-radius: 5px;
  }
  .liked img{
    width: 18px;
    height: 18px;
    margin-right: 3px;
  }
  .liked span{
    color: #ffffff;
    font-size: 13px;
    font-weight: 600;
  }
  .rating span {
    margin-right: 10px;
  }
  
  .consultation-type span {
    font-size: 14px;
    margin-right: 20px;
  }
  .rating-stars{
    margin-bottom: 80px;
  }
  .appointment {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .book-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .book-login{
    padding: 10px 50px;
  }
  .distance{
    font-size: 12px;
    margin: 0;
  }
  .availability{
    font-size: 15px;
    color: #FF7F50;
    font-weight: 500;
  }
  /* .distance-div{
    margin-left: 35px;
  } */

/* Date navigator styles */
.date-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  /* margin-bottom: 20px;
  padding-bottom: 10px; */
}

/* Arrow button styles */
.slots-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid #ddd;
  border-radius: 50%;
  cursor: pointer;
  color: orange;
}
.arrow:disabled {
  color: orange;
  cursor: not-allowed;
}

/* Date carousel styles */
.date-carousel {
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  flex-grow: 1;
}

.date-item {
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  flex: 1;
  /* border-bottom: 2px solid #eee; */
  transition: border-bottom 0.3s;
}

.date-item.active {
  /* border-bottom: 2px solid #007bff; */
  color: #000000;
}
.date-item.active h3{
  color: #000000;
  font-weight: bold;
}
.underline {
  position: relative;
  height: 5px;
  background-color: #F0F0F5;
  margin: 0 100px;
}

.underline-active {
  position: absolute;
  height: 5px;
  background-color: #2AA7FF;
  transition: left 0.3s, width 0.3s;
}
.disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.pagination-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  background-color: #f1f5f9; /* Light gray background for inactive state */
  color: #1d1d1d;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination-button:hover {
  background-color: #006aff; /* Coral for hover effect */
  color: #fff;
}

.pagination-button.active {
  background-color: #006aff; /* Bright blue for active page */
  color: #fff;
}

.pagination-button:disabled {
  background-color: #ddd;
  cursor: not-allowed;
}

/* Custom styles for specific arrows */
.pagination-button.prev,
.pagination-button.next {
  width: 30px;
  height: 30px;
}

.pagination-button.prev::before {
  content: '«'; /* Double arrow for the previous button */
  font-size: 18px;
}

.pagination-button.next::before {
  content: '»'; /* Double arrow for the next button */
  font-size: 18px;
}

.consultation-price span{
  color: #ff7f50;
}
.minimal-dropdown select {
  padding: 10px 10px;
  border: 1px solid #ccc; /* Light gray border for minimal look */
  border-radius: 8px;
  font-size: 14px;
  background-color: white;
  color: #333; /* Darker text for readability */
  appearance: none; /* Remove default dropdown arrow */
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: none;
  cursor: pointer;
  box-shadow: none;
  width: 100%; /* Full width */
  transition: border-color 0.3s ease-in-out; /* Smooth focus transition */
}

.minimal-dropdown select:focus {
  border-color: #007bff; /* Border color change on focus */
}

.minimal-dropdown {
  position: relative;
  width: 150px; /* Adjust this to fit the container */
}

/* Custom dropdown arrow */
.minimal-dropdown::after {
  content: '▼';
  color: #ff7f50;
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  font-size: 12px;
  color: #888; /* Light arrow color */
  pointer-events: none;
}

/* .date-selector {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.date-item {
  flex: 1;
  text-align: center;
  position: relative;
}

.date-item::after {
  content: '';
  display: block;
  margin: 0 auto;
  height: 2px;
  background-color: #37adff;
  width: 0;
  transition: width 0.3s ease;
}

.date-item.active::after {
  width: 80%; 
} */

.custom-dropdown {
  padding: 5px 2px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  font-size: 16px;
  transition: border-color 0.3s;
}

.custom-dropdown:hover {
  border-color: #007bff; /* Change on hover */
}

.custom-dropdown {
  transition: all 0.3s ease;
}

.date-item h3 {
  margin: 5px 0;
  font-size: 15px;
}

.date-item .slots-available {
  color: #ff7f50;
  font-size: 14px;
}

/* Time slots group styles */
.time-slots-group {
  margin-bottom: 20px;
  border-bottom: #e6f7ff solid 1px;
  padding-bottom: 20px;
}
.time-slots-group h6{
  font-size: 14px;
  margin: 10px 30px 10px 10px;
  font-weight: 400;
}

/* Time slots container styles */
.time-slots {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
}

/* Individual time slot button styles */
.time-slot {
  background: white;
  border: 1px solid #37adff;
  border-radius: 5px;
  padding: 5px 20px;
  margin: 5px;
  cursor: pointer;
  font-size: 13px;
  color: #37adff;
}

.time-slot:hover {
  background: #e6f7ff;
  border-color: #007bff;
}
.time-slot.selected {
  background-color: #007bff; /* Change to your desired color */
  color: #fff;
  border: none; /* Optionally remove border */
}

.c{
  margin-top: 20px;
  border-top: #eee solid 1px;
}

.sponsored-text {
  display: flex;
  align-items: center;  
  /* margin: 10px 0; */
}

.sponsored-text p {
  flex-grow: 1; /* Allow the paragraph to take up available space */
  margin: 0 0 0 0 ; /* Remove default margin for better alignment */
  padding-left: 8px; /* Optional: Add padding for spacing */
  font-weight: 100;
  font-size: 14px;
  opacity: 0.5;
}
/* .result-card p{
  opacity: 0.6;
} */

.container.expanded-map {
  max-width: 70%;
}
  .supplier-aboutMe{
    font-size: 0.9rem;
  }

/* .doctor-card-container {
  background-color: #ffffff;
  padding: 20px;
  margin: 10px 0;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
} */

  /* .appointment-slots {
    width: 100%;
  }
   */
  /* .slots {
    display: flex;
    flex-wrap: wrap;
  }
  
  .slot-button {
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
  }
   */

   /* DoctorMainCard.css */
   @media (max-width: 1000px) {
    
   }
@media (max-width: 768px) {
  .container {
      flex-direction: column;
  }
  .doc-card-header {
      flex-direction: column;
  }
  .doc-card-header h4{
    font-size: 25px;
  }
  .doc-card-header p{
    font-size: 9.5px;
  }
  .no-results-message{
    font-size: 12px !important;
  }
  .sort-by .form-group label {
    font-size: 11px;
  }
  .sort-by .form-group select,
.sort-by .form-group input{
  font-size: 10px;
}
.sponsored-text p{
  font-size: 10px;
}
  .sort-by {
      width: 100%;
      margin-top: 10px;
  }
  .doctor-card {
      flex-direction: column;
  }
  .doctor-info, .appointment {
      width: 100%;
      text-align: start;
  }
  .doctor-details1 {
      margin-top: 10px;
  }
  .doctor-photo {
      width: 80px;
      height: 80px;
  }
  .sponsored-doctor-photo{
    width: 80px;
    height: 80px;
  }
  .book-button {
      width: 100%;
      margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .doctor-card {
      padding: 10px;
  }
  .doctor-photo {
      width: 60px;
      height: 60px;
  }
  .sponsored-doctor-photo{
    width: 60px;
      height: 60px;
  }
  .doctor-details1 h2 {
      font-size: 16px;
  }
  .doctor-details1 p {
      font-size: 12px;
  }
  .book-button {
      font-size: 14px;
  }
  .consultation-type {
    font-size: 12px;
  }
  .percentage-data span {
    font-size: 10px;
  }
  .percentage-data{
    margin-top: 11px;
  }
  .availability{  
    margin: 10px 0px 0px 0px;
    font-size: 12px;
  }
  .rating-stars{
    display: none;
  }
  .book-button{
    font-size: 12px;
  }
}
@media (max-width: 360px) {
  .appointment{
    padding-left: 65px !important;
  }
  .date-item h3 {
    text-wrap: nowrap;
    font-size: 12px;
  }
  .slots-available{
    text-wrap: nowrap;
    font-size: 10px;
  }
}
.failed-response {
  color: #ff4d4d; /* Red color to indicate an error */
  font-weight: bold; /* Make the text bold */
  background-color: #ffe6e6; /* Light red background for emphasis */
  padding: 15px; /* Add some padding for spacing */
  border-radius: 8px; /* Rounded corners for a modern look */
  border: 1px solid #ff4d4d; /* Border matching the text color */
  margin-top: 20px; /* Add some margin for separation */
  text-align: center; /* Center-align the message */
  font-size: 16px; /* Increase font size for readability */
  animation: fadeIn 1s ease-in-out; /* Fade-in effect */
}

/* Adding fade-in animation */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.no-results-message {
  color: #6c757d; /* Neutral gray color for subtle emphasis */
  background-color: #f8f9fa; /* Light gray background for a soft contrast */
  padding: 15px; /* Add padding for spacing */
  border-radius: 8px; /* Rounded corners for a modern look */
  border: 1px solid #dee2e6; /* Light border to outline the message */
  margin-top: 20px; /* Add some margin for separation */
  text-align: center; /* Center-align the text */
  font-size: 16px; /* Increase font size for readability */
  font-style: italic; /* Italic font to differentiate the message */
}

/* Book Button Styling */
.book-appointment-btn .book-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(1, 103, 255, 1);
  padding: 10px 20px;
  gap: 5px;
  border-radius: 5px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  outline: none;
  border: 0.4px solid #0167ff;
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
}
/* Claim Profile Button Specific Styling */
.book-appointment-btn .claim-profile-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF7F50; /* Coral color for better visibility */
  padding: 10px 20px;
  gap: 5px;
  border-radius: 5px;
  color: #ffffff; /* White text color */
  font-size: 15px;
  font-weight: bold; /* Ensures the text is bold */
  outline: none;
  border: 1px solid rgba(255, 127, 80, 1); /* Slightly darker border color */
  cursor: pointer;
  transition: background 0.3s ease, transform 0.3s ease;
  text-align: center; /* Center the text properly */
}

/* Claim Profile Button Hover Effect */
.book-appointment-btn .claim-profile-button:hover {
  background-color: rgba(255, 230, 220, 0.5); /* Lighter background on hover */
  border: 1px solid rgba(255, 127, 80, 1); /* Slightly darker border color */
  color: #FF7F50; /* Ensures the text color remains white */
  transform: scale(1.05);
}

/* Book Button Hover Effect */
.book-appointment-btn .book-button:hover {
  background-color: #edf3ff;
  border: 0.4px solid #0167ff;
  color: #0167ff;
  transform: scale(1.05);
}

/* MAPEXPANDED */
.mapExpanded-doctor-card{
  width: 100%;
}
.mapExpanded-button{
  font-size: 12px;
  padding: 10px 20px;
}
.mapExpanded-percentage-data{
  display: none !important;
}
.mapExpanded-location{
  font-size: 13.5px;
}
.mapExpanded-clinic{
  font-size: 13px;
}
.mapExpanded-consultation-type span{
  font-size: 13px;
}
.mapExpanded-appointment {
  display: flex;
  flex-direction: column;
  align-items:center !important;
  margin-top: 10px; 
  margin-left: 18px;
}
.mapExpanded-sponsor-rating-stars{
  margin-left: 30px;
}
.mapExpanded-sponsor-distance-div{
  margin-left: 20px;
}
.mapExpanded-doc-card-header h4{
  font-size: 27px;
  text-wrap: nowrap;
}
.mapExpanded-doc-card-header p{
  font-size: 13px;
  margin: 5px 0 0 3px;
  text-wrap: nowrap;
}
.mapExpanded-sort-by {
  display: none !important;
}
.mapExpanded-hospital-sort-by{
  width: 70%;
}
.mapExpanded-sponsor-card{
  margin: 20px 0 !important;
}
.mapExpanded-pagination{
  width: 93%;
}