.about-us-main-container{
    background: linear-gradient(180deg, #EDF3FF 0%, #FFFFFF 100%);  
}
/*=============about-us-container ==========Start*/
.about-us-container{
    padding:70px;
}

.top-section11 { 
    display: flex;
    justify-content: center;
    align-items: center;
    gap:5%;   
}

.image-group11{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:15px;
}

.top-image11{
    display: flex;
    flex-direction: column; 
    gap: 15px;
}

.top-image111{
    width: 276px;
    height: 303px;
    border-radius:12px;
    object-fit: cover; 
}

.top-image211{
    width: 276px ;
    height:170px;
    border-radius: 12px;
    object-fit: cover; 
}
.bottom-images11{
    display: flex;
    flex-direction: column; 
    gap: 15px;
}

.image-overlay11 {
    background-color:#007bff;
    color: white;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    width: 276px;
    height: 120px;
    padding: 36px 20px;
    border-radius: 12px;   
}

.top-image311{
    width:276px;
    height:303px;
    border-radius: 12px;
    object-fit: cover;
} 

.content-section11 {
    max-width: 640px;
    max-height: 373px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap:15px;
}

.content-section11 h2 {
    border-left: 4px solid #FF7F50;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    padding-left: 10px;
    color:#272848;
    margin: 0;   
}
.content-section11 p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: rgba(83, 83, 83, 1);
    text-align: justify; 
    margin: 0;   
}

.content-section11 h6{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: justify;
    color:#272848;
    margin: 0; 
}
/*=============about-us-container ==========End*/

/*=============Who We Serve ==========Start*/
.Serve-container {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.Serve-title-head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 15px;
}

.Serve-title-head h2 {
    border-left: 4px solid #FF7F50;
    padding-left: 10px;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    color: #272848;
    margin: 0;
}

.Serve-title-head p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center; 
    color: rgba(83, 83, 83, 1);
    padding-bottom: 25px;
}

.cards-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 30px; 
    justify-items: center;
}


.card11 {
    display: flex;
    flex-direction: column; 
    align-items: center; 
    text-align: center; 
    gap:15px;
}

.card11 img {
    width: 100%;
    max-width: 401px; 
    max-height: 325px; 
    border-radius: 12px; 
    object-fit: cover;
}

.card11 h3 {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
    color: rgba(39, 40, 72, 1);
    margin: 0;
}

.card11 p {
    width: 100%;
    max-width: 351px;
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: rgba(83, 83, 83, 1);
    margin: 0; 
}

.card11 .learn-more-btn {
    width: 125px;
    height: 42px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #007bff;
    color: white;
    border: none;
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    transition: background 0.2s ease, transform 0.3s ease;
}

.learn-more-btn:hover {
    background-color: #0056b3; 
    transform: scale(1.05);
}


/*=============Who We Serve ==========End*/

/*=============Our-Global-Reach ==========Start*/
.Our-Global-Reach-container {
    background-image: url('./About_Assets/Frame.png');
    background-repeat: no-repeat;
    background-size: cover; 
    background-position: center; 
    padding: 80px 40px; 
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.Our-Global-Reach-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.Our-Global-Reach-title {
    border-left: 4px solid #FF7F50;
    padding-left: 10px;
    font-size: 28px;
    font-weight: 600;
    color: #002060;
    margin-top: 50px;
    text-align: center; 
}

.Our-Global-Reach-description {
    max-width: 870px; 
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
    color: rgba(83, 83, 83, 1);
    margin-bottom: 50px;
    padding: 0 20px; 
}
/*=============Our-Global-Reach ==========End*/

/*=============Our Mission and Goals ==========Start*/
.about-our-mission-section11 {
    padding:70px;
}
.about-our-mission-container{
    display: flex;
    align-items: center;
    justify-content: center;
    gap:5%;
    padding-top: 80px;
}

.about-our-mission-content11{
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
    gap:15px;
    max-width:572px;
    height: fit-content;
}

.about-our-mission-content11 h2 {
    border-left: 4px solid #FF7F50;
    padding-left: 10px;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    color:#272848;
    margin: 0;
}

.about-our-mission-content11 p {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align:left;
    color: rgba(83, 83, 83, 1); 
    margin:0;
}
.about-our-mission-content11 h3{
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align:justify;
    margin:0;
    color:#272848
}

.about-our-mission-sub-flex{
    display: flex;
    align-items: start;
    justify-content: start;
    flex-direction: column;
    gap:20px;
}

.about-our-mission-sub-flex-card {
    display: flex;
    align-items: center;
    gap:10px;
    text-align: left;
    box-shadow: 0px 4px 6px 0px rgba(1, 103, 255, 0.08);
    max-width:570px;
    max-height:fit-content;
    padding: 20px 15px;
    border-radius: 12px;
    background-color: #FFFFFF;
}
.about-our-mission-sub-flex-card img{
    width: 20px;
    height: 20px;
}

.about-our-mission-sub-flex-card p{
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: rgba(83, 83, 83, 1);
}

.goal11 img{
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
}

.goal11 p {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    text-align:left;
    color: rgba(83, 83, 83, 1);
    margin: 0;
}

.Our-Mission-Goalsdoctorprofile{
    max-width: 608px;
    max-height: 348px;
    
}
  
.Our-Mission-Goalsdoctorprofile img { 
    width: 100%;
    border-radius: 12px; 
    object-fit: cover; 
}
/*=============Our Mission and Goals ==========End*/

/*=============Meet Our Team================Start */
.team-section {
    padding: 100px 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 25px;
}

.team-section h2 {
    border-left: 4px solid #FF7F50;
    padding-left: 10px;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    color: #272848;
    margin: 0;
}

.team-subtitle {
    width: 100%;
    max-width: 490px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #666;
    margin-bottom: 40px;
}

.meet-our-team-members {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 40px;
    justify-content: center; 
    align-items: center;
}


.meet-our-team-member {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
}

.meet-our-team-member .meet-our-team-member-image {
    width: 100%;
    max-width: 309px;
    height:auto; 
    border-radius: 12px;
    margin-bottom: 10px;
}

.meet-our-team-member .meet-our-team-member-name {
    font-size: 22px;
    font-weight: 600;
    line-height: 26.4px;
    text-align: center;
    color: rgba(39, 40, 72, 1);
}

.meet-our-team-member .meet-our-team-member-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    text-align: center;
    color: rgba(64, 64, 64, 1);
}

.Linkedin-icon {
    width: 40px;
    height: 40px;
    text-align: center;
    color: #0077B5;
}

/*=============Meet Our Team================End *

/*============Meet Our Board of our advisors========Start */
.advisors-team-section {
    padding: 70px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:60px;
}

.advisors-team-section h2 {
    border-left: 4px solid #FF7F50;
    padding-left: 10px;
    font-size: 28px;
    font-weight: 600;
    line-height: 33.6px;
    color: #272848;
    margin: 0;
}

.advisors-team-subtitle {
    width: 100%;
    max-width: 490px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #666;
    margin-bottom: 40px;
}

.advisors-team-members {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 30px;
    align-items: center;
    justify-content: center;
}

.advisors-team-member {
    display: flex;
    flex-direction: column;
    align-items: start;
    text-align: left;
    max-width: 309px; 
}

.advisors-team-member .advisors-team-member-image {
    width: 100%;
    max-width: 309px;
    height:auto; 
    border-radius: 12px;
    margin-bottom: 10px;
}

.advisors-team-member .advisors-team-member-name {
    font-size: 22px;
    font-weight: 600;
    line-height: 26.4px;
    color: #FF7F50;
}

.advisors-team-member .advisors-team-member-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    color: rgba(64, 64, 64, 1);
}

.advisors-team-member .advisors-team-member-description {
    max-width: 309px;
    color: rgba(83, 83, 83, 1);
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
}

.advisors-team-member .advisors-team-member-location {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(0, 0, 0, 1);
}
/*============Meet Our Board of our advisors========End */


@media (max-width: 1200px) {
    /*=============about-us-container===============*/
    .about-us-container {
        padding: 50px 70px;
    }

    .top-image11 {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .top-image111 {
        width: 220px;
        height: 260px;
        object-fit: cover;
    }
    .top-image211 {
        width: 220px;
        height: 170px;
        object-fit: cover;
    }
    .bottom-images11 {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .image-overlay11 {
        background-color: #007bff;
        color: white;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        width: 220px;
        height: 85px;
        padding: 16px 10px;
        border-radius: 12px;
    }

    .top-image311 {
        width: 220px;
        height: 260px;
        object-fit: cover;
    }

    .content-section11 {
        gap: 10px;
    }
    
    .content-section11 h2 {
        font-size: 25px;
    }
    .content-section11 p {
        font-size: 12px;
    }

    /*=============Our Mission and Goals============= */
    .about-our-mission-content11 p {
        font-size: 14px;
    }
    .mission-sub-flex-card {
        display: flex;
        align-items: center;
        gap: 10px;
        text-align: left;
        box-shadow: 0px 4px 6px 0px rgba(1, 103, 255, 0.08);
        width: 470px;

        max-height: fit-content;
        padding: 20px 15px;
        border-radius: 12px;
        background-color: #FFFFFF;
    }
}

@media screen and (max-width: 1024px) {
    .Serve-container {
        padding: 50px 20px; 
    }

    .Serve-title-head h2 {
        font-size: 24px; 
    }

    .Serve-title-head p {
        font-size: 14px; 
    }

    .cards-grid {
        gap: 5px; 
    }

    .card11 {
        padding:20px; 
    }

    .card11 img {
        width: 100%;
        max-width: 390px; 
        max-height: 310px; 
        border-radius: 12px; 
        object-fit: cover;
    }

    .card11 h3 {
        font-size: 20px; 
    }

    .card11 p {
        font-size: 12px; 
    }


    .Our-Global-Reach-container {
        padding: 60px 20px;
        background-size: contain;
    }

    .Our-Global-Reach-title {
        font-size: 24px; 
    }

    .Our-Global-Reach-description {
        font-size: 14px;
        max-width: 700px; 
    }


    .meet-our-team-members {
        grid-template-columns: repeat(2, 1fr); 
        justify-content: center; 
    }

    .advisors-team-members {
        grid-template-columns: repeat(2, 1fr); 
    }
}

@media screen and (max-width: 998px) {
    .top-section11, .bottom-section11, .cards-row11, .team-members {
        flex-direction: column;
        gap: 20px;
    }

    .about-us-container {
        padding:30px;
    }

    .cards-grid {
        grid-template-columns: repeat(2, 1fr);
        align-items: center;
        gap: 5px; 
    }
    .about-our-mission-container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap:50px;
        padding-top:50px;
    }
}

@media (max-width: 768px) {

    .about-us-container {
        padding: 30px;
    }
    .about-us-container11 {
        padding: 30px;
    }
    .goal11-header {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .goal11 {
        width: 100%;
        height: 100px;
        padding:10px;
        border-radius: 12px;
    }
    .goals11 {
        padding: 20px;
    }

    .top-section11, .bottom-section11, .cards-row11, .team-members {
        flex-direction: column;
        gap: 30px;
    }

    .content-section11, .mission-content11, .Serve-title-head {
        width: 100%;
        padding: 0;
    }

    .mission-image11 {
        width: 100%;
        height: auto;
    }

    .Serve-container {
        padding:70px 15px;
    }

    .Serve-title-head h2 {
        font-size: 20px;
    }

    .Serve-title-head p {
        font-size: 13px;
    }

    .cards-grid {
        grid-template-columns: 1fr;
        align-items: center;
        gap: 15px; 
    }

    .card11 {
        padding: 10px; 
    }

    .card11 h3 {
        font-size: 20px; 
    }

    .card11 p {
        font-size: 12px; 
    }

    .card11 .learn-more-btn {
        font-size: 14px;
        width: 110px;
        height: 38px;
    }
    
    .Our-Global-Reach-container {
        padding: 40px 15px;
        background-size: cover;
        background-position: center top;
    }

    .Our-Global-Reach-title {
        font-size: 22px; 
        margin-top: 30px;
    }

    .Our-Global-Reach-description {
        font-size: 14px;
        max-width: 90%; 
        padding: 0 10px; 
    }


    .meet-our-team-members {
        grid-template-columns: 1fr; 
    }

    .team-section {
        padding: 50px 20px;
    }

    .advisors-team-members {
        grid-template-columns: 1fr; 
    }

    .advisors-team-section {
        padding: 50px 20px;
    }


}
@media (max-width: 576px) {
    .top-image111 {
        width: 190px;
        height: 250px;
        object-fit: cover;
    }
    .top-image211 {
        width: 190px;
        height: 165px;
        object-fit: cover;
    }

    .image-overlay11 {
        background-color: #007bff;
        color: white;
        font-size: 12px;
        font-weight: 600;
        line-height: 24px;
        text-align: center;
        width: 190px;
        height: 80px;
        padding: 12px 8px;
        border-radius: 12px;
    }

    .top-image311 {
        width: 190px;
        height: 250px;
        object-fit: cover;
    }
}

@media screen and (max-width: 480px) {
    .top-section11, .bottom-section11, .cards-row11, .team-members {
        flex-direction: column;
        gap: 120px;
    }

    .top-image111 {
        width: 170px;
        height: 200px;
        object-fit: cover;
    }
    .top-image211 {
        width: 170px;
        height: 100px;
        object-fit: cover;
    }

    .image-overlay11 {
        background-color: #007bff;
        color: white;
        font-size: 12px;
        font-weight: 600;
        line-height: 16px;
        text-align: center;
        width: 170px;
        height: 70px;
        padding: 12px 8px;
        border-radius: 12px;
    }

    .top-image311 {
        width: 170px;
        height: 200px;
        object-fit: cover;
    }

    .Serve-container {
        padding: 100px 30px;
    }
    .Serve-title-head {
        gap: 1px;
    }

    .Serve-title-head h2 {
        font-size: 18px; 
    }

    .Serve-title-head p {
        font-size: 12px; 
    }

    .card11 h3 {
        font-size: 18px;
    }

    .card11 p {
        font-size: 12px;
    }


    .Our-Global-Reach-container {
        padding: 40px 10px;
        background-size: cover; 
        background-position: center top;
    }

    .Our-Global-Reach-title {
        font-size: 20px;
    }

    .Our-Global-Reach-description {
        font-size: 13px; 
        max-width: 100%;
    }


    .about-our-mission-section11 {
        padding: 30px;
    }

    .team-section {
        padding: 30px;
    }


    .meet-our-team-member .meet-our-team-member-name {
        font-size: 18px;
    }

    .meet-our-team-member .meet-our-team-member-title {
        font-size: 12px;
    }

    .Linkedin-icon {
        width: 35px;
        height: 35px;
    }


    .advisors-team-section {
        padding: 30px;
    }

    .advisors-team-member .advisors-team-member-name {
        font-size: 18px;
    }

    .advisors-team-member .advisors-team-member-title {
        font-size: 12px;
    }

    .advisors-team-member .advisors-team-member-location {
        font-size: 14px;
    }
}

@media screen and (max-width: 360px) {
    .top-image111 {
        width: 140px;
        height: 170px;
        object-fit: cover;
    }

    .top-image211 {
        width: 140px;
        height: auto;
        object-fit: cover;
    }

    .image-overlay11 {
        background-color: #007bff;
        color: white;
        font-size: 8px;
        font-weight: 600;
        line-height: 14px;
        text-align: center;
        width: 140px;
        height: 55px;
        padding:10px;
        border-radius: 12px;
    }
    
    .top-image311 {
        width: 140px;
        height: 170px;
        object-fit: cover;
    }

}

@media screen and (max-width: 320px) {
    .top-image111 {
        width: 120px;
        height: 170px;
        object-fit: cover;
    }

    .top-image211 {
        width: 120px;
        height: auto;
        object-fit: cover;
    }

    .image-overlay11 {
        background-color: #007bff;
        color: white;
        font-size: 8px;
        font-weight: 600;
        line-height: 14px;
        text-align: center;
        width: 120px;
        height: 55px;
        padding:10px;
        border-radius: 12px;
    }
    
    .top-image311 {
        width: 120px;
        height: 170px;
        object-fit: cover;
    }

}












